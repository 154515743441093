import { concat, defaultTo, ifElse, isNil, pipe, map, sum, xprod, view, reduce, mergeDeepRight, filter } from 'ramda';
import { UOM_Measured_Default, UOM_Dependencies, UOM_CONVERSIONS, UOM_Pitchable } from '../takeoff/helper/UnitConversions';
import { getNodeChildrenLens } from './Estimate';

const ApplicableGroupColumns = {
  name: true,
  page_title: false,
  description: true,
  internal_notes: true,
  use_group_color: false,
  color: true,
  count: true,
  length: true,
  area: true,
  uom_measured: false,
  uom: false,
  quantity1: false,
  quantity2: false,
  pitch: false,
  pitch_type: false,
  unit_amount: false,
  assembly_entry_total_amount: false,
  material_vendor: false,
  material_cost: false,
  material_cost_extra: false,
  material_tax: false,
  material_shipping: false,
  material_unit_subtotal: false,
  material_subtotal: true,
  material_markup: false,
  material_unit_total: false,
  material_total: true,
  labor_amount: false,
  labor_cost: false,
  labor_unit_subtotal: false,
  labor_subtotal: true,
  labor_markup: false,
  labor_unit_total: false,
  labor_total: true,
  subcontractor_unit_cost: false,
  subcontractor_subtotal: true,
  subcontractor_markup: false,
  subcontractor_unit_total: false,
  subcontractor_total: true,
  unit_cost: false,
  unit_cost_markup: false,
  unit_cost_total: false,
  subtotal: true,
  subtotal_markup: true,
  total: true,
  cost: true,
  profit: true,
  margin: true,
  total_labor_hours: true,
  crew_size: false,
  labor_days: true,
  item_tags: false,
};

const getIsColumnApplicableToGroup = (column) => ApplicableGroupColumns[column];

const ApplicableAssemblyEntryColumns = {
  name: true,
  description: true,
  internal_notes: true,
  unit_amount: true,
  assembly_entry_total_amount: true,
  material_vendor: true,
  material_cost: true,
  material_cost_extra: false,
  material_tax: false,
  material_shipping: false,
  material_unit_subtotal: false,
  material_subtotal: false,
  material_markup: false,
  material_unit_total: false,
  material_total: false,
  labor_amount: true,
  labor_cost: false,
  labor_unit_subtotal: false,
  labor_subtotal: false,
  labor_markup: false,
  labor_total: false,
  subcontractor_unit_cost: false,
  subcontractor_subtotal: false,
  subcontractor_markup: false,
  subcontractor_unit_total: false,
  subcontractor_total: false,
  page_title: false,
  use_group_color: false,
  color: false,
  count: false,
  length: false,
  area: false,
  uom_measured: false,
  uom: false,
  quantity1: false,
  quantity2: false,
  pitch: false,
  pitch_type: false,
  labor_markup: false,
  labor_unit_total: false,
  unit_cost: false,
  unit_cost_markup: false,
  unit_cost_total: false,
  subtotal: false,
  subtotal_markup: false,
  total: false,
  cost: false,
  profit: false,
  margin: false,
  total_labor_hours: false,
  crew_size: false,
  labor_days: false,
  item_tags: true,
};

//columns in this list are disabled, organized by measurement type
const InapplicableItemColumns = {
  count: ['assembly_entry_total_amount', 'length', 'area', 'pitch', 'pitch_type'],
  line: ['assembly_entry_total_amount', 'count', 'area'],
  rectangle: ['assembly_entry_total_amount', 'count', 'length'],
  polygon: ['assembly_entry_total_amount', 'count', 'length'],
  circle: ['assembly_entry_total_amount', 'count', 'length'],
  item: ['assembly_entry_total_amount', 'count', 'length', 'area', 'uom_measured', 'uom', 'quantity1', 'quantity2', 'pitch', 'pitch_type', 'page_title'],
  assembly: ['assembly_entry_total_amount', 'count', 'length', 'area', 'uom_measured', 'uom', 'quantity1', 'quantity2', 'pitch', 'pitch_type', 'page_title'],
};

const fullGetIsApplicable = (getValue) => (getNodeType) => (column) => (nodeID) => {
  if (isNil(nodeID)) return false;
  const nodeType = getNodeType(nodeID);
  if (nodeType == 'assembly_entry') return ApplicableAssemblyEntryColumns[column];
  if (nodeType == 'group') return ApplicableGroupColumns[column];
  //TODO: shortcut for calculating uom_measured without knowing whether it's actually calculated or not. Knowing whether it is calculated would introduce a circular dependency
  if (column == 'quantity1' && nodeType != 'group')
    return !isNil(getValue('uom')(nodeID)) && UOM_Dependencies[getValue('uom_measured')(nodeID) || UOM_Measured_Default[getValue('type')(nodeID)]][getValue('uom')(nodeID)].length >= 2;
  //TODO: shortcut for calculating uom_measured without knowing whether it's actually calculated or not. Knowing whether it is calculated would introduce a circular dependency
  if (column == 'quantity2' && nodeType != 'group')
    return !isNil(getValue('uom')(nodeID)) && UOM_Dependencies[getValue('uom_measured')(nodeID) || UOM_Measured_Default[getValue('type')(nodeID)]][getValue('uom')(nodeID)].length >= 3;
  return !InapplicableItemColumns[nodeType]?.includes(column);
};

const EditableColumns = {
  name: true,
  page_title: false,
  description: true,
  internal_notes: true,
  use_group_color: true,
  color: true,
  count: false,
  length: false,
  area: false,
  uom_measured: false,
  uom: true,
  quantity1: true,
  quantity2: true,
  pitch: true,
  pitch_type: true,
  unit_amount: true,
  assembly_entry_total_amount: false,
  material_vendor: true,
  material_cost: true,
  material_cost_extra: true,
  material_tax: true,
  material_shipping: true,
  material_unit_subtotal: false,
  material_subtotal: false,
  material_markup: true,
  material_unit_total: true,
  material_total: false,
  labor_amount: true,
  labor_cost: true,
  labor_unit_subtotal: false,
  labor_subtotal: false,
  labor_markup: true,
  labor_unit_total: true,
  labor_total: false,
  subcontractor_unit_cost: true,
  subcontractor_subtotal: false,
  subcontractor_markup: true,
  subcontractor_unit_total: true,
  subcontractor_total: false,
  unit_cost: true,
  unit_cost_markup: true,
  unit_cost_total: false,
  subtotal: false,
  subtotal_markup: true,
  total: true,
  cost: false,
  profit: false,
  margin: false,
  total_labor_hours: false,
  crew_size: true,
  labor_days: false,
  item_tags: true,
};

const fullGetIsEditable = (getIsApplicable) => (column) => (nodeID) => getIsApplicable(column)(nodeID) && defaultTo(false)(EditableColumns[column]);

const ItemCalculatedColumns = {
  name: false,
  page_title: false,
  description: false,
  internal_notes: false,
  use_group_color: false,
  color: false,
  count: false,
  length: true,
  area: true,
  uom_measured: true,
  uom: false,
  quantity1: false,
  quantity2: false,
  pitch: false,
  pitch_type: false,
  unit_amount: true,
  assembly_entry_total_amount: false,
  material_vendor: false,
  material_cost: false,
  material_cost_extra: false,
  material_tax: false,
  material_shipping: false,
  material_unit_subtotal: true,
  material_subtotal: true,
  material_markup: false,
  material_unit_total: true,
  material_total: true,
  labor_amount: false,
  labor_cost: false,
  labor_unit_subtotal: true,
  labor_subtotal: true,
  labor_markup: false,
  labor_unit_total: true,
  labor_total: true,
  subcontractor_unit_cost: false,
  subcontractor_subtotal: true,
  subcontractor_markup: false,
  subcontractor_unit_total: true,
  subcontractor_total: true,
  unit_cost: true,
  unit_cost_markup: false,
  unit_cost_total: true,
  subtotal: true,
  subtotal_markup: false,
  total: true,
  cost: true,
  profit: true,
  margin: true,
  total_labor_hours: true,
  crew_size: false,
  labor_days: true,
  item_tags: false,
};

const GroupCalculatedColumns = {
  name: false,
  page_title: false,
  description: false,
  internal_notes: false,
  use_group_color: false,
  color: false,
  length: true,
  area: true,
  uom_measured: true,
  uom: false,
  quantity1: false,
  quantity2: false,
  pitch: false,
  pitch: false,
  unit_amount: true,
  assembly_entry_total_amount: false,
  material_vendor: false,
  material_cost: false,
  material_cost_extra: false,
  material_tax: false,
  material_shipping: false,
  material_unit_subtotal: true,
  material_subtotal: true,
  material_markup: false,
  material_unit_total: true,
  material_total: true,
  labor_amount: false,
  labor_cost: false,
  labor_unit_subtotal: true,
  labor_subtotal: true,
  labor_markup: false,
  labor_unit_total: true,
  labor_total: true,
  subcontractor_unit_cost: false,
  subcontractor_subtotal: true,
  subcontractor_markup: false,
  subcontractor_unit_total: false,
  subcontractor_total: true,
  unit_cost: true,
  unit_cost_markup: false,
  unit_cost_total: true,
  subtotal_markup: false,
  crew_size: false,
  subtotal: true,
  total: true,
  cost: true,
  profit: true,
  margin: true,
  total_labor_hours: true,
  labor_days: true,
  count: true,
  length: true,
  area: true,
  item_tags: false,
};

const AssemblyCalculatedColumns = {
  name: false,
  use_group_color: false,
  color: false,
  description: false,
  internal_notes: false,
  unit_amount: false,
  assembly_entry_total_amount: false,
  material_vendor: false,
  material_cost: true,
  material_cost_extra: false,
  material_tax: false,
  material_shipping: false,
  material_unit_subtotal: true,
  material_unit_total: true,
  material_subtotal: true,
  material_markup: false,
  material_unit_total: true,
  material_total: true,
  labor_amount: true,
  labor_cost: false,
  labor_unit_subtotal: true,
  labor_subtotal: true,
  labor_markup: false,
  labor_unit_total: true,
  labor_total: true,
  subcontractor_unit_cost: false,
  subcontractor_subtotal: true,
  subcontractor_markup: false,
  subcontractor_unit_total: true,
  subcontractor_total: true,
  unit_cost: true,
  unit_cost_markup: false,
  unit_cost_total: true,
  subtotal: true,
  subtotal_markup: false,
  total: true,
  cost: true,
  profit: true,
  margin: true,
  total_labor_hours: true,
  crew_size: false,
  labor_days: true,
  item_tags: false,
};

const AssemblyEntryCalculatedColumns = {
  assembly_entry_total_amount: true,
  item_tags: false,
};

const fullGetIsCalculated = (getNodeType) => (getIsApplicable) => (column) => (nodeID) => {
  if (!getIsApplicable(column)(nodeID)) return false;
  const nodeType = getNodeType(nodeID);
  if (nodeType == 'group') return GroupCalculatedColumns[column];
  if (nodeType == 'assembly') return AssemblyCalculatedColumns[column];
  if (nodeType == 'assembly_entry') return defaultTo(false)(AssemblyEntryCalculatedColumns[column]);
  return ItemCalculatedColumns[column];
};

const ItemDependencies = {
  length: ['scale'],
  area: ['scale'],
  uom_measured: ['type'],
  unit_amount: ['type', 'count', 'length', 'area', 'uom_measured', 'uom', 'quantity1', 'quantity2', 'pitch', 'pitch_type'],
  material_unit_subtotal: ['material_cost', 'material_tax', 'material_shipping', 'material_cost_extra'],
  material_subtotal: ['material_unit_subtotal', 'unit_amount'],
  material_unit_total: ['material_unit_subtotal', 'material_markup'],
  material_total: ['material_unit_total', 'unit_amount'],
  labor_unit_subtotal: ['labor_amount', 'labor_cost'],
  labor_subtotal: ['labor_unit_subtotal', 'unit_amount'],
  labor_unit_total: ['labor_unit_subtotal', 'labor_markup'],
  labor_total: ['labor_unit_total', 'unit_amount'],
  subcontractor_subtotal: ['subcontractor_unit_cost', 'unit_amount'],
  subcontractor_unit_total: ['subcontractor_unit_cost', 'subcontractor_markup'],
  subcontractor_total: ['subcontractor_unit_total', 'unit_amount'],
  unit_cost: ['material_unit_total', 'labor_unit_total', 'subcontractor_unit_total'],
  unit_cost_total: ['unit_cost', 'unit_cost_markup'],
  subtotal: ['unit_cost_total', 'unit_amount'],
  total: ['subtotal', 'subtotal_markup'],
  cost: ['material_unit_subtotal', 'labor_unit_subtotal', 'unit_cost', 'unit_amount', 'subcontractor_unit_cost'],
  profit: ['total', 'cost'],
  margin: ['profit', 'total'],
  total_labor_hours: ['unit_amount', 'labor_amount'],
  labor_days: ['total_labor_hours', 'crew_size'],
};

const GroupDependencies = {
  material_subtotal: { self: [], children: ['material_subtotal'] },
  material_total: { self: [], children: ['material_total'] },
  labor_subtotal: { self: [], children: ['labor_subtotal'] },
  labor_total: { self: [], children: ['labor_total'] },
  subcontractor_subtotal: { self: [], children: ['subcontractor_subtotal'] },
  subcontractor_total: { self: [], children: ['subcontractor_total'] },
  subtotal: { self: [], children: ['total'] },
  total: { self: ['subtotal', 'subtotal_markup'], children: [] },
  cost: { self: [], children: ['cost'] },
  profit: { self: ['total', 'cost'], children: [] },
  margin: { self: ['profit', 'total'], children: [] },
  total_labor_hours: { self: [], children: ['total_labor_hours'] },
  labor_days: { self: [], children: ['labor_days'] },
  count: { self: [], children: ['count'] },
  length: { self: [], children: ['length'] },
  area: { self: [], children: ['area'] },
};

const AssemblyDependencies = {
  material_cost: { self: [], children: ['unit_amount', 'material_cost'] },
  material_unit_subtotal: { self: ['material_cost', 'material_cost_extra', 'material_tax', 'material_shipping'], children: [] },
  material_subtotal: { self: ['material_unit_subtotal', 'unit_amount'], children: [] },
  material_unit_total: { self: ['material_unit_subtotal', 'material_markup'], children: [] },
  material_total: { self: ['material_unit_total', 'unit_amount'], children: [] },
  labor_amount: { self: [], children: ['labor_amount'] },
  labor_unit_subtotal: { self: ['labor_amount', 'labor_cost'], children: [] },
  labor_subtotal: { self: ['labor_unit_subtotal', 'unit_amount'], children: [] },
  labor_unit_total: { self: ['labor_unit_subtotal', 'labor_markup'], children: [] },
  labor_total: { self: ['labor_unit_total', 'unit_amount'], children: [] },
  subcontractor_subtotal: { self: ['subcontractor_unit_cost', 'unit_amount'], children: [] },
  subcontractor_unit_total: { self: ['subcontractor_unit_cost', 'subcontractor_markup'], children: [] },
  subcontractor_total: { self: ['subcontractor_unit_total', 'unit_amount'], children: [] },
  unit_cost: { self: ['material_unit_total', 'labor_unit_total'], children: [] },
  unit_cost_total: { self: ['unit_cost', 'unit_cost_markup'], children: [] },
  subtotal: { self: ['unit_cost_total', 'unit_amount'], children: [] },
  total: { self: ['subtotal', 'subtotal_markup'], children: [] },
  cost: { self: ['material_unit_subtotal', 'labor_unit_subtotal', 'unit_cost', 'unit_amount', 'subcontractor_unit_cost'], children: [] },
  profit: { self: ['total', 'cost'], children: [] },
  margin: { self: ['profit', 'total'], children: [] },
  total_labor_hours: { self: ['labor_amount', 'unit_amount'], children: [] },
  labor_days: { self: ['total_labor_hours', 'crew_size'], children: [] },
};

const AssemblyEntryDepndencies = { assembly_entry_total_amount: { self: ['unit_amount'], children: [], group: ['unit_amount'] } };

const fullGetDependencies = (getIsCalculated) => (getGroup) => (getChildren) => (getNodeType) => (column) => (nodeID) => {
  if (!getIsCalculated(column)(nodeID)) return [];
  if (getNodeType(nodeID) === 'group') return concat(xprod(GroupDependencies[column].self)([nodeID]))(xprod(GroupDependencies[column].children)(getChildren(nodeID)));
  if (getNodeType(nodeID) === 'assembly') return concat(xprod(AssemblyDependencies[column].self)([nodeID]))(xprod(AssemblyDependencies[column].children)(getChildren(nodeID)));
  if (getNodeType(nodeID) === 'assembly_entry') {
    return pipe(
      xprod(AssemblyEntryDepndencies[column].self),
      concat(xprod(AssemblyEntryDepndencies[column].group)([getGroup(nodeID)])),
      concat(xprod(AssemblyEntryDepndencies[column].children)(getChildren(nodeID)))
    )([nodeID]);
  }
  return xprod(ItemDependencies[column])([nodeID]);
};

const calculateUnitAmount = (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) => {
  let quantity =
    UOM_Measured_Default[getValue('type')(nodeID)] === 'ea'
      ? getValue('count')(nodeID) //TODO: taking some shortcuts for now; not checking if should be calculated or not
      : UOM_Measured_Default[getValue('type')(nodeID)] === 'ft'
      ? getCalculatedValue('length')(nodeID) //TODO: taking some shortcuts for now; not checking if should be calculated or not
      : UOM_Measured_Default[getValue('type')(nodeID)] === 'ft2'
      ? getCalculatedValue('area')(nodeID) //TODO: taking some shortcuts for now; not checking if should be calculated or not
      : 1;
  let converted = quantity;
  let uom_measured = ifElse(getShouldUseCalculatedValue('uom_measured'), getCalculatedValue('uom_measured'), getValue('uom_measured'))(nodeID) || UOM_Measured_Default[getValue('type')(nodeID)]; //cheating here again
  const uom = ifElse(getShouldUseCalculatedValue('uom'), getCalculatedValue('uom'), getValue('uom'))(nodeID);
  const quantity1 = ifElse(getShouldUseCalculatedValue('quantity1'), getCalculatedValue('quantity1'), getValue('quantity1'))(nodeID);
  const quantity2 = ifElse(getShouldUseCalculatedValue('quantity2'), getCalculatedValue('quantity2'), getValue('quantity2'))(nodeID);
  if (uom) {
    if (
      (UOM_Dependencies[uom_measured][uom].length === 1 || (UOM_Dependencies[uom_measured][uom].length > 1 && (quantity1 !== null || quantity1 !== undefined))) &&
      (UOM_Dependencies[uom_measured][uom].length === 1 || (UOM_Dependencies[uom_measured][uom].length > 1 && (quantity2 !== null || quantity2 !== undefined)))
    ) {
      if (UOM_CONVERSIONS[uom_measured][uom]) {
        converted = UOM_CONVERSIONS[uom_measured][uom]({ quantity, quantity1, quantity2 });
      }
    }
  }
  const pitch = ifElse(getShouldUseCalculatedValue('pitch'), getCalculatedValue('pitch'), getValue('pitch'))(nodeID);
  const pitch_type = ifElse(getShouldUseCalculatedValue('pitch_type'), getCalculatedValue('pitch_type'), getValue('pitch_type'))(nodeID);

  if (UOM_Pitchable[uom] && pitch) {
    if (pitch_type == 'percent') {
      converted = converted / Math.cos(Math.atan(pitch / 100));
    } else {
      converted = converted / Math.cos(Math.atan(pitch / 12));
    }
  } // TODO check pitch type

  if (uom === 'ea') {
    converted = Math.round(converted);
  }

  return converted;
};

//dictionary of functions for each column that is calculated
const ItemCalculations = {
  uom_measured: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    getValue('uom_measured')(nodeID) || UOM_Measured_Default[getValue('type')(nodeID)], //TODO: because we don't know if uom_measured is calculated or not (shortcut)
  unit_amount: calculateUnitAmount,
  //   material_unit_subtotal: ({ material_cost, material_tax, material_shipping }) => +(material_cost * (1 + material_tax / 100) * (1 + material_shipping / 100)).toFixed(2),
  material_unit_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    (ifElse(getShouldUseCalculatedValue('material_cost'), getCalculatedValue('material_cost'), getValue('material_cost'))(nodeID) +
      ifElse(getShouldUseCalculatedValue('material_cost_extra'), getCalculatedValue('material_cost_extra'), getValue('material_cost_extra'))(nodeID)) *
    (1 + ifElse(getShouldUseCalculatedValue('material_tax'), getCalculatedValue('material_tax'), getValue('material_tax'))(nodeID) / 100) *
    (1 + ifElse(getShouldUseCalculatedValue('material_shipping'), getCalculatedValue('material_shipping'), getValue('material_shipping'))(nodeID) / 100),
  material_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_subtotal'), getCalculatedValue('material_unit_subtotal'), getValue('material_unit_subtotal'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  //   material_unit_total: ({ material_unit_subtotal, material_markup }) => +(material_unit_subtotal * (1 + material_markup / 100)).toFixed(2),
  material_unit_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_subtotal'), getCalculatedValue('material_unit_subtotal'), getValue('material_unit_subtotal'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('material_markup'), getCalculatedValue('material_markup'), getValue('material_markup'))(nodeID) / 100),
  material_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_total'), getCalculatedValue('material_unit_total'), getValue('material_unit_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  //   labor_unit_subtotal: ({ labor_amount, labor_cost }) => +(labor_amount * labor_cost).toFixed(2),
  labor_unit_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_amount'), getCalculatedValue('labor_amount'), getValue('labor_amount'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('labor_cost'), getCalculatedValue('labor_cost'), getValue('labor_cost'))(nodeID),
  labor_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_unit_subtotal'), getCalculatedValue('labor_unit_subtotal'), getValue('labor_unit_subtotal'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  //   labor_unit_total: ({ labor_unit_subtotal, labor_markup }) => +(labor_unit_subtotal * (1 + labor_markup / 100)).toFixed(2),
  labor_unit_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_unit_subtotal'), getCalculatedValue('labor_unit_subtotal'), getValue('labor_unit_subtotal'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('labor_markup'), getCalculatedValue('labor_markup'), getValue('labor_markup'))(nodeID) / 100),
  labor_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_unit_total'), getCalculatedValue('labor_unit_total'), getValue('labor_unit_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  subcontractor_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_cost'), getCalculatedValue('subcontractor_unit_cost'), getValue('subcontractor_unit_cost'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  subcontractor_unit_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_cost'), getCalculatedValue('subcontractor_unit_cost'), getValue('subcontractor_unit_cost'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('subcontractor_markup'), getCalculatedValue('subcontractor_markup'), getValue('subcontractor_markup'))(nodeID) / 100),
  subcontractor_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_total'), getCalculatedValue('subcontractor_unit_total'), getValue('subcontractor_unit_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  //   unit_cost: ({ material_unit_total, labor_unit_total }) => +(material_unit_total + labor_unit_total).toFixed(2),
  unit_cost: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_total'), getCalculatedValue('material_unit_total'), getValue('material_unit_total'))(nodeID) +
    ifElse(getShouldUseCalculatedValue('labor_unit_total'), getCalculatedValue('labor_unit_total'), getValue('labor_unit_total'))(nodeID) +
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_total'), getCalculatedValue('subcontractor_unit_total'), getValue('subcontractor_unit_total'))(nodeID),
  unit_cost_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('unit_cost'), getCalculatedValue('unit_cost'), getValue('unit_cost'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('unit_cost_markup'), getCalculatedValue('unit_cost_markup'), getValue('unit_cost_markup'))(nodeID) / 100),
  //   subtotal: ({ unit_amount, unit_cost }) => +(unit_amount * unit_cost).toFixed(2),
  subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_cost_total'), getCalculatedValue('unit_cost_total'), getValue('unit_cost_total'))(nodeID),
  //   total: ({ subtotal, subtotal_markup }) => +(subtotal * (1 + subtotal_markup / 100)).toFixed(2),
  total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subtotal'), getCalculatedValue('subtotal'), getValue('subtotal'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('subtotal_markup'), getCalculatedValue('subtotal_markup'), getValue('subtotal_markup'))(nodeID) / 100),
  // 'cost': ({ material_unit_subtotal, labor_unit_subtotal, unit_cost, unit_amount }) => unit_cost ? +(unit_cost * unit_amount).toFixed(2) : +((material_unit_subtotal + labor_unit_subtotal) * unit_amount).toFixed(2),
  cost: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    getShouldUseCalculatedValue('unit_cost')(nodeID)
      ? (ifElse(getShouldUseCalculatedValue('material_unit_subtotal'), getCalculatedValue('material_unit_subtotal'), getValue('material_unit_subtotal'))(nodeID) +
          ifElse(getShouldUseCalculatedValue('labor_unit_subtotal'), getCalculatedValue('labor_unit_subtotal'), getValue('labor_unit_subtotal'))(nodeID) +
          ifElse(getShouldUseCalculatedValue('subcontractor_unit_cost'), getCalculatedValue('subcontractor_unit_cost'), getValue('subcontractor_unit_cost'))(nodeID)) *
        ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID)
      : getValue('unit_cost')(nodeID) * ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),

  profit: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))(nodeID) -
    ifElse(getShouldUseCalculatedValue('cost'), getCalculatedValue('cost'), getValue('cost'))(nodeID),

  margin: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    (ifElse(getShouldUseCalculatedValue('profit'), getCalculatedValue('profit'), getValue('profit'))(nodeID) /
      ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))(nodeID)) *
    100,

  total_labor_hours: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_amount'), getCalculatedValue('labor_amount'), getValue('labor_amount'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),

  labor_days: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('total_labor_hours'), getCalculatedValue('total_labor_hours'), getValue('total_labor_hours'))(nodeID) /
    (ifElse(getShouldUseCalculatedValue('crew_size'), getCalculatedValue('crew_size'), getValue('crew_size'))(nodeID) * 8),

  length: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) => getValue('length')(nodeID) * getValue('page_scale')(nodeID),

  area: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) => getValue('area')(nodeID) * Math.pow(getValue('page_scale')(nodeID), 2),
};

const GroupCalculations = {
  material_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('material_subtotal'), getCalculatedValue('material_subtotal'), getValue('material_subtotal'))), filter(isFinite), sum),
  material_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('material_total'), getCalculatedValue('material_total'), getValue('material_total'))), filter(isFinite), sum),
  labor_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('labor_subtotal'), getCalculatedValue('labor_subtotal'), getValue('labor_subtotal'))), filter(isFinite), sum),
  labor_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('labor_total'), getCalculatedValue('labor_total'), getValue('labor_total'))), filter(isFinite), sum),
  subcontractor_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('subcontractor_subtotal'), getCalculatedValue('subcontractor_subtotal'), getValue('subcontractor_subtotal'))), filter(isFinite), sum),
  subcontractor_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('subcontractor_total'), getCalculatedValue('subcontractor_total'), getValue('subcontractor_total'))), filter(isFinite), sum),
  subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))), filter(isFinite), sum),
  total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subtotal'), getCalculatedValue('subtotal'), getValue('subtotal'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('subtotal_markup'), getCalculatedValue('subtotal_markup'), getValue('subtotal_markup'))(nodeID) / 100),
  cost: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('cost'), getCalculatedValue('cost'), getValue('cost'))), filter(isFinite), sum),
  profit: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))(nodeID) -
    ifElse(getShouldUseCalculatedValue('cost'), getCalculatedValue('cost'), getValue('cost'))(nodeID),
  margin: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    (ifElse(getShouldUseCalculatedValue('profit'), getCalculatedValue('profit'), getValue('profit'))(nodeID) /
      ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))(nodeID)) *
    100,
  total_labor_hours: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('total_labor_hours'), getCalculatedValue('total_labor_hours'), getValue('total_labor_hours'))), filter(isFinite), sum),
  labor_days: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('labor_days'), getCalculatedValue('labor_days'), getValue('labor_days'))), filter(isFinite), sum),
  count: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('count'), getCalculatedValue('count'), getValue('count'))), filter(isFinite), sum),
  length: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('length'), getCalculatedValue('length'), getValue('length'))), filter(isFinite), sum),
  area: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(getChildren, map(ifElse(getShouldUseCalculatedValue('area'), getCalculatedValue('area'), getValue('area'))), filter(isFinite), sum),
};

const AssemblyCalculations = {
  material_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_subtotal'), getCalculatedValue('material_unit_subtotal'), getValue('material_unit_subtotal'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  material_cost: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(
      getChildren,
      map(
        (childID) =>
          ifElse(getShouldUseCalculatedValue('material_cost'), getCalculatedValue('material_cost'), getValue('material_cost'))(childID) *
          ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(childID)
      ),
      filter(isFinite),
      sum
    ),
  material_unit_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    (ifElse(getShouldUseCalculatedValue('material_cost'), getCalculatedValue('material_cost'), getValue('material_cost'))(nodeID) +
      ifElse(getShouldUseCalculatedValue('material_cost_extra'), getCalculatedValue('material_cost_extra'), getValue('material_cost_extra'))(nodeID)) *
    (1 + ifElse(getShouldUseCalculatedValue('material_tax'), getCalculatedValue('material_tax'), getValue('material_tax'))(nodeID) / 100) *
    (1 + ifElse(getShouldUseCalculatedValue('material_shipping'), getCalculatedValue('material_shipping'), getValue('material_shipping'))(nodeID) / 100),
  material_unit_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_subtotal'), getCalculatedValue('material_unit_subtotal'), getValue('material_unit_subtotal'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('material_markup'), getCalculatedValue('material_markup'), getValue('material_markup'))(nodeID) / 100),
  material_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_total'), getCalculatedValue('material_unit_total'), getValue('material_unit_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  labor_amount: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) =>
    pipe(
      getChildren,
      map(
        (childID) =>
          ifElse(getShouldUseCalculatedValue('labor_amount'), getCalculatedValue('labor_amount'), getValue('labor_amount'))(childID) *
          ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(childID)
      ),
      filter(isFinite),
      sum
    ),
  labor_unit_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_amount'), getCalculatedValue('labor_amount'), getValue('labor_amount'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('labor_cost'), getCalculatedValue('labor_cost'), getValue('labor_cost'))(nodeID),
  labor_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_unit_subtotal'), getCalculatedValue('labor_unit_subtotal'), getValue('labor_unit_subtotal'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  labor_unit_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_unit_subtotal'), getCalculatedValue('labor_unit_subtotal'), getValue('labor_unit_subtotal'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('labor_markup'), getCalculatedValue('labor_markup'), getValue('labor_markup'))(nodeID) / 100),
  labor_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_unit_total'), getCalculatedValue('labor_unit_total'), getValue('labor_unit_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  subcontractor_subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_cost'), getCalculatedValue('subcontractor_unit_cost'), getValue('subcontractor_unit_cost'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  subcontractor_unit_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_cost'), getCalculatedValue('subcontractor_unit_cost'), getValue('subcontractor_unit_cost'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('subcontractor_markup'), getCalculatedValue('subcontractor_markup'), getValue('subcontractor_markup'))(nodeID) / 100),
  subcontractor_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_total'), getCalculatedValue('subcontractor_unit_total'), getValue('subcontractor_unit_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  unit_cost: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('material_unit_total'), getCalculatedValue('material_unit_total'), getValue('material_unit_total'))(nodeID) +
    ifElse(getShouldUseCalculatedValue('labor_unit_total'), getCalculatedValue('labor_unit_total'), getValue('labor_unit_total'))(nodeID) +
    ifElse(getShouldUseCalculatedValue('subcontractor_unit_total'), getCalculatedValue('subcontractor_unit_total'), getValue('subcontractor_unit_total'))(nodeID),
  unit_cost_total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('unit_cost'), getCalculatedValue('unit_cost'), getValue('unit_cost'))(nodeID) *
    (1 + ifElse(getShouldUseCalculatedValue('unit_cost_markup'), getCalculatedValue('unit_cost_markup'), getValue('unit_cost_markup'))(nodeID) / 100),
  subtotal: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('unit_cost_total'), getCalculatedValue('unit_cost_total'), getValue('unit_cost_total'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  total: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) => {
    return (
      ifElse(getShouldUseCalculatedValue('subtotal'), getCalculatedValue('subtotal'), getValue('subtotal'))(nodeID) *
      (1 + ifElse(getShouldUseCalculatedValue('subtotal_markup'), getCalculatedValue('subtotal_markup'), getValue('subtotal_markup'))(nodeID) / 100)
    );
  },
  cost: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) => {
    //const unit_cost = ifElse(getShouldUseCalculatedValue('unit_cost'), getCalculatedValue('unit_cost'), getValue('unit_cost'))(nodeID);
    const unit_cost = getValue('unit_cost')(nodeID);
    const unit_amount = ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID);
    if (unit_cost) return unit_cost * unit_amount;
    return (
      (ifElse(getShouldUseCalculatedValue('material_unit_subtotal'), getCalculatedValue('material_unit_subtotal'), getValue('material_unit_subtotal'))(nodeID) +
        ifElse(getShouldUseCalculatedValue('labor_unit_subtotal'), getCalculatedValue('labor_unit_subtotal'), getValue('labor_unit_subtotal'))(nodeID) +
        ifElse(getShouldUseCalculatedValue('subcontractor_unit_cost'), getCalculatedValue('subcontractor_unit_cost'), getValue('subcontractor_unit_cost'))(nodeID)) *
      unit_amount
    );
  },
  profit: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))(nodeID) -
    ifElse(getShouldUseCalculatedValue('cost'), getCalculatedValue('cost'), getValue('cost'))(nodeID),
  margin: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('profit'), getCalculatedValue('profit'), getValue('profit'))(nodeID) /
    ifElse(getShouldUseCalculatedValue('total'), getCalculatedValue('total'), getValue('total'))(nodeID),
  total_labor_hours: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('labor_amount'), getCalculatedValue('labor_amount'), getValue('labor_amount'))(nodeID) *
    ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID),
  labor_days: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) =>
    ifElse(getShouldUseCalculatedValue('total_labor_hours'), getCalculatedValue('total_labor_hours'), getValue('total_labor_hours'))(nodeID) /
    (ifElse(getShouldUseCalculatedValue('crew_size'), getCalculatedValue('crew_size'), getValue('crew_size'))(nodeID) * 8),
};

const AssemblyEntryCalculations = {
  assembly_entry_total_amount: (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (nodeID) => {
    const groupUnitAmount = pipe(getGroup, ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount')))(nodeID);
    const selfUnitAmount = ifElse(getShouldUseCalculatedValue('unit_amount'), getCalculatedValue('unit_amount'), getValue('unit_amount'))(nodeID);
    return groupUnitAmount * selfUnitAmount;
  },
};

const fullGetNodeColor = (getIsAssemblyEntry) => (getNodeGroup) => (getNodeColorLens) => (nodeID) =>
  getIsAssemblyEntry(nodeID) ? fullGetNodeColor(getIsAssemblyEntry)(getNodeGroup)(getNodeColorLens)(getNodeGroup(nodeID)) : view(getNodeColorLens(nodeID))(nodeID);

const fullGetChildren2 = (state) => (nodeID) => defaultTo([])(view(getNodeChildrenLens(nodeID))(state));

const fullGetPrimaryValue = (getIsEditable) => (getIsCalculated) => (getValue) => (getCalculatedValue) => (column) => (nodeID) => {
  const isEditable = getIsEditable(column)(nodeID);
  const isCalculated = getIsCalculated(column)(nodeID);
  if (isEditable && isCalculated) return getValue(column)(nodeID) ?? getCalculatedValue(column)(nodeID);
  if (isCalculated) return getCalculatedValue(column)(nodeID);
  return getValue(column)(nodeID);
};

const fullGetShouldUseCalculatedValue = (getIsEditable) => (getIsCalculated) => (getValue) => (getCalculatedValue) => (column) => (nodeID) => {
  const isEditable = getIsEditable(column)(nodeID);
  const isCalculated = getIsCalculated(column)(nodeID);
  if (isEditable && isCalculated) return isNil(getValue(column)(nodeID));
  if (isCalculated) return true;
  return false;
};

//calculates (but does not memoize) (only use this function for calculated values)
const fullCalculateWithDependencies = (getNodeType) => (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (getCalculatedValue) => (column) => (nodeID) => {
  if (getNodeType(nodeID) == 'group') return GroupCalculations[column](getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)(getCalculatedValue)(nodeID);
  if (getNodeType(nodeID) == 'assembly') return AssemblyCalculations[column](getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)(getCalculatedValue)(nodeID);
  if (getNodeType(nodeID) == 'assembly_entry') {
    const calcFn = AssemblyEntryCalculations[column];
    if (isNil(calcFn)) return null;
    return calcFn(getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)(getCalculatedValue)(nodeID);
  }
  if (isNil(ItemCalculations[column])) return null;
  return ItemCalculations[column](getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)(getCalculatedValue)(nodeID);
};

// memoizing wrapper function used on top of fullCalculateWithDependencies to prevent recalculating the same values
const fullGetCalculationsObject =
  (existingCalculationsObject) => (getIsCalculated) => (getDependencies) => (getNodeType) => (getGroup) => (getChildren) => (getShouldUseCalculatedValue) => (getValue) => (column) => (nodeID) => {
    if ((column in existingCalculationsObject && nodeID in existingCalculationsObject[column]) || !getIsCalculated(column)(nodeID)) return existingCalculationsObject;
    const dependencies = getDependencies(column)(nodeID);
    const depCalculationsObject = reduce((acc, [depCol, depID]) =>
      fullGetCalculationsObject(acc)(getIsCalculated)(getDependencies)(getNodeType)(getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)(depCol)(depID)
    )(existingCalculationsObject)(dependencies);
    const result = fullCalculateWithDependencies(getNodeType)(getGroup)(getChildren)(getShouldUseCalculatedValue)(getValue)((col) => (id) => depCalculationsObject[col][id])(column)(nodeID);
    return mergeDeepRight(depCalculationsObject)({ [column]: { [nodeID]: result } });
  };

export {
  getIsColumnApplicableToGroup,
  fullGetCalculationsObject,
  fullGetChildren2,
  fullGetPrimaryValue,
  fullGetDependencies,
  fullGetIsApplicable,
  fullGetIsCalculated,
  fullGetIsEditable,
  fullGetShouldUseCalculatedValue,
};
