import { rectangle, Circle, Group, Rect, Text, Transformer, Line, Arc, Path } from "react-konva";
import Konva from "konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";
import { Html, Portal } from "react-konva-utils";
import pSBC from "../../helper/Colors";
import ContextMenu from "../../../components/ContextMenu";
import { IconCircleMinus, IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function AnnotationCloud({ annotation }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        handleUpdateAnnotation,
        pages, pageID,
        setEditingMeasurement,
        drawing,
        currentMeasurement, setCurrentMeasurement,
        selectedAnnotations, setSelectedAnnotations,
        currentAnnotation, setCurrentAnnotation,
        selectedMeasurements, setSelectedMeasurements,
        setShowDeleteAnnotationModal,
        handleZoomToAnnotation,
    } = useContext(TakeoffContext);

    const isSelected = (currentAnnotation === annotation.id && !drawing && !currentMeasurement);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [start, setStart] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setCurrentAnnotation(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    function konvaSquigglePath(points, squiggleStep, squiggleAmplitude) {
        let path = ""
        //assemble the path for the entire array of dots
        for (let i = 0; i < points.length; i++) {
            if (i === 0) {
                path += "M" + points[i].x + "," + points[i].y;
            } else {
                path += "L" + points[i].x + "," + points[i].y;
            }
        }

        let p = new Konva.Path({ data: path }),
            pathLen = p.getLength(),
            numSteps = Math.round(pathLen / squiggleStep),
            pos = p.getPointAtLength(0),
            newPath = "M" + [pos.x, pos.y].join(','),
            side = -1;

        for (let i = 1; i <= numSteps; i++) {
            let last = pos;
            pos = p.getPointAtLength(i * pathLen / numSteps);

            let vector = { x: pos.x - last.x, y: pos.y - last.y };
            let vectorLen = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
            let half = { x: last.x + vector.x / 2, y: last.y + vector.y / 2 };
            let perpVector = { x: -(squiggleAmplitude * vector.y / vectorLen), y: (squiggleAmplitude * vector.x / vectorLen) };
            let controlPoint = { x: half.x + perpVector.x * side, y: half.y + perpVector.y * side };

            newPath += "Q" + [controlPoint.x, controlPoint.y, pos.x, pos.y].join(',');
            side = -side;
        }

        return newPath;
    }

    return (
        <>
            <Html>
                {showContextMenu && currentAnnotation === annotation.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        <div
                            className="contextmenu-item"
                            onClick={() => handleCreateAnnotation({
                                type: "cloud",
                                data: {
                                    dots: annotation.cloud.dots,
                                    stroke_color: annotation.stroke_color,
                                    fill_color: annotation.fill_color,
                                    size: annotation.size,
                                }
                            })}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToAnnotation(annotation.id)}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => setShowDeleteAnnotationModal(true)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>
                                Delete
                            </div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <Group
                    draggable={isSelected && !drawing}
                    onDragStart={(e) => {
                        setStart({ x: e.target.x(), y: e.target.y() });
                        setDragging(true);
                    }}
                    onDragEnd={(e) => {
                        setDragging(false);
                        let dx = e.target.x() - start.x;
                        let dy = e.target.y() - start.y;

                        let newDots = annotation.cloud.dots.map((dot) => ({
                            id: dot.id,
                            x: dot.x + dx,
                            y: dot.y + dy,
                        }));

                        handleUpdateAnnotation({
                            ...annotation,
                            cloud: {
                                ...annotation.cloud,
                                dots: newDots,
                            }
                        });

                        e.target.x(0);
                        e.target.y(0);
                    }}
                >
                    <Path
                        data={konvaSquigglePath(annotation.cloud.dots.concat(annotation.cloud.dots[0]), annotation.size / pages[pageID].zoom, annotation.size / pages[pageID].zoom)}
                        stroke={annotation.stroke_color}
                        strokeWidth={(annotation.size / 3) / pages[pageID].zoom}
                        opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.7 : 0.5}
                    />

                    <Line
                        points={annotation.cloud.dots.concat(annotation.cloud.dots[0]).flatMap((dot) => [dot.x, dot.y])}
                        stroke={annotation.stroke_color}
                        strokeWidth={annotation.size / pages[pageID].zoom}
                        opacity={0}
                        onClick={(e) => {
                            if (e.evt.button !== 0) return;

                            if (e.evt.metaKey) {
                                if (selectedAnnotations.includes(annotation.id)) {
                                    setSelectedAnnotations(selectedAnnotations.filter((id) => id !== annotation.id));
                                } else {
                                    setSelectedAnnotations([...selectedAnnotations, annotation.id]);
                                }
                            } else {
                                setSelectedAnnotations([annotation.id]);
                                setCurrentAnnotation(annotation.id)
                            }

                            setCurrentMeasurement(null);
                            setSelectedMeasurements([]);
                        }}
                        onContextMenu={(e) => {
                            e.evt.preventDefault();
                            setSelectedAnnotations([annotation.id]);
                            setCurrentAnnotation(annotation.id);
                            setCurrentMeasurement(null);

                            setContextMenuPosition({
                                x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                            })
                            setShowContextMenu(true);
                        }}
                        onMouseEnter={(e) => {
                            e.target.getStage().container().style.cursor = 'pointer';
                        }}
                        onMouseLeave={(e) => {
                            e.target.getStage().container().style.cursor = 'default';
                        }}
                    />

                    {/*for each segment render a line segment connecting two neighboring points so we can let users create new dots */}
                    {isSelected && annotation.cloud.dots.map((dot, index) => (
                        <Line
                            key={index}
                            points={[
                                dot.x, dot.y,
                                annotation.cloud.dots[(index + 1) % annotation.cloud.dots.length].x,
                                annotation.cloud.dots[(index + 1) % annotation.cloud.dots.length].y
                            ]}
                            stroke="transparent"
                            strokeWidth={10 / pages[pageID].zoom}
                            onMouseEnter={(e) => {
                                e.target.getStage().container().style.cursor = 'copy';
                            }}
                            onMouseLeave={(e) => {
                                e.target.getStage().container().style.cursor = 'default';
                            }}
                            onContextMenu={(e) => {
                                e.evt.preventDefault();
                                setSelectedAnnotations([annotation.id]);
                                setCurrentAnnotation(annotation.id);
                                setCurrentMeasurement(null);

                                setContextMenuPosition({
                                    x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                    y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                                })
                                setShowContextMenu(true);
                            }}
                            onClick={(e) => {
                                if (e.evt.button !== 0) return;

                                let newDots = [
                                    ...annotation.cloud.dots.slice(0, index + 1),
                                    {
                                        x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                        y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                                    },
                                    ...annotation.cloud.dots.slice(index + 1)
                                ];

                                handleUpdateAnnotation({
                                    ...annotation,
                                    cloud: {
                                        ...annotation.cloud,
                                        dots: newDots,
                                    }
                                });
                            }}
                        />
                    ))}
                </Group>

                {isSelected && !dragging && annotation.cloud.dots.map((dot, index) => (
                    <AnnotationCloudVertex
                        key={index}
                        dot={dot}
                        isSelected={isSelected}
                        annotation={annotation}
                    />
                ))}
            </Portal>
        </>
    )
}

const AnnotationCloudVertex = ({ dot, isSelected, annotation }) => {
    const {
        pages,
        pageID,
        setAnnotations,
        handleUpdateAnnotation,
        setShowDeleteAnnotationModal,
        handleCreateAnnotation,
        handleZoomToAnnotation,
        currentAnnotation,
    } = useContext(TakeoffContext);

    const [isHovered, setIsHovered] = useState(false);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    return (
        <>
            <Html>
                {showContextMenu && currentAnnotation === annotation.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        <div
                            className="contextmenu-item"
                            onClick={() => handleCreateAnnotation({
                                type: "cloud",
                                data: {
                                    dots: annotation.cloud.dots,
                                    stroke_color: annotation.stroke_color,
                                    fill_color: annotation.fill_color,
                                    size: annotation.size,
                                }
                            })}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToAnnotation(annotation.id)}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        {annotation.cloud.dots.length > 3 &&
                            <div
                                className="contextmenu-item contextmenu-item-delete"
                                onClick={() => {
                                    handleUpdateAnnotation({
                                        ...annotation,
                                        cloud: {
                                            ...annotation.cloud,
                                            dots: annotation.cloud.dots.filter((d) => d.id !== dot.id).map((d, index) => ({
                                                x: d.x,
                                                y: d.y,
                                            }))
                                        }
                                    });
                                }}
                            >
                                <IconCircleMinus size={16} stroke={1} />
                                <div>Delete dot</div>
                            </div>
                        }

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => setShowDeleteAnnotationModal(true)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>Delete</div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Circle
                draggable={true}
                x={dot.x}
                y={dot.y}
                radius={5 / pages[pageID].zoom}
                fill={isHovered ? annotation.fill_color : 'white'}
                stroke={annotation.fill_color}
                strokeWidth={2 / pages[pageID].zoom}
                onContextMenu={(e) => {
                    e.evt.preventDefault();
                    setContextMenuPosition({
                        x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                        y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                    })
                    setShowContextMenu(true);
                }}
                onMouseEnter={(e) => {
                    e.target.getStage().container().style.cursor = 'pointer';
                    setIsHovered(true);
                }}
                onMouseLeave={(e) => {
                    setIsHovered(false);
                    e.target.getStage().container().style.cursor = 'default';
                }}
                onDragMove={(e) => {
                    console.log(e.target.x(), e.target.y())
                    setAnnotations(prev => ({
                        ...prev,
                        [annotation.id]: {
                            ...annotation,
                            cloud: {
                                ...annotation.cloud,
                                dots: [
                                    ...annotation.cloud.dots.slice(0, annotation.cloud.dots.findIndex((d) => d.id === dot.id)),
                                    {
                                        ...dot,
                                        x: e.target.x(),
                                        y: e.target.y(),
                                    },
                                    ...annotation.cloud.dots.slice(annotation.cloud.dots.findIndex((d) => d.id === dot.id) + 1)
                                ]
                            }
                        }
                    }))
                }}
                onDragEnd={(e) => {
                    handleUpdateAnnotation({
                        ...annotation,
                        cloud: {
                            ...annotation.cloud,
                            dots: [
                                ...annotation.cloud.dots.slice(0, annotation.cloud.dots.findIndex((d) => d.id === dot.id)),
                                {
                                    ...dot,
                                    x: e.target.x(),
                                    y: e.target.y(),
                                },
                                ...annotation.cloud.dots.slice(annotation.cloud.dots.findIndex((d) => d.id === dot.id) + 1)
                            ]
                        }
                    })
                }}
            />
        </>
    )
}