import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAuth } from '../redux/slices/authSlice';
import {
  andThen,
  defaultTo,
  lensIndex,
  lensProp,
  pipe,
  view,
  compose,
  prop,
  map,
  set,
  equals,
  sortBy,
  tryCatch,
  toLower,
  ifElse,
  isNil,
  always,
  identity,
  F,
  not,
  add,
  mathMod,
  indexOf,
  length,
  last,
  reject,
  lensPath,
  all,
  when,
  append,
  includes,
  over,
  filter,
  omit,
  isEmpty,
  min,
  flatten,
  max,
  slice,
  isNotNil,
  multiply,
  mergeDeepLeft,
  fromPairs,
} from 'ramda';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';
import {
  axiosCurry,
  createLookupByKeyName,
  eventValueLens,
  formDataFromObj,
  getIsFilePdf,
  getStringFirstMatchArray,
  mapIndexed,
  renameFile,
  responseDataLens,
  reverseOver,
  reverseView,
} from '../utilities/utilities';
import { Stack, Navbar, Form, Card, Button, Badge, Modal, ButtonGroup, Offcanvas, Toast, ToastContainer, ProgressBar, InputGroup, ListGroup } from 'react-bootstrap';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  IconArrowLeft,
  IconArrowRight,
  IconCheck,
  IconCircle,
  IconCircleCheckFilled,
  IconCloudUpload,
  IconCopy,
  IconCornerDownLeft,
  IconCursorText,
  IconDownload,
  IconEye,
  IconProgressCheck,
  IconRobot,
  IconTrashX,
  IconUpload,
  IconZoomIn,
  IconZoomOut,
} from '@tabler/icons-react';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { useContextMenu, Menu } from 'react-contexify';
import ContextMenuItem from '../components/ContextMenuItem';
import { StyledSortableRow } from '../files/Components';
import axios from 'axios';
import { Blocks } from 'react-loader-spinner';
import useWebSocket from 'react-use-websocket';
import FilesNavbar from '../files/FilesNavbar';
import Dropzone from 'react-dropzone';
import { IconSearch } from '@tabler/icons-react';
import './styles.css';

const FILE_CONTEXT_MENU_ID = 'file-context-menu';
const PAGE_CONTEXT_MENU_ID = 'page-context-menu';

const sortingLens = lensProp('sorting');
const sortingAttributeLens = compose(sortingLens, lensProp('attribute'));
const currentFileUuidLens = lensProp('currentFileUuid');
const modalLens = lensProp('modal');
const isToastSidebarOpenLens = lensProp('isToastSidebarOpen');
const pageViewerModalUuidLens = compose(modalLens, lensProp('pageViewerUuid'));
const fileDeletionModalUuidLens = compose(modalLens, lensProp('fileDeletionUuid'));
const pageDeletionModalUuidLens = compose(modalLens, lensProp('pageDeletionUuid'));
const pageRenameModalLens = compose(modalLens, lensProp('pageRename'));
const pageRenameModalUuidLens = compose(pageRenameModalLens, lensProp('uuid'));
const pageRenameModalTextLens = compose(pageRenameModalLens, lensProp('text'));
const fileRenameModalLens = compose(modalLens, lensProp('fileRename'));
const fileRenameModalUuidLens = compose(fileRenameModalLens, lensProp('uuid'));
const fileRenameModalTextLens = compose(fileRenameModalLens, lensProp('text'));
const searchModalLens = compose(modalLens, lensProp('search'));
const searchModalIsOpenLens = compose(searchModalLens, lensProp('isOpen'));
const searchModalTextLens = compose(searchModalLens, lensProp('text'));
const searchModalIsLoadingLens = compose(searchModalLens, lensProp('isLoading'));
const searchModalMatchedFileUuidsLens = compose(searchModalLens, lensProp('matchedFileUuids'));
const searchModalPreviewPageUuidLens = compose(searchModalLens, lensProp('previewPageUuid'));
const contextMenuLens = lensProp('contextMenu');
const pageContextMenuUuidLens = compose(contextMenuLens, lensProp('pageUuid'));
const fileContextMenuUuidLens = compose(contextMenuLens, lensProp('fileUuid'));
const dragLens = lensProp('drag');
const firstPageUuidAnchorLens = lensProp('firstPageUuidAnchor');
const fileDragUuidLens = compose(dragLens, lensProp('fileUuid'));
const zoomLevelLens = lensProp('zoomLevel');
const fileUuidsLens = lensProp('fileUuids');
const uploadKeysLens = lensProp('uploadKeys');
const uploadObjectsLens = lensProp('uploadObjects');
const nodesLens = lensProp('nodes');

const getNodeLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid));
const getNodeNameLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('name'));
const getNodePageUuidsLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('page_uuids'));
const getNodeMatchedPageUuidsLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('matched_page_uuids'));
const getNodeMatchedTextBoxUuidsLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('matched_text_box_uuids'));
const getNodeThumbnailURLLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('thumbnail_url'));
const getNodeFileURLLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('file_url'));
const getNodeTitleLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('title'));
const getNodeIsIncludedLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('is_included'));
const getNodeIsAiPreppedLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('ai_prepped'));
const getNodeParentFileUuidLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('parent_file_uuid'));
const getNodeIndexLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('index'));
const getNodeIsUploadCompleteLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('is_upload_complete'));
const getNodeMeasurementCountLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('measurement_count'));
const getNodeTextLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('text'));

const getUploadObjectLens = (uploadKey) => compose(uploadObjectsLens, lensProp(uploadKey));
const getUploadObjectProgressLens = (uploadKey) => compose(getUploadObjectLens(uploadKey), lensProp('progress'));
const getUploadObjectMessageLens = (uploadKey) => compose(getUploadObjectLens(uploadKey), lensProp('message'));

const initialState = {
  sorting: { attribute: 'index' },
  currentFileUuid: null,
  modal: {
    pageViewerUuid: null,
    fileDeletionUuid: null,
    pageDeletionUuid: null,
    pageRename: { uuid: null, text: '' },
    fileRename: { uuid: null, text: '' },
    search: { isOpen: false, text: '', isLoading: false, matchedFileUuids: [], previewPageUuid: null },
  },
  isToastSidebarOpen: false,
  contextMenu: { fileUuid: null, pageUuid: null },
  drag: { fileUuid: null },
  firstPageUuidAnchor: null,
  uploadKeys: [],
  uploadObjects: {},
  zoomLevel: 200,
  fileUuids: [],
  nodes: {},
};

const openFile = (fileUuid) => pipe(set(currentFileUuidLens)(fileUuid), set(firstPageUuidAnchorLens)(null));
const refreshFilesData = (filesArray) => (state) => {
  const fileUuids = pipe(defaultTo([]), map(prop('uuid')))(filesArray);
  const nodes = createLookupByKeyName('uuid')(filesArray);
  return pipe(set(fileUuidsLens)(fileUuids), set(nodesLens)(nodes))(state);
};
const setSearchResults = (filesArray) => (state) => {
  const fileUuids = pipe(defaultTo([]), map(prop('uuid')))(filesArray);
  const nodes = createLookupByKeyName('uuid')(filesArray);
  return pipe(set(searchModalMatchedFileUuidsLens)(fileUuids), set(searchModalIsLoadingLens)(false), over(nodesLens)(mergeDeepLeft(nodes)))(state);
};
const openPageRenameModal = (pageUuid) => (state) => {
  const pageTitle = pipe(getNodeTitleLens, reverseView(state))(pageUuid);
  return pipe(set(pageRenameModalTextLens)(pageTitle), set(pageRenameModalUuidLens)(pageUuid))(state);
};
const openFileRenameModal = (fileUuid) => (state) => {
  const filename = pipe(getNodeNameLens, reverseView(state))(fileUuid);
  return pipe(set(fileRenameModalTextLens)(filename), set(fileRenameModalUuidLens)(fileUuid))(state);
};
const unmountPage = (pageUuid) => (state) => pipe(getNodeParentFileUuidLens, reverseView(state), getNodePageUuidsLens, reverseOver(state)(reject(equals(pageUuid))))(pageUuid);
const unmountFile = (fileUuid) => (state) => pipe(over(fileUuidsLens)(reject(equals(fileUuid))), over(currentFileUuidLens)(when(equals(fileUuid))(always(null))))(state);
const closeRenamePageModal = pipe(set(pageRenameModalUuidLens)(null), set(pageRenameModalTextLens)(''));
const closeRenameFileModal = pipe(set(fileRenameModalUuidLens)(null), set(fileRenameModalTextLens)(''));
const closeSearchModal = pipe(
  set(searchModalIsOpenLens)(false),
  set(searchModalTextLens)(''),
  set(searchModalIsLoadingLens)(false),
  set(searchModalMatchedFileUuidsLens)([]),
  set(searchModalPreviewPageUuidLens)(null)
);
const closeAllModals = pipe(
  set(pageViewerModalUuidLens)(null),
  set(fileDeletionModalUuidLens)(null),
  set(pageDeletionModalUuidLens)(null),
  closeRenamePageModal,
  closeRenameFileModal,
  closeSearchModal
);
const hopPage = (amount) => (pageUuid) => (state) => {
  const pageUuids = pipe(getNodeParentFileUuidLens, reverseView(state), getNodePageUuidsLens, reverseView(state))(pageUuid);
  const numPages = length(pageUuids);
  const newIndex = pipe(indexOf(pageUuid), add(amount), (n) => mathMod(n)(numPages))(pageUuids);
  return ifElse(
    isNil,
    always(state),
    pipe(lensIndex, reverseView(pageUuids), (i) => set(pageViewerModalUuidLens)(i)(state))
  )(newIndex);
};
const modifyFileIndex = (index) => (fileUuid) => (state) => pipe(getNodeIndexLens, (lens) => set(lens)(index)(state))(fileUuid);
const modifyPageInclusion = (isIncluded) => (pageUuid) => (state) => pipe(getNodeIsIncludedLens, (lens) => set(lens)(isIncluded)(state))(pageUuid);
const updateOrCreateUpload = (uploadKey) => (progress) => (message) =>
  pipe(
    when(pipe(view(uploadKeysLens), includes(uploadKey), not), pipe(over(uploadKeysLens)(append(uploadKey)))),
    set(getUploadObjectMessageLens(uploadKey))(message),
    set(getUploadObjectProgressLens(uploadKey))(progress)
  );
const removeUpload = (uploadKey) => (state) => pipe(over(uploadKeysLens)(reject(equals(uploadKey))), over(uploadObjectsLens)(omit([uploadKey])))(state);

export const Files2 = () => {
  const params = useParams();
  const projectUUID = params.projectUUID;
  const auth = useSelector(selectAuth);
  const filesApi = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/files2-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }),
    [API_ROUTE, auth.token, projectUUID]
  );
  const getAWSPresignedHeaders = (filename) =>
    pipe(axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post'), andThen(view(responseDataLens)))({ filename });
  const fileUploadInputRef = useRef(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${WEBSOCKET_ROUTE}/file-upload-consumer/${projectUUID}/`, {
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 60000, // 1 minute, if no response is received, the connection will be closed
      interval: 25000, // every 25 seconds, a ping message will be sent
    },
    onMessage: (e) => {
      const data = pipe(view(responseDataLens), JSON.parse)(e);
      const { operation, uploadKey, progress, message } = data;
      if (operation == 'updateOrCreateUpload') {
        setState(updateOrCreateUpload(uploadKey)(progress)(message));
      } else if (operation == 'removeUpload') {
        setState(removeUpload(uploadKey));
        pipe(filesApi('post'), andThen(view(responseDataLens)), andThen(last), andThen(refreshFilesData), andThen(setState))([{ operation: 'files_get' }]);
      }
    },
    onClose: console.log,
    shouldReconnect: always(true),
    onOpen: console.log,
  });

  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);

  // Context Menus ======================================================================================
  const { show: showFileContextMenu } = useContextMenu({ id: FILE_CONTEXT_MENU_ID });
  const { show: showPageContextMenu } = useContextMenu({ id: PAGE_CONTEXT_MENU_ID });

  // Handlers (with side effects) =======================================================================
  const handlePageInclusion = (isIncluded) => (pageUuid) => {
    pipe(getNodeIsIncludedLens, (lens) => set(lens)(isIncluded), setState)(pageUuid);
    pipe(filesApi('post'))([{ operation: 'page_update', uuid: pageUuid, is_included: isIncluded }]);
  };

  const handlePageRangeInclusion = (pageUuid) => {
    const pageUuids = pipe(getNodeParentFileUuidLens, viewState, getNodePageUuidsLens, viewState)(pageUuid);
    const index1 = indexOf(pageUuid)(pageUuids);
    const index2 = indexOf(viewState(firstPageUuidAnchorLens))(pageUuids);
    if (index1 == -1 || index2 == -1) return;
    const first = min(index1, index2);
    const last = max(index1, index2);
    const slicedPageUuids = slice(first, last + 1)(pageUuids);
    const areAllIncluded = pipe(map(getNodeIsIncludedLens), map(viewState), all(identity))(slicedPageUuids);
    pipe(
      map(getNodeIsIncludedLens),
      map((lens) => set(lens)(!areAllIncluded)),
      (fnList) => setState(pipe(...fnList, set(firstPageUuidAnchorLens)(null)))
    )(slicedPageUuids);
    pipe(
      map((pageUuid) => ({ operation: 'page_update', is_included: !areAllIncluded, uuid: pageUuid })),
      filesApi('post')
    )(slicedPageUuids);
  };

  const handleImageUpload = (file) => {
    const filename = file.name;
    const presignedHeadersPromise = getAWSPresignedHeaders(filename);
    const fileUploadPromise = andThen(({ url, fields }) => {
      const { key } = fields;
      const onUploadProgress = ({ loaded, total }) => setState(updateOrCreateUpload(key)(Math.round((loaded / total) * 100))(`Uploading ${filename}`));
      return axios.post(url, formDataFromObj({ ...fields, file: file }), { onUploadProgress });
    })(presignedHeadersPromise);
    const fileCreationPromise = andThen(([presignedHeaders, _]) =>
      filesApi('post')([{ operation: 'file_create_image', file_key: presignedHeaders.fields.key, name: filename }, { operation: 'files_get' }])
    )(Promise.all([presignedHeadersPromise, fileUploadPromise]));
    andThen(([responseData, presignedHeaders]) => {
      const key = presignedHeaders.fields.key;
      const filesArray = pipe(view(responseDataLens), last)(responseData);
      setState(pipe(refreshFilesData(filesArray), removeUpload(key)));
    })(Promise.all([fileCreationPromise, presignedHeadersPromise]));
  };

  const handlePdfUpload = (file) => {
    const filename = file.name;
    const presignedHeadersPromise = getAWSPresignedHeaders(filename);
    const fileUploadPromise = andThen(({ url, fields }) => {
      const { key } = fields;
      const onUploadProgress = ({ loaded, total }) => setState(updateOrCreateUpload(key)(Math.round((loaded / total) * 50))(`Uploading ${filename}`));
      return axios.post(url, formDataFromObj({ ...fields, file: file }), { onUploadProgress });
    })(presignedHeadersPromise);
    const fileCreationPromise = andThen(([presignedHeaders, _]) =>
      filesApi('post')([{ operation: 'file_create_pdf', file_key: presignedHeaders.fields.key, name: filename }, { operation: 'files_get' }])
    )(Promise.all([presignedHeadersPromise, fileUploadPromise]));
    // pipe(andThen(view(responseDataLens)), andThen(last), andThen(refreshFilesData), andThen(setState))(fileCreationPromise);
  };

  const handleFilesUpload = (files) => {
    const cleanedFiles = map((file) => renameFile(file.name.replace(/[^a-zA-Z0-9.]/g, ''))(file))(files);
    const pdfFiles = filter(getIsFilePdf)(cleanedFiles);
    const imageFiles = reject(getIsFilePdf)(cleanedFiles);
    map(handleImageUpload)(imageFiles);
    map(handlePdfUpload)(pdfFiles);
  };

  //Effects =============================================================================================
  useEffect(() => {
    // this is the initial fetch
    pipe(
      filesApi('post'),
      andThen(view(responseDataLens)),
      andThen(last),
      andThen(refreshFilesData),
      andThen(setState)
    )([{ operation: 'files_generate_uuids' }, { operation: 'pages_generate_uuids' }, { operation: 'files_get' }]);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key == 'Escape' || e.key == 'Enter') {
        if (document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') document.activeElement.blur();
      }
      //if right arrow and modal is open, go to next page
      const pageViewerModalUuid = viewState(pageViewerModalUuidLens);
      if (e.key == 'ArrowRight' && pageViewerModalUuid) setState(hopPage(1)(pageViewerModalUuid));
      //if left arrow and modal is open, go to previous page
      if (e.key == 'ArrowLeft' && pageViewerModalUuid) setState(hopPage(-1)(pageViewerModalUuid));
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [viewState(pageViewerModalUuidLens)]);

  //Memos ===============================================================================================
  const sortedFileUuids = useMemo(() => {
    const sortingAttribute = viewState(sortingAttributeLens);
    const sortFn = pipe(
      getNodeLens,
      viewState,
      prop(sortingAttribute),
      tryCatch(toLower, (_, value) => value)
    );
    return pipe(viewState, sortBy(sortFn))(fileUuidsLens);
  }, [viewState(nodesLens), viewState(sortingAttributeLens), viewState(fileUuidsLens)]);

  const numPagesIncluded = useMemo(
    () => pipe(viewState, map(getNodePageUuidsLens), map(viewState), flatten, map(getNodeIsIncludedLens), map(viewState), filter(identity), length)(fileUuidsLens),
    [viewState(nodesLens), viewState(fileUuidsLens)]
  );

  const pageDictionary = useMemo(
    () =>
      pipe(
        viewState,
        map(getNodePageUuidsLens),
        map(viewState),
        flatten,
        map(getNodeLens),
        map(viewState),
        map((pageObj) => [pageObj.id, pageObj]),
        fromPairs
      )(fileUuidsLens),
    [viewState(nodesLens), viewState(fileUuidsLens)]
  );

  return (
    <Stack className='w-screen h-screen overflow-hidden' direction='vertical'>
      <FilesNavbar projectUUID={projectUUID} className='shrink-0' pages={pageDictionary} />
      <Stack direction='horizontal' className='overflow-hidden grow'>
        <Stack className='overflow-hidden border-r border-gray-revell w-96 grow-0 shrink-0'>
          <Navbar className='p-2'>
            <Stack direction='horizontal' gap={1} className='w-full'>
              <Form.Select onChange={pipe(view(eventValueLens), set(sortingAttributeLens), setState)} value={viewState(sortingAttributeLens)} className='hover:cursor-pointer hover:bg-gray-revell'>
                <option value='index'>Custom</option>
                <option value='name'>Name</option>
                <option value='created'>Date Created</option>
              </Form.Select>
              <ButtonGroup>
                <Button variant='outline-primary' onClick={() => pipe(set(searchModalIsOpenLens), setState)(true)}>
                  <IconSearch />
                </Button>
                <Button variant='outline-primary' onClick={() => fileUploadInputRef.current.click()}>
                  <IconUpload />
                </Button>
                <Button variant='outline-primary' onClick={() => pipe(set(isToastSidebarOpenLens), setState)(true)}>
                  {pipe(view(uploadKeysLens))(state).length > 0 ? (
                    <Blocks visible={true} height='24' width='24' color='#006AFE' ariaLabel='blocks-loading' radius='12' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                  ) : (
                    <IconProgressCheck />
                  )}
                </Button>
              </ButtonGroup>
            </Stack>
          </Navbar>
          <Stack className='overflow-scroll grow'>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragStart={pipe(view(lensPath(['active', 'id'])), set(fileDragUuidLens), setState)}
              onDragEnd={(event) => {
                const { active, over } = event;
                if (active.id !== over.id) {
                  const oldIndex = sortedFileUuids.indexOf(active.id);
                  const newIndex = sortedFileUuids.indexOf(over.id);
                  const newOrder = arrayMove(sortedFileUuids, oldIndex, newIndex);
                  pipe(
                    mapIndexed((fileUuid, index) => modifyFileIndex(index)(fileUuid)),
                    (fnList) => setState(pipe(...fnList, identity))
                  )(newOrder);
                  pipe(
                    mapIndexed((fileUuid, index) => ({ operation: 'file_update', index, uuid: fileUuid })),
                    filesApi('post')
                  )(newOrder);
                }
              }}
            >
              <SortableContext items={viewState(fileUuidsLens)}>
                {map((fileUuid) => (
                  <StyledSortableRow
                    onContextMenu={(e) => {
                      showFileContextMenu({ event: e });
                      pipe(set(fileContextMenuUuidLens), setState)(fileUuid);
                    }}
                    disabled={pipe(viewState, equals('index'), not)(sortingAttributeLens)}
                    key={fileUuid}
                    id={fileUuid}
                    active={pipe(viewState, equals(fileUuid))(currentFileUuidLens)}
                    onClick={() => pipe(openFile, setState)(fileUuid)}
                    className={`flex flex-row items-center overflow-hidden  hover:bg-gray-revell cursor-pointer select-none h-10 shrink-0 ${pipe(
                      viewState,
                      ifElse(equals(fileUuid), always('!bg-blue-alice text-blue-bobyard'), F)
                    )(currentFileUuidLens)}`}
                  >
                    <div
                      //font inter
                      className='overflow-hidden grow text-ellipsis text-nowrap font-inter'
                    >
                      {pipe(getNodeNameLens, viewState)(fileUuid)}
                    </div>
                    {pipe(
                      getNodeIsUploadCompleteLens,
                      viewState,
                      ifElse(
                        identity,
                        F,
                        always(
                          <div className='shrink-0'>
                            <Blocks height='30' width='30' color='#006AFE' ariaLabel='blocks-loading' radius='5' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                          </div>
                        )
                      )
                    )(fileUuid)}
                  </StyledSortableRow>
                ))(sortedFileUuids)}
              </SortableContext>
              <DragOverlay>
                {pipe(
                  viewState,
                  ifElse(isNil, F, (fileUuid) => (
                    <StyledSortableRow className={`flex flex-row items-center overflow-hidden text-ellipsis rounded hover:bg-gray-revell cursor-pointer select-none h-10 shadow-lg bg-white`}>
                      {pipe(getNodeNameLens, viewState)(fileUuid)}
                    </StyledSortableRow>
                  ))
                )(fileDragUuidLens)}
              </DragOverlay>
            </DndContext>
          </Stack>
        </Stack>
        <Stack className='overflow-hidden grow'>
          {pipe(
            viewState,
            getNodePageUuidsLens,
            viewState,
            ifElse(
              isNil,
              always(
                <Dropzone onDrop={handleFilesUpload}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className='files-upload-placeholder'>
                      <input {...getInputProps()} accept='.pdf,.png,.jpg,.jpeg' />
                      <div className='files-upload-placeholder-title'>
                        <IconUpload size={100} />
                      </div>
                      <div className='files-upload-placeholder-body'>Drag & drop, or click to select</div>
                    </div>
                  )}
                </Dropzone>
              ),
              (pageUuids) => {
                const areAllPagesIncluded = pipe(map(getNodeIsIncludedLens), map(viewState), all(identity))(pageUuids);
                return (
                  <>
                    <div className='files-previews-menu-container'>
                      {pipe(
                        viewState,
                        ifElse(
                          isNil,
                          always(
                            <div
                              style={{
                                color: numPagesIncluded ? 'black' : 'red',
                              }}
                            >
                              {numPagesIncluded ? (
                                <>
                                  <b>{numPagesIncluded}</b> included in all files
                                </>
                              ) : (
                                <>Click on thumbnails to include for takeoff</>
                              )}
                            </div>
                          ),
                          always(
                            <>
                              <Badge bg='info'>From: {pipe(viewState, getNodeTitleLens, viewState)(firstPageUuidAnchorLens)}</Badge>
                              <div className='overflow-hidden text-nowrap text-ellipsis'>Click another page to select range</div>
                            </>
                          )
                        )
                      )(firstPageUuidAnchorLens)}

                      <Navbar.Text className='flex flex-row items-center min-w-0 gap-1 overflow-hidden text-ellipsis shrink'></Navbar.Text>

                      <div className='projectdetails-infopopup-zoom'>
                        <div className='projectdetails-pages-header-button' onClick={() => setState(over(zoomLevelLens)(pipe(multiply(1.25), min(400))))}>
                          <IconZoomIn size={20} stroke={1} />
                        </div>
                        <div className='projectdetails-pages-header-button' onClick={() => setState(over(zoomLevelLens)(pipe(multiply(0.8), max(150))))}>
                          <IconZoomOut size={20} stroke={1} />
                        </div>
                      </div>
                      {ifElse(
                        identity,
                        always(
                          <div
                            className='projectdetails-infopopup-selectall'
                            onClick={() => {
                              pipe(map(modifyPageInclusion(false)), (fnList) => setState(pipe(...fnList, identity)))(pageUuids);
                              pipe(
                                map((uuid) => ({ uuid, operation: 'page_update', is_included: false })),
                                filesApi('post')
                              )(pageUuids);
                            }}
                          >
                            Exclude all
                            <div className='projectdetails-infopopup-status-icon' style={{ backgroundColor: '#006AFF' }}></div>
                          </div>
                        ),
                        always(
                          <div
                            className='projectdetails-infopopup-selectall'
                            onClick={() => {
                              pipe(map(modifyPageInclusion(true)), (fnList) => setState(pipe(...fnList, identity)))(pageUuids);
                              pipe(
                                map((uuid) => ({ uuid, operation: 'page_update', is_included: true })),
                                filesApi('post')
                              )(pageUuids);
                            }}
                          >
                            Include all
                            <div className='projectdetails-infopopup-status-icon'>&nbsp;</div>
                          </div>
                        )
                      )(areAllPagesIncluded)}
                    </div>

                    <div
                      className='projectdetails-pages-container'
                      style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${viewState(zoomLevelLens)}px, 1fr))`,
                      }}
                    >
                      {map((pageUuid) => {
                        const isIncluded = pipe(getNodeIsIncludedLens, viewState)(pageUuid);
                        return (
                          <Card
                            key={pageUuid}
                            className={`transition-all cursor-pointer hover:shadow-lg`}
                            border={isIncluded ? 'primary' : ''}
                            onClick={(e) => {
                              if (pipe(viewState, isNotNil)(firstPageUuidAnchorLens)) handlePageRangeInclusion(pageUuid);
                              else if (e.shiftKey) pipe(set(firstPageUuidAnchorLens), setState)(pageUuid);
                              else handlePageInclusion(!isIncluded)(pageUuid);
                            }}
                            onContextMenu={(e) => {
                              showPageContextMenu({ event: e });
                              pipe(set(pageContextMenuUuidLens), setState)(pageUuid);
                            }}
                          >
                            <div
                              id={'projectdetails-page-view' + pageUuid}
                              className='projectdetails-page-view'
                              onClick={(e) => {
                                e.stopPropagation();
                                pipe(set(pageViewerModalUuidLens), setState)(pageUuid);
                              }}
                            >
                              <IconEye />
                            </div>

                            {pipe(
                              getNodeIsAiPreppedLens,
                              viewState,
                              ifElse(
                                identity,
                                always(
                                  <div id={'projectdetails-page-aiprepped' + pageUuid} className='projectdetails-page-aiprepped'>
                                    <IconRobot size={20} stroke={1} />
                                  </div>
                                ),
                                F
                              )
                            )(pageUuid)}

                            <div id={'projectdetails-page-measurements' + pageUuid} className='projectdetails-page-measurements'>
                              {pipe(getNodeMeasurementCountLens, viewState)(pageUuid)}
                            </div>

                            <div
                              id={'projectdetails-page-status' + pageUuid}
                              className='projectdetails-page-status-icon'
                              style={{
                                backgroundColor: isIncluded && '#006AFF',
                              }}
                            ></div>

                            <Card.Img variant='top' src={pipe(getNodeThumbnailURLLens, viewState)(pageUuid)} alt='Image Missing' />
                            <div className='files-page-name'>{pipe(getNodeTitleLens, viewState)(pageUuid)}</div>
                          </Card>
                        );
                      })(pageUuids)}
                    </div>
                  </>
                );
              }
            )
          )(currentFileUuidLens)}
        </Stack>
        {pipe(viewState, (pageUuid) => (
          <Modal show={isNotNil(pageUuid)} onHide={() => setState(closeAllModals)} fullscreen backdrop='static'>
            <Modal.Header>
              <Modal.Title className='overflow-hidden text-ellipsis'>{pipe(getNodeTitleLens, viewState)(pageUuid)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='overflow-hidden'>
              <ReactPanZoom alt={'Loading Image...'} image={pipe(getNodeFileURLLens, viewState)(pageUuid)} />
            </Modal.Body>
            <Modal.Footer>
              <Stack direction='horizontal' gap={1} className='w-full'>
                <ButtonGroup>
                  <Button variant='outline-primary' onClick={() => pipe(hopPage(-1), setState)(pageUuid)}>
                    <IconArrowLeft />
                  </Button>
                  <Button variant='outline-primary' onClick={() => pipe(hopPage(1), setState)(pageUuid)}>
                    <IconArrowRight />
                  </Button>
                </ButtonGroup>
                {pipe(
                  getNodeIsIncludedLens,
                  viewState,
                  ifElse(
                    identity,
                    always(
                      <Button variant='outline-primary' className='flex flex-row gap-1' onClick={() => handlePageInclusion(false)(pageUuid)}>
                        Included
                        <IconCircleCheckFilled />
                      </Button>
                    ),
                    always(
                      <Button variant='outline-primary' className='flex flex-row gap-1' onClick={() => handlePageInclusion(true)(pageUuid)}>
                        Excluded
                        <IconCircle />
                      </Button>
                    )
                  )
                )(pageUuid)}
                <Button variant='outline-secondary' onClick={() => setState(closeAllModals)} className='ms-auto'>
                  Close
                </Button>
              </Stack>
            </Modal.Footer>
          </Modal>
        ))(pageViewerModalUuidLens)}
        {pipe(viewState, (pageUuid) => {
          const newName = viewState(pageRenameModalTextLens);
          return (
            <Modal show={isNotNil(pageUuid)} onHide={() => setState(closeAllModals)}>
              <Modal.Header>
                <Modal.Title className='overflow-hidden text-ellipsis'>Renaming page "{pipe(getNodeTitleLens, viewState)(pageUuid)}"</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label>Page Name</Form.Label>
                <Form.Control onChange={pipe(view(eventValueLens), set(pageRenameModalTextLens), setState)} value={newName} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    const nodeTitleLens = getNodeTitleLens(pageUuid);
                    filesApi('post')([{ operation: 'page_update', uuid: pageUuid, title: newName }]);
                    setState(pipe(set(nodeTitleLens)(newName), closeAllModals));
                  }}
                >
                  Save
                </Button>
                <Button variant='outline-secondary' onClick={() => setState(closeAllModals)} className='ms-auto'>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          );
        })(pageRenameModalUuidLens)}
        {pipe(viewState, (pageUuid) => {
          const pageName = pipe(getNodeTitleLens, viewState)(pageUuid);
          return (
            <Modal show={isNotNil(pageUuid)} onHide={() => setState(closeAllModals)}>
              <Modal.Header>
                <Modal.Title className='overflow-hidden text-ellipsis'>Deleting page "{pageName}"</Modal.Title>
              </Modal.Header>
              <Modal.Body className='overflow-hidden text-ellipsis'>Are you sure you want to delete page "{pageName}" and all of its measurements?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant='outline-danger'
                  onClick={() => {
                    setState(pipe(unmountPage(pageUuid), closeAllModals));
                    filesApi('post')([{ operation: 'page_delete', uuid: pageUuid }]);
                  }}
                >
                  Delete
                </Button>
                <Button variant='outline-secondary' onClick={() => setState(closeAllModals)} className='ms-auto'>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          );
        })(pageDeletionModalUuidLens)}
        {pipe(viewState, (fileUuid) => {
          const newName = viewState(fileRenameModalTextLens);
          return (
            <Modal show={isNotNil(fileUuid)} onHide={() => setState(closeAllModals)}>
              <Modal.Header>
                <Modal.Title className='overflow-hidden text-ellipsis'>Renaming file "{pipe(getNodeNameLens, viewState)(fileUuid)}"</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Label htmlFor='inputPassword5'>Page Name</Form.Label>
                <Form.Control onChange={pipe(view(eventValueLens), set(fileRenameModalTextLens), setState)} value={newName} />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant='outline-primary'
                  onClick={() => {
                    const nodeNameLens = getNodeNameLens(fileUuid);
                    filesApi('post')([{ operation: 'file_update', uuid: fileUuid, name: newName }]);
                    setState(pipe(set(nodeNameLens)(newName), closeAllModals));
                  }}
                >
                  Save
                </Button>
                <Button variant='outline-secondary' onClick={() => setState(closeAllModals)} className='ms-auto'>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          );
        })(fileRenameModalUuidLens)}
        {pipe(viewState, (fileUuid) => {
          const fileName = pipe(getNodeNameLens, viewState)(fileUuid);
          return (
            <Modal show={isNotNil(fileUuid)} onHide={() => setState(closeAllModals)}>
              <Modal.Header>
                <Modal.Title className='overflow-hidden text-ellipsis'>Deleting file "{fileName}"</Modal.Title>
              </Modal.Header>
              <Modal.Body className='overflow-hidden text-ellipsis'>Are you sure you want to delete file "{fileName}" and all of its pages and measurements?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant='outline-danger'
                  onClick={() => {
                    setState(pipe(unmountFile(fileUuid), closeAllModals));
                    filesApi('post')([{ operation: 'file_delete', uuid: fileUuid }]);
                  }}
                >
                  Delete
                </Button>
                <Button variant='outline-secondary' onClick={() => setState(closeAllModals)} className='ms-auto'>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          );
        })(fileDeletionModalUuidLens)}
        <Modal show={viewState(searchModalIsOpenLens)} onHide={() => setState(closeAllModals)} fullscreen className='h-screen'>
          <Modal.Header>
            <Modal.Title>Search</Modal.Title>
            <Button variant='outline-secondary' onClick={() => setState(closeAllModals)}>
              Close
            </Button>
          </Modal.Header>
          <Modal.Body className='flex flex-col overflow-hidden'>
            <Stack gap={1} className='h-full grow'>
              <InputGroup>
                <Form.Control
                  placeholder='Search'
                  onBlur={(e) => {
                    const searchText = view(eventValueLens)(e);
                    if (isEmpty(searchText)) {
                      setState(pipe(set(searchModalTextLens)(searchText), set(searchModalPreviewPageUuidLens)(null), set(searchModalIsLoadingLens)(false)));
                    } else {
                      setState(pipe(set(searchModalTextLens)(searchText), set(searchModalPreviewPageUuidLens)(null), set(searchModalIsLoadingLens)(true)));
                      pipe(filesApi('post'), andThen(view(responseDataLens)), andThen(last), andThen(setSearchResults), andThen(setState))([{ operation: 'files_search', search_term: searchText }]);
                    }
                  }}
                />
                <InputGroup.Text className='cursor-pointer hover:bg-gray-revell'>
                  <IconCornerDownLeft />
                </InputGroup.Text>
              </InputGroup>
              {(() => {
                const isSearchTextEmpty = pipe(viewState, isEmpty)(searchModalTextLens);
                const isLoading = viewState(searchModalIsLoadingLens);
                const areMatchedFileUuidsEmpty = pipe(viewState, isEmpty)(searchModalMatchedFileUuidsLens);
                if (isSearchTextEmpty) {
                  return (
                    <Stack className='items-center justify-center grow'>
                      <div className='p-20 text-lg'>Search files and pages</div>
                    </Stack>
                  );
                } else if (isLoading) {
                  return (
                    <Stack className='items-center justify-center grow'>
                      <Blocks height='30' width='30' color='#006AFE' ariaLabel='blocks-loading' radius='5' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                    </Stack>
                  );
                } else if (areMatchedFileUuidsEmpty) {
                  return (
                    <Stack className='items-center justify-center grow'>
                      <div className='text-lg'>No files or pages matched your search</div>
                    </Stack>
                  );
                } else {
                  return (
                    <Stack gap={1} direction='horizontal' className='overflow-hidden'>
                      <Stack gap={1} className='overflow-scroll w-96 grow-0 shrink-0'>
                        {pipe(
                          viewState,
                          map((matchedFileUuid) => (
                            <Card key={matchedFileUuid}>
                              <Card.Header className='flex flex-row items-center justify-between overflow-hidden'>
                                <div className='overflow-hidden shrink-1 grow-0 text-ellipsis text-nowrap'>{pipe(getNodeNameLens, viewState)(matchedFileUuid)}</div>
                                <Button
                                  variant='outline-primary'
                                  size='sm'
                                  className='shrink-0 grow-0'
                                  onClick={() => {
                                    setState(pipe(closeAllModals, set(currentFileUuidLens)(matchedFileUuid)));
                                  }}
                                >
                                  Reveal File
                                </Button>
                              </Card.Header>
                              {pipe(
                                getNodeMatchedPageUuidsLens,
                                viewState,
                                ifElse(isEmpty, always(<></>), (matchedPageUuids) => (
                                  <ListGroup as='ol' className='list-group-flush'>
                                    {map((matchedPageUuid) => {
                                      const matchedTextboxUuids = pipe(getNodeMatchedTextBoxUuidsLens, viewState)(matchedPageUuid);
                                      return (
                                        <ListGroup.Item
                                          as='li'
                                          className='cursor-pointer d-flex justify-content-between align-items-start'
                                          key={matchedPageUuid}
                                          action
                                          onClick={() => pipe(set(searchModalPreviewPageUuidLens), setState)(matchedPageUuid)}
                                          active={pipe(viewState, equals(matchedPageUuid))(searchModalPreviewPageUuidLens)}
                                        >
                                          <div className='overflow-hidden ms-2 me-auto'>
                                            <div className='fw-bold'>{pipe(getNodeTitleLens, viewState)(matchedPageUuid)}</div>
                                            <ListGroup className='overflow-hidden'>
                                              {map((matchedTextBoxUuid) => (
                                                <ListGroup.Item key={matchedTextBoxUuid} className='overflow-hidden bg-gray-revell max-h-48 text-ellipsis'>
                                                  {pipe(getNodeTextLens, viewState, getStringFirstMatchArray(viewState(searchModalTextLens)), ([initial, matched, remainder]) => (
                                                    <div className='flex flex-row w-full gap-1 overflow-hidden flex-nowrap'>
                                                      <div className='overflow-hidden text-nowrap shrink text-ellipsis' dir='rtl'>
                                                        {initial}
                                                      </div>
                                                      <div className='bg-yellow-300 shink-0 grow-0'>{matched}</div>
                                                      <div className='overflow-hidden text-nowrap shrink text-ellipsis'>{remainder}</div>
                                                    </div>
                                                  ))(matchedTextBoxUuid)}
                                                </ListGroup.Item>
                                              ))(matchedTextboxUuids)}
                                            </ListGroup>
                                          </div>
                                          {pipe(
                                            length,
                                            ifElse(equals(0), F, (numMatchedTextboxes) => <Badge bg='info'>{numMatchedTextboxes}</Badge>)
                                          )(matchedTextboxUuids)}
                                        </ListGroup.Item>
                                      );
                                    })(matchedPageUuids)}
                                  </ListGroup>
                                ))
                              )(matchedFileUuid)}
                            </Card>
                          ))
                        )(searchModalMatchedFileUuidsLens)}
                      </Stack>
                      <div className='relative overflow-hidden text-center grow'>
                        {pipe(
                          viewState,
                          ifElse(isNil, always('Click on a page on the left to preview'), (searchModalPreviewPageUuid) => (
                            <ReactPanZoom alt={'Loading Image...'} image={pipe(getNodeFileURLLens, viewState)(searchModalPreviewPageUuid)} />
                          ))
                        )(searchModalPreviewPageUuidLens)}
                      </div>
                    </Stack>
                  );
                }
              })()}
            </Stack>
          </Modal.Body>
        </Modal>
        <Offcanvas show={viewState(isToastSidebarOpenLens)} onHide={() => pipe(set(isToastSidebarOpenLens), setState)(false)} scroll backdrop placement='end'>
          <Offcanvas.Header>
            <Offcanvas.Title>Uploads</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ToastContainer className='position-static'>
              {pipe(
                viewState,
                ifElse(
                  isEmpty,
                  always(<div>No uploads in progress</div>),
                  map((uploadKey) => (
                    <Toast key={uploadKey}>
                      <Toast.Header className='overflow-hidden text-ellipsis'>{pipe(getUploadObjectMessageLens, viewState)(uploadKey)}</Toast.Header>
                      <Toast.Body>
                        <ProgressBar now={pipe(getUploadObjectProgressLens, viewState)(uploadKey)} />
                      </Toast.Body>
                    </Toast>
                  ))
                )
              )(uploadKeysLens)}
            </ToastContainer>
          </Offcanvas.Body>
        </Offcanvas>
        {pipe(viewState, (pageUuid) => (
          <Menu id={PAGE_CONTEXT_MENU_ID} theme='bobyard-light' onVisibilityChange={ifElse(equals(false), () => setState(set(pageContextMenuUuidLens)(null)), identity)}>
            <ContextMenuItem onClick={() => pipe(openPageRenameModal, setState)(pageUuid)}>
              <IconCursorText size={20} stroke={1} />
              Rename
            </ContextMenuItem>
            <ContextMenuItem
              onClick={() => {
                pipe(
                  filesApi('post'),
                  andThen(view(responseDataLens)),
                  andThen(last),
                  andThen(refreshFilesData),
                  andThen(setState)
                )([{ operation: 'page_duplicate', uuid: pageUuid }, { operation: 'files_get' }]);
              }}
            >
              <IconCopy size={20} stroke={1} />
              Duplicate
            </ContextMenuItem>
            <ContextMenuItem onClick={() => pipe(set(pageDeletionModalUuidLens), setState)(pageUuid)} className='hover:!text-red-normal hover:bg-pink-light'>
              <IconTrashX size={20} stroke={1} />
              Delete
            </ContextMenuItem>
          </Menu>
        ))(pageContextMenuUuidLens)}
        {pipe(viewState, (fileUuid) => (
          <Menu id={FILE_CONTEXT_MENU_ID} theme='bobyard-light' onVisibilityChange={ifElse(equals(false), () => setState(set(fileContextMenuUuidLens)(null)), identity)}>
            {(() => {
              if (!fileUuid) return;
              const pageUuids = pipe(getNodePageUuidsLens, viewState)(fileUuid);
              const areAllPagesIncluded = pipe(map(getNodeIsIncludedLens), map(viewState), all(identity))(pageUuids);
              return ifElse(
                identity,
                always(
                  <ContextMenuItem
                    onClick={() => {
                      pipe(map(modifyPageInclusion(false)), (fnList) => setState(pipe(...fnList, identity)))(pageUuids);
                      pipe(
                        map((uuid) => ({ uuid, operation: 'page_update', is_included: false })),
                        filesApi('post')
                      )(pageUuids);
                    }}
                  >
                    <IconCircleCheckFilled size={20} stroke={1} />
                    Exclude all
                  </ContextMenuItem>
                ),
                always(
                  <ContextMenuItem
                    onClick={() => {
                      pipe(map(modifyPageInclusion(true)), (fnList) => setState(pipe(...fnList, identity)))(pageUuids);
                      pipe(
                        map((uuid) => ({ uuid, operation: 'page_update', is_included: true })),
                        filesApi('post')
                      )(pageUuids);
                    }}
                  >
                    <IconCircle size={20} stroke={1} />
                    Include all
                  </ContextMenuItem>
                )
              )(areAllPagesIncluded);
            })()}
            <ContextMenuItem onClick={() => pipe(openFileRenameModal, setState)(fileUuid)}>
              <IconCursorText size={20} stroke={1} />
              Rename
            </ContextMenuItem>
            <ContextMenuItem>
              <a href={pipe(getNodeFileURLLens, viewState)(fileUuid)} target='_blank' className='contents'>
                <IconDownload size={20} stroke={1} />
                Download
              </a>
            </ContextMenuItem>
            <ContextMenuItem className='hover:!text-red-normal hover:bg-pink-light' onClick={() => pipe(set(fileDeletionModalUuidLens), setState)(fileUuid)}>
              <IconTrashX size={20} stroke={1} />
              Delete
            </ContextMenuItem>
          </Menu>
        ))(fileContextMenuUuidLens)}
        <input type='file' className='hidden' ref={fileUploadInputRef} multiple={true} accept='.pdf,.png,.jpg,.jpeg' onChange={(e) => handleFilesUpload(e.target.files)} />
      </Stack>
    </Stack>
  );
};
