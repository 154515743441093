import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Text } from "react-konva";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function RectangleConstructor({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        selectedGroups,
        createMeasurement,
        createMeasurements,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        handleChangeFilter,
        stopWatch,
        snaps, setSnaps,
        stageRef,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    const [showSnap, setShowSnap] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Shift") {
                e.preventDefault();
                e.stopPropagation();
                setShowSnap(true);
            }
        }

        const handleKeyUp = (e) => {
            if (e.key === "Shift") {
                e.preventDefault();
                e.stopPropagation();
                setShowSnap(false);
            }
        }

        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);


        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        }
    }, []);

    const addRectangle = (rect) => {
        handleChangeFilter('include_area', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'rectangle',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                'width': rect.width,
                'height': rect.height,
                ['rectangle']: {
                    x: rect.x,
                    y: rect.y,
                },
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/rectangle/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup || null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'x': rect.x,
                'y': rect.y,
                'width': rect.width,
                'height': rect.height,
                'time': stopWatch.totalSeconds,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                //createMeasurement(response.data, false, tempName);
                createMeasurements(response.data, tempName);

                setTimeout(() => {
                    const element = document.getElementById("measurement-" + response.data.measurement.id);
                    element?.scrollIntoView();
                }, 100);
            })
            .catch((error) => {
                console.log(error);
            });

        stopWatch.reset();
    }

    let closestSnap = [];

    if (pages?.[pageID]?.zoom && cursorPosition.x && cursorPosition.y && showSnap && snaps.length > 0) {
        closestSnap = snaps.reduce((prev, curr) => {
            const prevDistance = Math.sqrt((prev[0] - cursorPosition.x) ** 2 + (prev[1] - cursorPosition.y) ** 2);
            const currDistance = Math.sqrt((curr[0] - cursorPosition.x) ** 2 + (curr[1] - cursorPosition.y) ** 2);
            return prevDistance < currDistance ? prev : curr;
        })
    }

    return (
        <>
            {(drawing && start && end) &&
                <Rect
                    x={start.x}
                    y={start.y}
                    width={showSnap ? closestSnap[0] - start.x : end.x - start.x}
                    height={showSnap ? closestSnap[1] - start.y : end.y - start.y}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                    opacity={0.5}
                />
            }

            {showSnap && closestSnap.length > 0 &&
                <>
                    <Circle
                        x={closestSnap[0]}
                        y={closestSnap[1]}
                        radius={2 / pages[pageID].zoom}
                        fill="#006aff"
                        stroke="black"
                        strokeWidth={1 / pages[pageID].zoom}
                    />

                    <Line
                        strokeWidth={1 / pages[pageID].zoom}
                        dash={[5, 5]}
                        points={[closestSnap[0], closestSnap[1], cursorPosition.x, cursorPosition.y]}
                        stroke="#006aff"
                    />
                </>
            }

            {start?.x && start?.y && end?.x && end?.y && pages[pageID].scale &&
                <>
                    <Text
                        x={(start.x + end.x) / 2}
                        y={start.y}
                        text={`${(Math.abs(end.x - start.x) * pages[pageID].scale).toFixed(2)} ft`}
                        fontSize={12 / pages[pageID].zoom}
                        fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    />

                    <Text
                        x={start.x}
                        y={(start.y + end.y) / 2}
                        text={`${(Math.abs(end.y - start.y) * pages[pageID].scale).toFixed(2)} ft`}
                        fontSize={12 / pages[pageID].zoom}
                        fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    />
                </>
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;

                    if (drawing) {
                        if (showSnap) {
                            addRectangle({
                                x: start.x,
                                y: start.y,
                                width: closestSnap[0] - start.x,
                                height: closestSnap[1] - start.y
                            })
                        } else {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            addRectangle({ x: start.x, y: start.y, width: x - start.x, height: y - start.y });
                        }

                        setStart(null);
                        setEnd(null);
                        setDrawing(false);
                    } else {
                        setDrawing(true);

                        if (showSnap) {
                            setStart({ x: closestSnap[0], y: closestSnap[1] });
                        } else {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setStart({ x: x, y: y });
                        }
                    }
                }}
                onMouseMove={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    setCursorPosition({ x, y });

                    if (drawing) {
                        setEnd({ x: x, y: y });
                    }
                }}
            />
        </>
    );
}