import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { forwardRef, memo, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { API_ROUTE } from '..';

import './styles/Database.css';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Resizable } from 'react-resizable';
import { FolderTreeItemWrapper } from './sortableTree/ui/folder/FolderTreeItemWrapper';
import { SortableTree } from './sortableTree/SortableTree';
import NumericField from '../estimate/components/NumericField';
import TextField from '../components/TextField';
import { InView } from 'react-intersection-observer';
import {
  CalculateValue,
  ColumnLabels,
  ColumnDescriptions,
  GroupFields,
  InputCalculated,
  InputEditable,
  InputTypes,
  AssemblyFields,
  AssemblyEntryFields,
  AssemblyCalculatedFields,
} from './components/Utils';
import { DatabaseContext } from './components/Context';
import {
  IconArrowUp,
  IconArrowsMaximize,
  IconArrowsMinimize,
  IconBrandDatabricks,
  IconCaretDown,
  IconCaretRight,
  IconCheckbox,
  IconChevronDown,
  IconChevronRight,
  IconCirclePlus,
  IconCube,
  IconEdit,
  IconFolder,
  IconFolderOff,
  IconFolderOpen,
  IconFolderPlus,
  IconSearch,
  IconSelect,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import { EmptyCircleIcon, IconCircleRevert, IconCircleRevertHover } from './components/Icons';
import ColumnsModal from './components/ColumnsModal';
import DeleteModal from './components/DeleteModal';
import Popup from 'reactjs-popup';
import GroupEdit from './components/GroupEdit';
import PopulateDatabase from './components/PopulateDatabase';
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';
import { Blocks } from 'react-loader-spinner';
import { Item, Menu, useContextMenu } from 'react-contexify';
import { IconCubePlus } from '@tabler/icons-react';
import { numToStr2Places } from '../estimate/EstimateUtilities';
import { axiosCurry, createLookupByKeyName, responseDataLens } from '../utilities/utilities';
import { andThen, compose, concat, defaultTo, isNotNil, keys, last, lensProp, map, nth, pipe, prop, reduce, set, split, uniq, view } from 'ramda';
import { EstimateTagField } from '../estimate/components/StyledTagInput/StyledTagInput';
import { Modal } from 'react-bootstrap';
import { WithContext as ReactTags } from 'react-tag-input';

const nodesLens = lensProp('nodes');
const tagUuidsLens = lensProp('tagUuids');
const tagEditorNodeUuidLens = lensProp('tagEditorNodeUuid');

const initialState = { nodes: {}, tagUuids: [], tagEditorNodeUuid: null };

const getNodeDatabaseItemTagUuidsLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('database_item_tag_uuids'));
const getNodeTextLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('text'));
const getNodeNameLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('name'));
const getNodeTypeLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('type'));
const getNodeAssemblyLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('assembly'));

export default function Database() {
  const currentURL = window.location.href;

  const auth = useSelector(selectAuth);

  const [loading, setLoading] = useState(true);
  const [loadingSort, setLoadingSort] = useState('');
  const [expanding, setExpanding] = useState(false);
  const [expandingAll, setExpandingAll] = useState(false);
  const [searching, setSearching] = useState(false);
  const [resizing, setResizing] = useState(null);
  const [addingEntry, setAddingEntry] = useState(false);
  const [addingGroup, setAddingGroup] = useState(false);
  const [addingAssembly, setAddingAssembly] = useState(false);

  const [tree, setTree] = useState(null);
  const [entries, setEntries] = useState(null);
  const [assemblyEntries, setAssemblyEntries] = useState(null);
  const [groups, setGroups] = useState(null);
  const [columns, setColumns] = useState(null);
  const [column_settings, setColumnSettings] = useState(null);
  const [settings, setSettings] = useState(null);
  const [contractor, setContractor] = useState(null);
  const [projects, setProjects] = useState(null);

  //const [hoverID, setHoverID] = useState(null);
  const [activeID, setActiveID] = useState(null);
  const [draggingID, setDraggingID] = useState(null);

  const [selectedIDs, setSelectedIDs] = useState([]);
  const [multiselectAnchor1, setMultiselectAnchor1] = useState(null);
  const [multiselectAnchor2, setMultiselectAnchor2] = useState(null);

  const [search, setSearch] = useState('');

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);

  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);

  const getUuidFromEntryID = (entryID) => {
    //WARNING: database groups dont even have uuids, so the group case is just for future implementation here
    const getLookupDict = (entryID) => {
      if (entryID.includes('group')) {
        return groups;
      } else if (entryID.includes('assembly')) {
        return assemblyEntries;
      } else {
        return entries;
      }
    };
    const getEntryIDNumber = pipe(split('-'), last);
    return pipe(getLookupDict, prop(getEntryIDNumber(entryID)), prop('uuid'))(entryID);
  };

  const getIsAssemblyEntry = pipe(getNodeAssemblyLens, viewState, isNotNil);

  const { show } = useContextMenu({
    id: 'database-context-menu',
  });

  const databaseItemTagAPI = useMemo(() =>
    axiosCurry(`${API_ROUTE}/api/database-item-tags/`)({
      Authorization: `Token ${auth.token}`,
      'Content-Type': 'application/json',
    })('post')
  );

  const refreshTagData = () => {
    pipe(
      databaseItemTagAPI,
      andThen(view(responseDataLens)),
      andThen(createLookupByKeyName('uuid')),
      andThen(set(nodesLens)),
      andThen(setState)
    )([
      { operation: 'database_entries_generate_uuids' },
      { operation: 'database_assembly_entries_generate_uuids' },
      { operation: 'database_entries_get' },
      { operation: 'database_assembly_entries_get' },
    ]);
  };

  useEffect(() => {
    if (auth.token) refreshTagData();
  }, [auth.token]);

  useEffect(() => {
    if (auth.token) {
      axios({
        method: 'get',
        url: `${API_ROUTE}/api/database/`,
        params: {
          userID: auth.user.id,
        },
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response);

          setTree(response.data.tree);
          setEntries(response.data.entries);
          setAssemblyEntries(response.data.assembly_entries);
          setGroups(response.data.groups);
          setColumns(response.data.columns);
          setColumnSettings(response.data.column_settings);
          setSettings(response.data.settings);
          setContractor(response.data.contractor);
          setProjects(response.data.projects);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [auth]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Escape' || e.key === 'Return' || e.key === 'Tab') {
        if (document.activeElement.tagName === 'INPUT') {
          document.activeElement.blur();
        } else {
          setActiveID(null);
          setSelectedIDs([]);
          setMultiselectAnchor1(null);
          setMultiselectAnchor2(null);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleAddGroup = () => {
    setAddingGroup(true);

    axios({
      method: 'post',
      url: `${API_ROUTE}/api/database-group/`,
      data: {
        userID: auth.user.id,
        groupID: activeID?.includes('group') ? activeID.split('-')[1] : null,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        refreshTagData();

        setGroups((prev) => ({
          ...prev,
          [response.data.group.id]: response.data.group,
        }));
        setTree([...response.data.tree]);

        setSelectedIDs((prev) => [...prev, `group-${response.data.group.id}`]);

        setTimeout(() => {
          setAddingGroup(false);

          let element = document.getElementById(`database-tree-entry-group-${response.data.group.id}`);
          if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().bottom > window.innerHeight)) {
            element.scrollIntoView();
          }
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddItem = () => {
    setAddingEntry(true);

    axios({
      method: 'post',
      url: `${API_ROUTE}/api/database-entry/`,
      data: {
        userID: auth.user.id,
        groupID: activeID?.includes('group') ? activeID.split('-')[1] : null,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        refreshTagData();

        setEntries((prev) => ({
          ...prev,
          [response.data.entry.id]: response.data.entry,
        }));
        setTree([...response.data.tree]);

        setSelectedIDs((prev) => [...prev, `entry-${response.data.entry.id}`]);

        setTimeout(() => {
          setAddingEntry(false);

          let element = document.getElementById(`database-tree-entry-entry-${response.data.entry.id}`);
          if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().bottom > window.innerHeight)) {
            element.scrollIntoView();
          }
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddAssembly = () => {
    setAddingAssembly(true);

    axios({
      method: 'post',
      url: `${API_ROUTE}/api/database-entry/`,
      data: {
        userID: auth.user.id,
        groupID: activeID?.includes('group') ? activeID.split('-')[1] : null,
        type: 'assembly',
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        refreshTagData();
        setEntries((prev) => ({
          ...prev,
          [response.data.entry.id]: response.data.entry,
        }));
        setTree([...response.data.tree]);

        setSelectedIDs((prev) => [...prev, `entry-${response.data.entry.id}`]);

        setTimeout(() => {
          setAddingAssembly(false);

          let element = document.getElementById(`database-tree-entry-entry-${response.data.entry.id}`);
          if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().bottom > window.innerHeight)) {
            element.scrollIntoView();
          }
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = () => {
    axios({
      method: 'delete',
      url: `${API_ROUTE}/api/database/`,
      data: {
        entries: selectedIDs.filter((a) => a.includes('entry-')).map((a) => a.split('-')[1]),
        groups: selectedIDs.filter((a) => a.includes('group')).map((a) => a.split('-')[1]),
        assembly_entries: selectedIDs.filter((a) => a.includes('assembly')).map((a) => a.split('-')[1]),
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        refreshTagData();
        setTree([...response.data]);

        setEntries((prev) => {
          let newEntries = { ...prev };

          selectedIDs
            .filter((a) => a.includes('entry-'))
            .forEach((entryID) => {
              delete newEntries[entryID.split('-')[1]];
            });

          return newEntries;
        });

        setGroups((prev) => {
          let newGroups = { ...prev };

          selectedIDs
            .filter((a) => a.includes('group'))
            .forEach((groupID) => {
              delete newGroups[groupID.split('-')[1]];
            });

          return newGroups;
        });

        setAssemblyEntries((prev) => {
          let newAssemblyEntries = { ...prev };

          selectedIDs
            .filter((a) => a.includes('assembly'))
            .forEach((entryID) => {
              delete newAssemblyEntries[entryID.split('-')[1]];
            });

          return newAssemblyEntries;
        });

        setSelectedIDs([]);
        setShowDeleteModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateColumnSettings = (columnID, setting) => {
    setColumnSettings((prev) => ({
      ...prev,
      [columnID]: setting,
    }));

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/databasecolumns/${column_settings.id}/`,
      data: {
        company: auth.contractor.company,
        [columnID]: setting,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        setResizing(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateField = (entryID, columnID, value) => {
    if (entryID.includes('group')) {
    } else if (entryID.includes('entry-')) {
      setEntries((prev) => ({
        ...prev,
        [entryID.split('-')[1]]: {
          ...prev[entryID.split('-')[1]],
          [columnID]: value || null,
        },
      }));

      axios({
        method: 'put',
        url: `${API_ROUTE}/api/databaseentry/${entryID.split('-')[1]}/`,
        data: {
          company: auth.contractor.company,
          [columnID]: value || null,
        },
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response);
          refreshTagData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (entryID.includes('assembly')) {
      setAssemblyEntries((prev) => ({
        ...prev,
        [entryID.split('-')[1]]: {
          ...prev[entryID.split('-')[1]],
          [columnID]: value || null,
        },
      }));

      axios({
        method: 'put',
        url: `${API_ROUTE}/api/databaseassemblyentry/${entryID.split('-')[1]}/`,
        data: {
          assembly: assemblyEntries[entryID.split('-')[1]].assembly,
          [columnID]: value || null,
        },
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          refreshTagData();
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleUpdateGroupField = (groupID, columnID, value) => {
    setGroups((prev) => ({
      ...prev,
      [groupID.split('-')[1]]: {
        ...prev[groupID.split('-')[1]],
        [columnID]: value || null,
      },
    }));

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/databasegroup/${groupID.split('-')[1]}/`,
      data: {
        company: auth.contractor.company,
        [columnID]: value || null,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateTree = (newTree, type, item, draggedItem, draggedFromParent, droppedToParent) => {
    if (
      type === 'expanded' ||
      type === 'collapsed' ||
      !settings.sort ||
      (!draggedFromParent && droppedToParent) ||
      (draggedFromParent && !droppedToParent) ||
      (draggedFromParent && droppedToParent && draggedFromParent.id !== droppedToParent.id)
    ) {
      setTree([...newTree]);

      if ((type === 'expanded' || type === 'collapsed') && item?.id?.includes('group')) {
        setExpanding(true);

        axios({
          method: 'put',
          url: `${API_ROUTE}/api/database-group-settings/`,
          data: {
            groupID: item.id.split('-')[1],
            collapsed: type === 'collapsed',
          },
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            console.log(response);

            setExpanding(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if ((type === 'expanded' || type === 'collapsed') && item?.id?.includes('entry') && entries[item.id.split('-')[1]].type === 'assembly') {
        setExpanding(true);

        axios({
          method: 'put',
          url: `${API_ROUTE}/api/database-entry-settings/`,
          data: {
            entryID: item.id.split('-')[1],
            collapsed: type === 'collapsed',
          },
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            console.log(response);

            setExpanding(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      if (type === 'dropped') {
        axios({
          method: 'put',
          url: `${API_ROUTE}/api/database-tree/`,
          data: {
            companyID: auth.contractor.company,
            tree: newTree,
          },
          headers: {
            Authorization: `Token ${auth.token}`,
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const handleUpdateSort = (newSettings) => {
    setSettings(newSettings);

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/database/`,
      data: {
        companyID: auth.contractor.company,
        sort: newSettings.sort,
        search: search,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        setTree([...response.data]);
        setLoadingSort('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleToggleAll = (collapsed) => {
    setExpandingAll(true);

    console.log('collapsed');

    axios({
      method: 'post',
      url: `${API_ROUTE}/api/database-group-settings/`,
      data: {
        userID: auth.user.id,
        collapsed: collapsed,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        setTree([...response.data]);
        setExpandingAll(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnToggleAll = (toggle) => {
    setColumnSettings((prev) => {
      let newSettings = { ...prev };

      Object.keys(columns).forEach((columnID) => {
        newSettings[columnID] = toggle;
      });

      return newSettings;
    });
    setColumns((prev) => {
      let newSettings = { ...prev };

      Object.keys(prev).forEach((columnID) => {
        newSettings[columnID] = toggle;
      });

      return newSettings;
    });

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/databasecolumns/${column_settings.id}/`,
      data: {
        company: auth.contractor.company,
        ...Object.keys(columns).reduce(
          (acc, columnID) => ({
            ...acc,
            [columnID]: toggle,
          }),
          {}
        ),
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleColumnToggle = (columnID) => {
    setColumnSettings((prev) => ({
      ...prev,
      [columnID]: !column_settings[columnID],
    }));
    setColumns((prev) => ({
      ...prev,
      [columnID]: !column_settings[columnID],
    }));

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/databasecolumns/${column_settings.id}/`,
      data: {
        company: auth.contractor.company,
        [columnID]: !column_settings[columnID],
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateAllValues = (columnID, value) => {
    setEntries((prev) => {
      let newEntries = { ...prev };

      selectedIDs
        .filter((a) => a.includes('entry-'))
        .forEach((entryID) => {
          newEntries[entryID.split('-')[1]] = {
            ...prev[entryID.split('-')[1]],
            [columnID]: value,
          };
        });

      return newEntries;
    });

    setGroups((prev) => {
      let newGroups = { ...prev };

      selectedIDs
        .filter((a) => a.includes('group'))
        .forEach((groupID) => {
          newGroups[groupID.split('-')[1]] = {
            ...prev[groupID.split('-')[1]],
            [columnID]: value,
          };
        });

      return newGroups;
    });

    setAssemblyEntries((prev) => {
      let newAssemblyEntries = { ...prev };

      selectedIDs
        .filter((a) => a.includes('assembly'))
        .forEach((entryID) => {
          newAssemblyEntries[entryID.split('-')[1]] = {
            ...prev[entryID.split('-')[1]],
            [columnID]: value,
          };
        });

      return newAssemblyEntries;
    });

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/database-entry/`,
      data: {
        field: columnID,
        value: value,
        entries: selectedIDs.filter((a) => a.includes('entry-')).map((a) => a.split('-')[1]),
        groups: selectedIDs.filter((a) => a.includes('group')).map((a) => a.split('-')[1]),
        assembly_entries: selectedIDs.filter((a) => a.includes('assembly')).map((a) => a.split('-')[1]),
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        refreshTagData();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (s) => {
    setSearching(true);

    axios({
      method: 'get',
      url: `${API_ROUTE}/api/database-search/`,
      params: {
        search: s,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        setTree([...response.data]);
        setSearching(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = (entryID, e) => {
    if (e.button === 2) {
      setActiveID(entryID);
      setSelectedIDs([entryID]);
      return;
    }

    let flattenedTree = visibleIDs;

    if (e.ctrlKey || e.metaKey) {
      setActiveID(null);
      setSelectedIDs((prev) => {
        if (prev.length && prev.find((a) => a === entryID)) {
          return prev.filter((a) => a !== entryID);
        }
        return [...prev, entryID];
      });
      setMultiselectAnchor1(flattenedTree.findIndex((m) => m === entryID));
      setMultiselectAnchor2(null);
    } else if (e.shiftKey) {
      if ((multiselectAnchor1 === null || multiselectAnchor1 === undefined) && !activeID) {
        let index = flattenedTree.findIndex((m) => m === entryID);
        let subtree = flattenedTree.slice(0, index + 1);

        setSelectedIDs(subtree.map((m) => m));
        setMultiselectAnchor1(index);
      } else if (multiselectAnchor1 !== null) {
        let subtree = flattenedTree.filter((m) => selectedIDs.includes(m));
        let secondIndex = flattenedTree.findIndex((m) => m === entryID);

        if (multiselectAnchor2) {
          subtree = subtree.filter((m) => {
            if (multiselectAnchor1 > multiselectAnchor2 && flattenedTree.findIndex((n) => n === m) <= multiselectAnchor1 && flattenedTree.findIndex((n) => n === m) >= multiselectAnchor2) {
              return false;
            } else if (flattenedTree.findIndex((n) => n === m) >= multiselectAnchor1 && flattenedTree.findIndex((n) => n === m) <= multiselectAnchor2) {
              return false;
            }
            return true;
          });
        }

        subtree = subtree.concat(flattenedTree.slice(Math.min(multiselectAnchor1, secondIndex), Math.max(multiselectAnchor1, secondIndex) + 1));

        setSelectedIDs(subtree);
        setMultiselectAnchor2(secondIndex);
      }
    } else {
      setActiveID(entryID);
      let anchor = flattenedTree.findIndex((m) => m === entryID);
      setMultiselectAnchor1(anchor);
      setMultiselectAnchor2(null);
      setSelectedIDs([entryID]);
    }
  };

  const handleAddAssemblyItem = (entryID) => {
    axios({
      method: 'post',
      url: `${API_ROUTE}/api/database-assembly-entry/`,
      data: {
        assemblyID: entryID.split('-')[1],
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        refreshTagData();
        setTree([...response.data.tree]);
        setAssemblyEntries((prev) => ({
          ...prev,
          [response.data.assembly_entry.id]: response.data.assembly_entry,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const databaseItemTagUuids = useMemo(
    () => pipe(viewState, keys, map(getNodeDatabaseItemTagUuidsLens), map(viewState), map(defaultTo([])), reduce(concat)([]), uniq)(nodesLens),
    [viewState(nodesLens)]
  );

  const visibleIDs = useMemo(() => {
    if (!tree) return [];
    //if (entries === null || groups === null) return [];

    const flattened = [];

    const dfs = (node) => {
      flattened.push(node?.id);

      if (node?.children && !node?.collapsed) {
        node.children.forEach((child, i) => {
          dfs(child);
        });
      }
    };

    tree.forEach((node, i) => {
      dfs(node);
    });

    /*flattened.sort((a, b) => {
            let indexA, indexB;

            if (settings?.sort) {
                let direction = settings?.sort[0];
                let columnID = settings?.sort.slice(1);

                indexA = a.includes('group')
                    ? GroupFields[columnID]
                        ? groups[a.split('-')[1]][columnID]
                        : 0
                    : entries[a.split('-')[1]][columnID];
                indexB = b.includes('group')
                    ? GroupFields[columnID]
                        ? groups[b.split('-')[1]][columnID]
                        : 0
                    : entries[b.split('-')[1]][columnID];

                if (direction === '-') {
                    [indexA, indexB] = [indexB, indexA];
                }
            } else {
                indexA = a.includes('group') ? groups[a.split('-')[1]].index : entries[a.split('-')[1]].index;
                indexB = b.includes('group') ? groups[b.split('-')[1]].index : entries[b.split('-')[1]].index;
            }

            return indexA - indexB;
        })*/

    return flattened;
  }, [tree]);

  const hasCollapsed = useMemo(() => {
    if (!tree) return false;
    return String(JSON.stringify(tree)).includes(`"collapsed":true`);
  }, [tree]);

  const hasExpanded = useMemo(() => {
    if (!tree) return false;
    return String(JSON.stringify(tree)).includes(`"collapsed":false`);
  }, [tree]);

  const trackVisibility = (visible) => {
    setShowContextMenu(visible);
  };

  if (loading) {
    return (
      <div className='database-container'>
        <div className='database-navbar'>
          <div className='database-navbar-items'>
            <div className='database-navbar-logo-container'>
              <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='database-navbar-logo' id='database-navbar-logo' />
            </div>

            <div className='database-navbar-title'>
              <IconBrandDatabricks size={20} /> Cost Database
            </div>
          </div>

          <div className='database-navbar-items'>
            <Skeleton width={300} />

            <div className='database-navbar-profile-picture-container'>
              <img src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'} alt='profile picture' className='database-navbar-profile-picture' />
            </div>
          </div>
        </div>

        <div className='database-columns'>
          <div className='database-column database-sidebar'>
            <div className='database-column-header'>
              <Skeleton width={300} />
            </div>

            <div className='database-sidebar-content'>
              {Array.from({ length: Math.floor(Math.random() * 15) + 5 }, (_, i) => (
                <Skeleton key={i} height={30} window={280} />
              ))}
            </div>
          </div>

          {InputTypes &&
            Object.keys(InputTypes).map((columnID) => (
              <div key={columnID} className='database-column'>
                <div className='database-column-header'>
                  <Skeleton width={300} />
                </div>

                <div className='database-column-content'>
                  {Array.from({ length: Math.floor(Math.random() * 15) + 5 }, (_, i) => (
                    <Skeleton key={i} height={30} window={280} />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  return (
    <div className='database-container'>
      <div className='database-navbar'>
        <div className='database-navbar-items'>
          <div id='database-navbar-logo' className='database-navbar-logo-container'>
            <a href='/dashboard'>
              <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='database-navbar-logo' id='database-navbar-logo' />
            </a>
          </div>

          <Tooltip anchorSelect='#database-navbar-logo' place='top-start' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
            Navigate to dashboard
          </Tooltip>

          <div className='database-navbar-title'>
            <IconBrandDatabricks size={20} /> Cost Database
          </div>

          <Popup
            trigger={(open) => (
              <div id={'database-navbar-project-select'} className='database-navbar-item'>
                <IconSelect size={20} />
              </div>
            )}
            on='click'
            position='bottom center'
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
          >
            <div className='navdropdown-items'>
              {projects
                ?.filter((a) => !a.archived)
                .sort((a, b) => b.id - a.id)
                .map((p) => (
                  <a href={'/estimate/' + p.uuid} className='navdropdown-item-link'>
                    <div className='navdropdown-item'>
                      <div>{p.title}</div>
                    </div>
                  </a>
                ))}
            </div>
          </Popup>

          <Tooltip anchorSelect='#database-navbar-project-select' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
            Navigate to a project
          </Tooltip>
        </div>

        <div className='database-navbar-search'>
          {searching ? <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' /> : <IconSearch size={20} />}

          <div className='database-navbar-search-container'>
            <input
              id='database-navbar-search'
              type='text'
              placeholder='Search...'
              className='database-navbar-search-input'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onBlur={(e) => handleSearch(search)}
            />

            <div
              id='database-navbar-search-clear'
              className='database-navbar-search-clear'
              onClick={() => {
                setSearch('');
                handleSearch('');
              }}
            >
              <IconX size={20} />
            </div>
          </div>
        </div>

        <Tooltip anchorSelect='#database-navbar-search' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
          Search for item or group names and descriptions
        </Tooltip>

        <Tooltip anchorSelect='#database-navbar-search-clear' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
          Clear search
        </Tooltip>

        <div className='database-navbar-items-group'>
          <div className='database-navbar-items'>
            <div
              id='database-navbar-scrolltop'
              className={'database-navbar-item'}
              onClick={() => {
                const element = document.getElementById('database-tree-entry-' + visibleIDs[0]);
                if (element) element.scrollIntoView();
              }}
            >
              <IconArrowUp size={20} />
            </div>

            <Tooltip anchorSelect='#database-navbar-scrolltop' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Scroll to top
            </Tooltip>

            <div
              id='database-navbar-selectallitems'
              className={'database-navbar-item ' + (!visibleIDs.length ? 'database-navbar-item-disabled' : '')}
              onClick={() => {
                if (visibleIDs.filter((a) => a.includes('entry-')).length) {
                  setSelectedIDs(visibleIDs.filter((a) => a.includes('entry-')));
                }
              }}
            >
              <IconCheckbox size={20} />
            </div>

            <Tooltip anchorSelect='#database-navbar-selectallitems' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Select all items
            </Tooltip>

            <div
              id='database-navbar-maximize'
              className={'database-navbar-item ' + (!hasCollapsed ? 'database-navbar-item-disabled' : '')}
              onClick={() => {
                if (hasCollapsed && !expandingAll) {
                  const element = document.getElementById('database-navbar-maximize');
                  if (element) {
                    element.dispatchEvent(new MouseEvent('mouseleave', { bubbles: true }));
                  }
                  handleToggleAll(false);
                }
              }}
            >
              {expandingAll ? (
                <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
              ) : (
                <IconArrowsMaximize size={20} />
              )}
            </div>

            <Tooltip anchorSelect='#database-navbar-maximize' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Expand all groups
            </Tooltip>

            <div
              id='database-navbar-minimize'
              className={'database-navbar-item ' + (!hasExpanded ? 'database-navbar-item-disabled' : '')}
              onClick={() => {
                if (hasExpanded && !expandingAll) {
                  const element = document.getElementById('database-navbar-minimize');
                  if (element) {
                    element.dispatchEvent(new MouseEvent('mouseleave', { bubbles: true }));
                  }

                  handleToggleAll(true);
                }
              }}
            >
              {expandingAll ? (
                <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
              ) : (
                <IconArrowsMinimize size={20} />
              )}
            </div>

            <Tooltip anchorSelect='#database-navbar-minimize' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Collapse all groups
            </Tooltip>
          </div>

          <div className='database-navbar-items'>
            <div
              id='database-navbar-add-group'
              className='database-navbar-item'
              onClick={() => {
                if (addingGroup) return;
                handleAddGroup();
              }}
            >
              {addingGroup ? <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' /> : <IconFolderPlus size={20} />}
            </div>

            <Tooltip anchorSelect='#database-navbar-add-group' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Add a group
            </Tooltip>

            <div
              id='database-navbar-add-item-id'
              className='database-navbar-item'
              onClick={() => {
                if (addingEntry) return;
                handleAddItem();
              }}
            >
              {addingEntry ? <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' /> : <IconCirclePlus size={20} />}
            </div>

            <Tooltip anchorSelect='#database-navbar-add-item-id' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Add an item
            </Tooltip>

            <div
              id='database-navbar-add-assembly-item'
              className='database-navbar-item'
              onClick={() => {
                if (addingAssembly) return;
                handleAddAssembly();
              }}
            >
              {addingAssembly ? (
                <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
              ) : (
                <IconCubePlus size={20} />
              )}
            </div>

            <Tooltip anchorSelect='#database-navbar-add-assembly-item' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
              Add an assembly
            </Tooltip>
          </div>

          <div className='database-navbar-items'>
            <GroupEdit selectedIDs={selectedIDs} columns={columns} handleUpdateAllValues={handleUpdateAllValues} />

            <ColumnsModal columns={columns} handleColumnToggle={handleColumnToggle} handleColumnToggleAll={handleColumnToggleAll} />

            <PopulateDatabase setTree={setTree} setEntries={setEntries} setGroups={setGroups} />
          </div>

          <div
            id='database-navbar-delete'
            className={'database-navbar-item database-navbar-item-delete  ' + (!selectedIDs.length ? 'database-navbar-item-disabled' : '')}
            onClick={() => {
              if (selectedIDs.length) {
                setShowDeleteModal(true);
              }
            }}
          >
            <IconTrashX size={20} />
          </div>

          <Tooltip anchorSelect='#database-navbar-delete' place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
            Delete selected
          </Tooltip>

          <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} handleDelete={handleDelete} selectedIDs={selectedIDs} />
          <Modal show={pipe(viewState, isNotNil)(tagEditorNodeUuidLens)} onHide={() => setState(set(tagEditorNodeUuidLens)(null))}>
            <Modal.Header closeButton>
              <Modal.Title>Tags for {pipe(viewState, getNodeNameLens, viewState)(tagEditorNodeUuidLens)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='flex flex-col gap-2'>
              <ReactTags
                classNames={{
                  selected: 'flex flex-row flex-wrap w-full gap-2 items-center',
                  tag: 'flex flex-row items-center h-full gap-1 p-2 text-xs text-white rounded-lg bg-blue-bobyard',
                  tagInput: '',
                  tagInputField: 'rounded-lg bg-gray-revell p-2',
                  remove: '',
                  suggestions: 'rounded-lg shadow bg-white p-2 mt-2',
                  activeSuggestion: 'bg-blue-bobyard text-white rounded-lg',
                  editTagInput: 'editTagInputClass',
                  editTagInputField: 'editTagInputField',
                  clearAll: 'clearAllClass',
                }}
                handleAddition={({ text }) => {
                  const itemUuid = viewState(tagEditorNodeUuidLens);
                  const isAssemblyEntry = getIsAssemblyEntry(itemUuid);
                  const database_entry_uuids = isAssemblyEntry ? [] : [itemUuid];
                  const database_assembly_entry_uuids = isAssemblyEntry ? [itemUuid] : [];
                  pipe(
                    databaseItemTagAPI,
                    andThen(view(responseDataLens)),
                    andThen(createLookupByKeyName('uuid')),
                    andThen(set(nodesLens)),
                    andThen(setState)
                  )([
                    { operation: 'database_item_tag_create_unique', database_entry_uuids, database_assembly_entry_uuids, text },
                    { operation: 'database_entries_get' },
                    { operation: 'database_assembly_entries_get' },
                  ]);
                }}
                handleDelete={(index, _) => {
                  const itemUuid = viewState(tagEditorNodeUuidLens);
                  const isAssemblyEntry = getIsAssemblyEntry(itemUuid);
                  const operation = isAssemblyEntry ? 'item_tag_remove_estimate_assembly_entry' : 'database_item_tag_remove_database_entry';
                  const itemParamName = isAssemblyEntry ? 'database_assembly_entry_uuid' : 'database_entry_uuid';
                  const databaseTagUuid = pipe(viewState, getNodeDatabaseItemTagUuidsLens, viewState, nth(index))(tagEditorNodeUuidLens);
                  pipe(
                    databaseItemTagAPI,
                    andThen(view(responseDataLens)),
                    andThen(createLookupByKeyName('uuid')),
                    andThen(set(nodesLens)),
                    andThen(setState)
                  )([{ operation, [itemParamName]: itemUuid, database_item_tag_uuid: databaseTagUuid }, { operation: 'database_entries_get' }, { operation: 'database_assembly_entries_get' }]);
                }}
                tags={pipe(
                  viewState,
                  getNodeDatabaseItemTagUuidsLens,
                  viewState,
                  defaultTo([]),
                  map((nodeUuid) => ({ id: nodeUuid, text: pipe(getNodeTextLens, viewState)(nodeUuid) }))
                )(tagEditorNodeUuidLens)}
                suggestions={map((nodeUuid) => ({ id: nodeUuid, text: pipe(getNodeTextLens, viewState)(nodeUuid) }))(databaseItemTagUuids)}
              />
            </Modal.Body>
          </Modal>
          <a href={`/profile`} className='database-navbar-profile-picture-link'>
            <div className='database-navbar-profile-picture-container' id='database-navbar-profile-picture-link'>
              {contractor?.profile_picture ? (
                <img src={contractor?.profile_picture} alt='profile picture' className='database-navbar-profile-picture' />
              ) : contractor?.first_name && contractor?.last_name ? (
                <div className='database-navbar-profile-picture-initials'>{contractor?.first_name[0] + contractor?.last_name[0]}</div>
              ) : (
                <img src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'} alt='profile picture' className='database-navbar-profile-picture' />
              )}
            </div>
          </a>

          <Tooltip anchorSelect='#database-navbar-profile-picture-link' place='bottom-end' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
            Navigate to profile
          </Tooltip>
        </div>
      </div>

      <DatabaseContextMenu
        trackVisibility={trackVisibility}
        setShowDeleteModal={setShowDeleteModal}
        tree={tree}
        setTree={setTree}
        activeID={activeID}
        entries={entries}
        setEntries={setEntries}
        groups={groups}
        setGroups={setGroups}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        addingEntry={addingEntry}
        handleAddItem={handleAddItem}
        addingGroup={addingGroup}
        handleAddGroup={handleAddGroup}
        addingAssembly={addingAssembly}
        selectedIDs={selectedIDs}
        handleAddAssemblyItem={handleAddAssemblyItem}
        handleAddAssembly={handleAddAssembly}
      />

      {tree && entries && groups && columns && visibleIDs ? (
        <ScrollSync>
          <div className='database-columns' onClick={() => setShowContextMenu(false)}>
            <div
              className='database-column database-sidebar'
              style={{
                width: column_settings['name_width'] + 'px',
              }}
            >
              <Resizable
                axis='x'
                handle={
                  <div
                    className='database-column-resize-handle'
                    onClick={(e) => e.stopPropagation()}
                    onDoubleClick={(e) => handleUpdateColumnSettings('name_width', 300)}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      handleUpdateColumnSettings('name_width', 300);
                    }}
                  >
                    &nbsp;
                  </div>
                }
                minConstraints={[300, 0]}
                maxConstraints={[1000, Infinity]}
                height={Infinity}
                width={column_settings['name_width']}
                onResizeStart={() => setResizing('name')}
                onResize={(e, data) => {
                  setColumnSettings((prev) => ({
                    ...prev,
                    name_width: Math.max(300, data.size.width),
                  }));
                }}
                onResizeStop={(e, data) => {
                  handleUpdateColumnSettings('name_width', Math.max(300, data.size.width));
                }}
              >
                <div
                  id='database-column-header-name'
                  className='database-column-header'
                  style={{
                    width: column_settings['name_width'] + 'px',
                  }}
                  onClick={() => {
                    if (resizing) return;

                    let sort = settings?.sort;

                    if (sort === '+name') {
                      sort = '-name';
                    } else if (sort === '-name') {
                      sort = null;
                    } else {
                      sort = '+name';
                    }

                    handleUpdateSort({
                      ...settings,
                      sort: sort,
                    });
                    setLoadingSort('name');
                  }}
                >
                  Name {settings?.sort === '+name' ? '▲' : settings?.sort === '-name' ? '▼' : null}{' '}
                  {loadingSort === 'name' && <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='10' wrapperStyle={{}} wrapperClass='blocks-wrapper' />}
                </div>
              </Resizable>

              <Tooltip anchorSelect='#database-column-header-name' place='bottom' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
                Click to cycle through sorting options
              </Tooltip>

              {searching || expandingAll || loadingSort || (resizing && resizing !== 'name') ? (
                <div className='database-sidebar-content'>
                  {Array.from({ length: 7 }, (_, i) => (
                    <Skeleton key={i} height={40} window={280} width={column_settings['name_width'] - 5 + 'px'} />
                  ))}
                </div>
              ) : (
                <ScrollSyncPane>
                  <div className='database-sidebar-content'>
                    <DatabaseContext.Provider
                      value={{
                        entries,
                        assemblyEntries,
                        groups,
                        handleUpdateField,
                        //hoverID, setHoverID,
                        activeID,
                        setActiveID,
                        column_settings,
                        draggingID,
                        setDraggingID,
                        handleClick,
                        selectedIDs,
                        handleUpdateGroupField,
                        settings,
                        show,
                        setShowContextMenu,
                      }}
                    >
                      <SortableTree
                        items={tree}
                        onItemsChanged={(newTree, { type, item, draggedItem, draggedFromParent, droppedToParent }) =>
                          handleUpdateTree(newTree, type, item, draggedItem, draggedFromParent, droppedToParent)
                        }
                        TreeItemComponent={TreeItem}
                        keepGhostInPlace={settings?.sort}
                        disableSorting={loadingSort}
                        pointerSensorOptions={{
                          activationConstraint: {
                            distance: 10,
                          },
                        }}
                      />
                    </DatabaseContext.Provider>
                  </div>
                </ScrollSyncPane>
              )}
            </div>

            {columns &&
              Object.keys(columns)
                ?.filter((columnID) => columnID !== 'name' && columns[columnID])
                ?.map((columnID) => (
                  <Tooltip key={columnID} anchorSelect={'#database-column-header-' + columnID} place='bottom' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
                    <div>{ColumnDescriptions[columnID]}</div>
                    <div>Click to cycle through sorting options</div>
                  </Tooltip>
                ))}

            {columns &&
              Object.keys(columns)
                ?.filter((columnID) => columnID !== 'name' && columns[columnID])
                ?.map((columnID) => (
                  <div
                    key={columnID}
                    className='database-column'
                    style={{
                      width: column_settings[columnID + '_width'] + 'px',
                    }}
                  >
                    <Resizable
                      axis='x'
                      handle={
                        <div
                          className='database-column-resize-handle'
                          onClick={(e) => e.stopPropagation()}
                          onDoubleClick={(e) => handleUpdateColumnSettings(columnID + '_width', 200)}
                          onContextMenu={(e) => {
                            e.preventDefault();
                            handleUpdateColumnSettings(columnID + '_width', 200);
                          }}
                        >
                          &nbsp;
                        </div>
                      }
                      minConstraints={[200, 0]}
                      maxConstraints={[1000, Infinity]}
                      height={Infinity}
                      width={column_settings[columnID + '_width'] || 200}
                      onResizeStart={() => setResizing(columnID)}
                      onResize={(e, data) => {
                        setColumnSettings((prev) => ({
                          ...prev,
                          [columnID + '_width']: Math.max(200, data.size.width),
                        }));
                      }}
                      onResizeStop={(e, data) => {
                        handleUpdateColumnSettings(columnID + '_width', Math.max(200, data.size.width));
                      }}
                    >
                      <div
                        id={'database-column-header-' + columnID}
                        className='database-column-header'
                        style={{
                          width: column_settings[columnID + '_width'] + 'px',
                        }}
                        onClick={() => {
                          if (resizing) return;

                          let sort = settings?.sort;

                          if (sort === '+' + columnID) {
                            sort = '-' + columnID;
                          } else if (sort === '-' + columnID) {
                            sort = null;
                          } else {
                            sort = '+' + columnID;
                          }

                          handleUpdateSort({
                            ...settings,
                            sort: sort,
                          });
                          setLoadingSort(columnID);
                        }}
                      >
                        {ColumnLabels[columnID]} {settings?.sort === '+' + columnID ? '▲' : settings?.sort === '-' + columnID ? '▼' : null}{' '}
                        {loadingSort === columnID && (
                          <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='10' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                        )}
                      </div>
                    </Resizable>

                    {searching || expandingAll || expanding || loadingSort || (resizing && resizing !== columnID) ? (
                      <div className='database-column-content'>
                        {Array.from({ length: 7 }, (_, i) => (
                          <Skeleton key={i} height={40} window={280} width={column_settings[columnID + '_width'] - 5 + 'px'} />
                        ))}
                      </div>
                    ) : (
                      <ScrollSyncPane>
                        <div className='database-column-content'>
                          {visibleIDs?.map((entryID) => (
                            <InView rootMargin='1000px 10px 1000px 10px'>
                              {({ inView, ref, entry }) => (
                                <div
                                  ref={ref}
                                  onClick={() => setShowContextMenu(false)}
                                  onContextMenu={(e) => {
                                    show({ event: e });

                                    if (!selectedIDs.includes(entryID)) {
                                      handleClick(entryID, e);
                                    }
                                  }}
                                >
                                  {inView ? (
                                    <Entry
                                      entryID={entryID}
                                      columnID={columnID}
                                      //setHoverID={setHoverID}
                                      //hoverID={hoverID}
                                      entries={entries}
                                      assemblyEntries={assemblyEntries}
                                      groups={groups}
                                      selectedIDs={selectedIDs}
                                      activeID={activeID}
                                      handleClick={handleClick}
                                      handleUpdateField={handleUpdateField}
                                      handleUpdateGroupField={handleUpdateGroupField}
                                      multiselectAnchor1={multiselectAnchor1}
                                      multiselectAnchor2={multiselectAnchor2}
                                      calculatedValue={CalculateValue(columnID, entries[entryID.split('-')[1]], assemblyEntries)}
                                      tagUuids={pipe(getUuidFromEntryID, getNodeDatabaseItemTagUuidsLens, viewState, defaultTo([]))(entryID)}
                                      getTagText={pipe(getNodeTextLens, viewState, defaultTo(''))}
                                      handleTagAddition={() => pipe(getUuidFromEntryID, set(tagEditorNodeUuidLens), setState)(entryID)}
                                      handleTagDeletion={(databaseTagUuid) => {
                                        const itemUuid = getUuidFromEntryID(entryID);
                                        const isAssemblyEntry = getIsAssemblyEntry(itemUuid);
                                        const operation = isAssemblyEntry ? 'item_tag_remove_estimate_assembly_entry' : 'database_item_tag_remove_database_entry';
                                        const itemParamName = isAssemblyEntry ? 'database_assembly_entry_uuid' : 'database_entry_uuid';
                                        pipe(
                                          databaseItemTagAPI,
                                          andThen(view(responseDataLens)),
                                          andThen(createLookupByKeyName('uuid')),
                                          andThen(set(nodesLens)),
                                          andThen(setState)
                                        )([
                                          { operation, [itemParamName]: itemUuid, database_item_tag_uuid: databaseTagUuid },
                                          { operation: 'database_entries_get' },
                                          { operation: 'database_assembly_entries_get' },
                                        ]);
                                      }}
                                    />
                                  ) : entryID.includes('group') && !GroupFields[columnID] ? (
                                    <div key={entryID} className={'database-entry '}>
                                      &nbsp;&nbsp;&nbsp;--
                                    </div>
                                  ) : (
                                    <div key={entryID} className={'database-entry '}>
                                      <div className='database-entry-field' style={{ color: 'silver' }}>
                                        ...
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </InView>
                          ))}
                        </div>
                      </ScrollSyncPane>
                    )}
                  </div>
                ))}
          </div>
        </ScrollSync>
      ) : null}
    </div>
  );
}

const DatabaseContextMenu = ({
  activeID,
  trackVisibility,
  setShowDeleteModal,
  tree,
  setTree,
  entries,
  groups,
  setEntries,
  setGroups,
  addingEntry,
  handleAddItem,
  addingGroup,
  handleAddGroup,
  addingAssembly,
  handleAddAssembly,
  showContextMenu,
  setShowContextMenu,
  selectedIDs,
  handleAddAssemblyItem,
}) => {
  const auth = useSelector(selectAuth);

  const [showGroupOptions, setShowGroupOptions] = useState(false);

  const deepCut = (data, id) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        let item = data[i];
        data.splice(i, 1);
        return item;
      } else if (data[i].children) {
        let item = deepCut(data[i].children, id);
        if (item) return item;
      }
    }

    return null;
  };

  const deepPaste = (data, id, item) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        data[i].children.push(item);
        return [true, data];
      } else if (data[i].children) {
        if (deepPaste(data[i].children, id, item)[0]) {
          return [true, data];
        }
      }
    }

    return [false, data];
  };

  const handleUngroup = () => {
    let newTree = [...tree];
    let item = deepCut(newTree, activeID);
    newTree = [...newTree, item];

    setTree(newTree);

    if (activeID.includes('entry-')) {
      let newEntries = { ...entries };
      newEntries[activeID.split('-')[1]] = {
        ...newEntries[activeID.split('-')[1]],
        group: null,
      };
      setEntries(newEntries);
    } else {
      let newGroups = { ...groups };
      newGroups[activeID.split('-')[1]] = {
        ...newGroups[activeID.split('-')[1]],
        group: null,
      };
      setGroups(newGroups);
    }

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/database-tree/`,
      data: {
        companyID: auth.contractor.company,
        tree: newTree,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelect = (groupID) => {
    let newTree = [...tree];
    let item = deepCut(newTree, activeID);

    let result = deepPaste(newTree, groupID, item);
    if (!result[0]) return;

    newTree = result[1];
    setTree(newTree);

    if (activeID.includes('entry-')) {
      let newEntries = { ...entries };
      newEntries[activeID.split('-')[1]] = {
        ...newEntries[activeID.split('-')[1]],
        group: groupID.split('-')[1],
      };
      setEntries(newEntries);
    } else {
      let newGroups = { ...groups };
      newGroups[activeID.split('-')[1]] = {
        ...newGroups[activeID.split('-')[1]],
        group: groupID.split('-')[1],
      };
      setGroups(newGroups);
    }

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/database-tree/`,
      data: {
        companyID: auth.contractor.company,
        tree: newTree,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSelectMulti = (groupID) => {
    //move all selectedIDs to new location
    let newTree = [...tree];

    let newGroups = { ...groups };
    let newEntries = { ...entries };

    for (const selectedID of selectedIDs.filter((id) => {
      let parent = id.includes('entry-') ? entries[id.split('-')[1]].group : groups[id.split('-')[1]].group;

      while (parent) {
        if (id !== 'group-' + String(parent) && selectedIDs.includes('group-' + String(parent))) {
          return false;
        }

        parent = groups[parent]?.group;
      }

      return true;
    })) {
      const temp = deepCut(newTree, selectedID);
      const result = deepPaste(newTree, groupID, temp);
      if (result[0]) {
        newTree = result[1];
        if (selectedID.includes('group')) {
          newGroups[selectedID.split('-')[1]]['group'] = groupID;
        } else {
          newEntries[selectedID.split('-')[1]]['group'] = groupID;
        }
      }
    }

    setGroups(newGroups);
    setEntries(newEntries);
    setTree(newTree);

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/database-tree/`,
      data: {
        companyID: auth.contractor.company,
        tree: newTree,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const currentParent = activeID?.includes('group') ? groups[activeID?.split('-')[1]]?.group : entries[activeID?.split('-')[1]]?.group;

  return (
    <Menu
      id={'database-context-menu'}
      //onVisibilityChange={trackVisibility}
      theme='bobyard-light'
    >
      {new Set(selectedIDs).size > 1 ? (
        <>
          <div
            className='aisidebar-legend-entry-menu-item'
            onClick={() => {
              const element = document.getElementById('database-navbar-group-edit');
              if (element) {
                element.dispatchEvent(new MouseEvent('click', { bubbles: true }));
              }
            }}
          >
            <IconEdit size={20} stroke={1} />
            Group edit
          </div>

          {tree && !activeID?.includes('assembly') && tree?.filter((a) => a.id.includes('group')).filter((a) => !a.path.includes(activeID)).length > 0 && (
            <Item onMouseEnter={() => setShowGroupOptions(true)} onMouseLeave={() => setShowGroupOptions(false)}>
              <div className='aisidebar-legend-entry-menu-item' onClick={(e) => e.stopPropagation()}>
                <IconFolder size={20} stroke={1} />
                Move
              </div>

              {showGroupOptions && (
                <div className={'database-context-menu-group-options'} onClick={(e) => e.stopPropagation()}>
                  {tree &&
                    tree
                      ?.filter((a) => a.id.includes('group'))
                      .filter((a) => !a.path.includes(activeID))
                      .filter((a) => {
                        for (const SelectedID of selectedIDs) {
                          if (a.path.includes(SelectedID)) {
                            return false;
                          }
                        }

                        return true;
                      })
                      .map((group, i) => <NestedGroups group={group} handleSelect={handleSelectMulti} activeID={activeID} currentParent={currentParent} multi={true} selectedIDs={selectedIDs} />)}
                </div>
              )}
            </Item>
          )}
        </>
      ) : (
        <>
          {activeID?.includes('group') ? (
            <>
              <Item
                onClick={() => {
                  if (addingGroup) return;
                  handleAddGroup();
                }}
              >
                <div className='aisidebar-legend-entry-menu-item'>
                  {addingGroup ? (
                    <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                  ) : (
                    <>
                      <IconFolderPlus size={20} stroke={1} />
                      Add group
                    </>
                  )}
                </div>
              </Item>

              <Item
                onClick={() => {
                  if (addingEntry) return;
                  handleAddItem();
                }}
              >
                <div className='aisidebar-legend-entry-menu-item'>
                  {addingEntry ? (
                    <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                  ) : (
                    <>
                      <IconCirclePlus size={20} stroke={1} />
                      Add item
                    </>
                  )}
                </div>
              </Item>

              <Item
                onClick={() => {
                  if (addingAssembly) return;
                  handleAddAssembly();
                }}
              >
                <div className='aisidebar-legend-entry-menu-item'>
                  {addingAssembly ? (
                    <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                  ) : (
                    <>
                      <IconCubePlus size={20} stroke={1} />
                      Add assembly
                    </>
                  )}
                </div>
              </Item>
            </>
          ) : null}

          {activeID?.includes('entry-') && entries[activeID?.split('-')[1]]?.type === 'assembly' ? (
            <Item onClick={() => handleAddAssemblyItem(activeID)}>
              <div className='aisidebar-legend-entry-menu-item'>
                <IconCirclePlus size={20} stroke={1} />
                Add assembly item
              </div>
            </Item>
          ) : null}

          {(activeID?.includes('group') && groups[activeID?.split('-')[1]]?.group) || (activeID?.includes('entry-') && entries[activeID?.split('-')[1]]?.group) ? (
            <Item onClick={() => handleUngroup()}>
              <div className='aisidebar-legend-entry-menu-item'>
                <IconFolderOff size={20} stroke={1} />
                Ungroup
              </div>
            </Item>
          ) : null}

          {tree && !activeID?.includes('assembly') && tree?.filter((a) => a.id.includes('group')).filter((a) => !a.path.includes(activeID)).length > 0 && (
            <Item onMouseEnter={() => setShowGroupOptions(true)} onMouseLeave={() => setShowGroupOptions(false)}>
              <div className='aisidebar-legend-entry-menu-item' onClick={(e) => e.stopPropagation()}>
                <IconFolder size={20} stroke={1} />
                Move
              </div>

              {showGroupOptions && (
                <div className={'database-context-menu-group-options'} onClick={(e) => e.stopPropagation()}>
                  {tree &&
                    tree
                      ?.filter((a) => a.id.includes('group'))
                      .filter((a) => !a.path.includes(activeID))
                      .map((group, i) => <NestedGroups group={group} handleSelect={handleSelect} activeID={activeID} currentParent={currentParent} />)}
                </div>
              )}
            </Item>
          )}
        </>
      )}

      <Item onClick={() => setShowDeleteModal(true)}>
        <div className='aisidebar-legend-entry-menu-delete'>
          <IconTrashX size={20} stroke={1} /> Delete
        </div>
      </Item>
    </Menu>
  );
};

const NestedGroups = ({ group, handleSelect, activeID, currentParent, multi, selectedIDs }) => {
  const [show, setShow] = useState(false);

  return (
    <div key={group.id} className='database-context-menu-group'>
      <div
        className={'database-context-menu-group-header ' + (group.id === 'group-' + currentParent ? 'database-context-menu-group-header-current' : '')}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShow(!show);
        }}
      >
        <div>
          {group &&
            group.children.filter((a) => a?.id?.includes('group')).filter((a) => !a?.path?.includes(activeID)).length > 0 &&
            (show ? <IconChevronDown size={20} /> : <IconChevronRight size={20} />)}
        </div>
        <div className='database-context-menu-group-name'>{group.name}</div>
        {group.id === 'group-' + currentParent ? (
          <div className='database-context-menu-group-name'>Current</div>
        ) : (
          <div
            className='database-context-menu-group-select'
            onClick={(e) => {
              handleSelect(group.id);
            }}
          >
            Select
          </div>
        )}
      </div>

      {show && group && group.children.filter((a) => a.id.includes('group')).filter((a) => !a.path.includes(activeID)).length > 0 && (
        <div className='database-context-menu-group-children'>
          {group?.children
            .filter((a) => a.id.includes('group'))
            .filter((a) => !a.path.includes(activeID))
            .filter((a) => {
              if (!multi) return true;

              for (const SelectedID of selectedIDs) {
                if (a.path.includes(SelectedID)) {
                  return false;
                }
              }

              return true;
            })
            .map((g) => (
              <NestedGroups group={g} handleSelect={handleSelect} activeID={activeID} currentParent={currentParent} multi={multi} selectedIDs={selectedIDs} />
            ))}
        </div>
      )}
    </div>
  );
};

const Entry = memo(
  ({
    entryID,
    columnID,
    entries,
    assemblyEntries,
    groups,
    selectedIDs,
    activeID,
    handleClick,
    handleUpdateField,
    handleUpdateGroupField,
    calculatedValue,
    tagUuids,
    handleTagAddition,
    handleTagDeletion,
    getTagText,
  }) => {
    /*let calculatedValue = useMemo(() => {
        return CalculateValue(columnID, entries[entryID.split('-')[1]]);
    }, [columnID, entries, entryID]);*/

    return (
      <div
        key={entryID}
        className={'database-entry '}
        //onMouseEnter={() => setHoverID(entryID)}
        //onMouseLeave={() => setHoverID(null)}
        style={{
          backgroundColor: selectedIDs.includes(entryID)
            ? 'aliceblue'
            : entryID === activeID
            ? 'aliceblue'
            : /*entryID === hoverID
                            ? '#EEEEEE'
                            : */ '#F8F8F8',
        }}
      >
        {entryID.includes('group') ? (
          GroupFields[columnID] ? (
            InputTypes[columnID] === 'text' ? (
              <TextField
                className='database-entry-field'
                onBlur={(value) => handleUpdateGroupField(entryID, columnID, value)}
                onClick={(e) => {
                  handleClick(entryID, e);
                }}
                value={groups[entryID.split('-')[1]]?.[columnID]}
                //placeholder={columnID.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                placeholder={'...'}
              />
            ) : (
              <>&nbsp;&nbsp;&nbsp;--</>
            )
          ) : (
            <>&nbsp;&nbsp;&nbsp;--</>
          )
        ) : columnID == 'item_tags' ? (
          <EstimateTagField tags={map((tagUuid) => ({ id: tagUuid, text: getTagText(tagUuid) }))(tagUuids)} handleAddition={handleTagAddition} handleDeletion={handleTagDeletion} />
        ) : entryID.includes('assembly') ? (
          AssemblyEntryFields[columnID] ? (
            InputTypes[columnID] === 'text' ? (
              <TextField
                className='database-entry-field'
                onBlur={(value) => handleUpdateField(entryID, columnID, value)}
                onClick={(e) => {
                  handleClick(entryID, e);
                }}
                value={assemblyEntries[entryID.split('-')[1]]?.[columnID]}
                //placeholder={columnID.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                placeholder={'...'}
              />
            ) : (
              <NumericField
                className={'database-entry-field '}
                onBlur={(value) => {
                  handleUpdateField(entryID, columnID, value);
                }}
                onClick={(e) => {
                  handleClick(entryID, e);
                }}
                value={assemblyEntries[entryID.split('-')[1]]?.[columnID]}
                placeholder={0.0}
              />
            )
          ) : (
            <>&nbsp;&nbsp;&nbsp;--</>
          )
        ) : entries[entryID.split('-')[1]].type === 'assembly' ? (
          AssemblyFields[columnID] ? (
            InputEditable[columnID] ? (
              InputTypes[columnID] === 'text' ? (
                <TextField
                  className='database-entry-field'
                  onBlur={(value) => handleUpdateField(entryID, columnID, value)}
                  onClick={(e) => {
                    handleClick(entryID, e);
                  }}
                  value={entries[entryID.split('-')[1]]?.[columnID]}
                  //placeholder={columnID.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                  placeholder={'...'}
                />
              ) : (
                <NumericField
                  className={'database-entry-field ' + (AssemblyCalculatedFields[columnID] ? ' database-entry-field-calculated ' : '')}
                  onBlur={(value) => {
                    handleUpdateField(entryID, columnID, value);
                  }}
                  onClick={(e) => {
                    handleClick(entryID, e);
                  }}
                  value={entries[entryID.split('-')[1]]?.[columnID]}
                  placeholder={AssemblyCalculatedFields[columnID] ? calculatedValue : 0.0}
                />
              )
            ) : (
              <>
                &nbsp;&nbsp;&nbsp;
                {AssemblyCalculatedFields[columnID]
                  ? isFinite(calculatedValue)
                    ? numToStr2Places(calculatedValue)
                    : 'ERROR'
                  : isFinite(entries[entryID.split('-')[1]]?.[columnID])
                  ? numToStr2Places(entries[entryID.split('-')[1]]?.[columnID])
                  : 'ERROR'}
              </>
            )
          ) : (
            <>&nbsp;&nbsp;&nbsp;--</>
          )
        ) : InputEditable[columnID] ? (
          InputTypes[columnID] === 'text' ? (
            <TextField
              className='database-entry-field'
              onBlur={(value) => handleUpdateField(entryID, columnID, value)}
              onClick={(e) => {
                handleClick(entryID, e);
              }}
              value={entries[entryID.split('-')[1]]?.[columnID]}
              //placeholder={columnID.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
              placeholder={'...'}
            />
          ) : (
            <NumericField
              className={'database-entry-field ' + (InputCalculated[columnID] && entryID.includes('entry-') && InputEditable[columnID] ? ' database-entry-field-calculated ' : '')}
              onBlur={(value) => {
                handleUpdateField(entryID, columnID, value);
              }}
              onClick={(e) => {
                handleClick(entryID, e);
              }}
              value={entries[entryID.split('-')[1]]?.[columnID]}
              placeholder={InputCalculated[columnID] ? calculatedValue : 0.0}
            />
          )
        ) : (
          <>
            &nbsp;&nbsp;&nbsp;
            {InputCalculated[columnID]
              ? isFinite(calculatedValue)
                ? numToStr2Places(calculatedValue)
                : 'ERROR'
              : isFinite(entries[entryID.split('-')[1]]?.[columnID])
              ? numToStr2Places(entries[entryID.split('-')[1]]?.[columnID])
              : 'ERROR'}
          </>
        )}

        {(entryID.includes('entry-') && entries[entryID.split('-')[1]]?.type === 'entry' && InputEditable[columnID] && InputCalculated[columnID]) ||
        (entryID.includes('entry-') && entries[entryID.split('-')[1]]?.type === 'assembly' && AssemblyCalculatedFields[columnID] && InputEditable[columnID]) ? (
          entries[entryID.split('-')[1]]?.[columnID] !== null && entries[entryID.split('-')[1]]?.[columnID] !== undefined ? (
            <InputFieldRevert entryID={entryID} handleRevert={() => handleUpdateField(entryID, columnID, null)} />
          ) : (
            <div id={'database-entry-calculated-' + entryID} className='database-entry-calculated-icon'>
              <EmptyCircleIcon />
            </div>
          )
        ) : null}

        <Tooltip anchorSelect={'#database-entry-calculated-' + entryID} place='top' delayShow={500} positionstrategy='fixed' style={{ zindex: 1000 }}>
          Calculated values are automatically generated
        </Tooltip>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.entryID === nextProps.entryID &&
      prevProps.columnID === nextProps.columnID &&
      prevProps.entries[prevProps.entryID.split('-')[1]]?.[prevProps.columnID] === nextProps.entries[nextProps.entryID.split('-')[1]]?.[nextProps.columnID] &&
      prevProps.groups[prevProps.entryID.split('-')[1]]?.[prevProps.columnID] === nextProps.groups[nextProps.entryID.split('-')[1]]?.[nextProps.columnID] &&
      prevProps.assemblyEntries[prevProps.entryID.split('-')[1]]?.[prevProps.columnID] === nextProps.assemblyEntries[nextProps.entryID.split('-')[1]]?.[nextProps.columnID] &&
      prevProps.selectedIDs.includes(prevProps.entryID) === nextProps.selectedIDs.includes(nextProps.entryID) &&
      (prevProps.activeID === prevProps.entryID) === (nextProps.activeID === nextProps.entryID) &&
      prevProps.multiselectAnchor1 === nextProps.multiselectAnchor1 &&
      prevProps.multiselectAnchor2 === nextProps.multiselectAnchor2 &&
      //&& (prevProps.hoverID === prevProps.entryID) === (nextProps.hoverID === nextProps.entryID)
      prevProps.calculatedValue === nextProps.calculatedValue &&
      prevProps.tagUuids === nextProps.tagUuids
    );
    //&& CalculateValue(prevProps.columnID, prevProps.entries[prevProps.entryID?.split('-')[1]]) === CalculateValue(nextProps.columnID, nextProps.entries[nextProps.entryID?.split('-')[1]])
  }
);

const TreeItem = forwardRef((props, ref) => {
  const { entries, groups, assemblyEntries, handleUpdateField, column_settings, draggingID, setDraggingID, handleClick, handleUpdateGroupField, show, setShowContextMenu, selectedIDs } =
    useContext(DatabaseContext);

  if (props.item.id.includes('entry-') && entries[props.item.id.split('-')[1]]?.type === 'assembly') {
    return (
      <FolderTreeItemWrapper {...props} ref={ref} manualDrag={true} showDragHandle={true} hideCollapseButton={true}>
        <div
          id={'database-tree-entry-' + props.item.id}
          className='database-tree-entry'
          onContextMenu={(e) => {
            show({ event: e });

            if (!selectedIDs.includes(props.item.id)) {
              handleClick(props.item.id, e);
            }
          }}
          onClick={() => setShowContextMenu(false)}
          style={{
            width: draggingID === props.item.id ? null : column_settings['name_width'] - 20 * (props.item.id.includes('group') ? props.item.depth + 2 : props.item.depth + 2) - 10 + 'px',
          }}
        >
          <div className={'database-tree-entry-icon'} id={'database-tree-entry-icon-' + props.item.id}>
            {props.collapsed ? <IconCaretRight size={18} /> : <IconCaretDown size={18} />}
          </div>

          <TextField
            className='database-entry-field'
            onBlur={(value) => handleUpdateField(props.item.id, 'name', value)}
            onClick={(e) => {
              handleClick(props.item.id, e);
            }}
            value={entries[props.item.id.split('-')[1]]['name']}
            placeholder={'Name'}
          />
        </div>
      </FolderTreeItemWrapper>
    );
  } else if (props.item.id.includes('assembly')) {
    return (
      <FolderTreeItemWrapper {...props} ref={ref} manualDrag={true} showDragHandle={true} hideCollapseButton={true}>
        <div
          id={'database-tree-entry-' + props.item.id}
          className='database-tree-entry'
          onContextMenu={(e) => {
            show({ event: e });

            if (!selectedIDs.includes(props.item.id)) {
              handleClick(props.item.id, e);
            }
          }}
          onClick={() => setShowContextMenu(false)}
          style={{
            width: draggingID === props.item.id ? null : column_settings['name_width'] - 20 * (props.item.id.includes('group') ? props.item.depth + 2 : props.item.depth + 2) - 10 + 'px',
          }}
        >
          <div className={'database-tree-entry-icon database-tree-entry-icon-viewonly'} id={'database-tree-entry-icon-' + props.item.id}>
            <IconCube size={16} />
          </div>

          <TextField
            className='database-entry-field'
            onBlur={(value) => handleUpdateField(props.item.id, 'name', value)}
            onClick={(e) => {
              handleClick(props.item.id, e);
            }}
            value={assemblyEntries[props.item.id.split('-')[1]]['name']}
            placeholder={'Name'}
          />
        </div>
      </FolderTreeItemWrapper>
    );
  }

  return (
    <FolderTreeItemWrapper {...props} ref={ref} manualDrag={true} showDragHandle={true} hideCollapseButton={true}>
      <div
        id={'database-tree-entry-' + props.item.id}
        className='database-tree-entry'
        onContextMenu={(e) => {
          show({ event: e });

          if (!selectedIDs.includes(props.item.id)) {
            handleClick(props.item.id, e);
          }
        }}
        onClick={() => setShowContextMenu(false)}
        style={{
          width: draggingID === props.item.id ? null : column_settings['name_width'] - 20 * (props.item.id.includes('group') ? props.item.depth + 2 : props.item.depth + 2) - 10 + 'px',
        }}
      >
        <div className={'database-tree-entry-icon ' + (!props.item.id.includes('group') ? 'database-tree-entry-icon-disabled' : '')} id={'database-tree-entry-icon-' + props.item.id}>
          {props.item.id.includes('group') && (props.collapsed ? <IconFolder size={16} /> : <IconFolderOpen size={16} />)}
        </div>

        {/*<Tooltip anchorSelect={"#database-tree-entry-icon-" + props.item.id} place="top-start" delayShow={500} positionStrategy="fixed" style={{ zIndex: 1000 }}>
                    {props.collapsed
                        ? 'Expand group'
                        : 'Collapse group'
                    }
                </Tooltip>*/}

        <TextField
          className='database-entry-field'
          onBlur={(value) => (props.item.id.includes('group') ? handleUpdateGroupField(props.item.id, 'name', value) : handleUpdateField(props.item.id, 'name', value))}
          onClick={(e) => {
            handleClick(props.item.id, e);
          }}
          value={props.item.id.includes('group') ? groups[props.item.id.split('-')[1]]['name'] : entries[props.item.id.split('-')[1]]['name']}
          placeholder={'Name'}
        />
      </div>
    </FolderTreeItemWrapper>
  );
});

const InputFieldRevert = ({ handleRevert, entryID }) => {
  const [hover, setHover] = useState(false);

  return (
    <>
      <div
        id={'database-entry-overridden' + entryID}
        className='database-entry-calculated-icon database-entry-calculated-icon-override'
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => handleRevert()}
      >
        {hover ? (
          <div className='database-entry-calculated-icon-hover'>
            <IconCircleRevertHover />
          </div>
        ) : (
          <IconCircleRevert />
        )}
      </div>

      <Tooltip anchorSelect={'#database-entry-overridden' + entryID} place='top' delayShow={500} positionStrategy='fixed' style={{ zIndex: 1000 }}>
        <div>This is a calculated value that has been overridden</div>
        <div>Click to revert to the calculated value</div>
      </Tooltip>
    </>
  );
};
