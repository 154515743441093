import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Circle, Rect, Transformer, Group, Line } from 'react-konva';
import { TakeoffContext } from '../../helper/Context';
import { Html, Portal } from 'react-konva-utils';
import ContextMenu from '../../../components/ContextMenu';
import pSBC from '../../helper/Colors';
import { MeasurementsIntersect, MergeablePolygon } from '../../helper/Intersection';
import { IconCopy, IconPolygonOff, IconTimeline, IconTrashX, IconZoomScan } from '@tabler/icons-react';
import CustomIcon from '../../../components/CustomIcon';
import { IconPolygon } from '@tabler/icons-react';
import { IconLayersUnion } from '@tabler/icons-react';

export default function Rectangle({ measurement }) {
    const shapeRef = useRef();
    const transformerRef = useRef();

    const {
        project,
        pageID,
        pages,
        groups, setGroups,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        handleRectTransform,
        DeleteMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        setShowDetails,
        handleCombineMeasurements,
        optionDown,
        setDrawingRectangle, setDrawingPolygon, setDrawingLine, setDrawingCount, setDrawingScale, setDrawingCircle,
        setCuttingPolygon, setCuttingRectangle, setSplittingPolygon, setSplittingRectangle, setCuttingPolygonRect, setCuttingRectangleRect,
        setAddingCount, setDeletingCount,
        handleConvertPolygon,
        handleConvertLength,
        handleTurnOffDrawing,
        handleDuplicateMeasurement,
        updateMeasurementInState,
        setShowDeleteMeasurementModal,
    } = useContext(TakeoffContext);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [colorPattern, setColorPattern] = useState([]);

    const isSelected = currentMeasurement == measurement.id;

    useEffect(() => {
        if (isSelected) {
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    useEffect(() => {
        if (measurement?.gap > 0) {
            const colors = [];
            let x = Number(measurement.gap) / 4.0;

            for (let i = 0; i + 2 * x < 100; i += 2 * x) {
                colors.push(i / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, 'white');
                colors.push((i + 2 * x) / 100.0, 'white');
            }

            if (colorPattern !== colors) {
                setColorPattern(colors);
            }
        }
    }, [measurement, measurement.gap, measurement.color]);


    const handleDragStart = (e) => {
        transformerRef.current.nodes([shapeRef.current]);
    };

    const handleDragEnd = (e) => {
        handleRectTransform({
            ...measurement,
            width: e.target.width(),
            height: e.target.height(),
            area: measurement.offset
                ? Math.abs(e.target.width() - 2 * measurement.offset / pages[pageID].scale) * Math.abs(e.target.height() - 2 * measurement.offset / pages[pageID].scale)
                : Math.abs(e.target.width() * e.target.height()),
            rectangle: {
                ...measurement.rectangle,
                x: e.target.x(),
                y: e.target.y()
            },
        });
    }

    const handleTransform = (e) => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);

        updateMeasurementInState({
            ...measurement,
            width: Math.max(node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            area: measurement.offset
                ? Math.abs(node.width() * scaleX - 2 * measurement.offset / pages[pageID].scale) * Math.abs(node.height() * scaleY - 2 * measurement.offset / pages[pageID].scale)
                : Math.abs(node.width() * scaleX * node.height() * scaleY),
            rectangle: {
                ...measurement.rectangle,
                x: node.x(),
                y: node.y()
            },
        });
    };

    const handleTransformEnd = (e) => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);

        handleRectTransform({
            ...measurement,
            width: Math.max(node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            area: measurement.offset
                ? Math.abs(node.width() * scaleX - 2 * measurement.offset / pages[pageID].scale) * Math.abs(node.height() * scaleY - 2 * measurement.offset / pages[pageID].scale)
                : Math.abs(node.width() * scaleX * node.height() * scaleY),
            rectangle: {
                ...measurement.rectangle,
                x: node.x(),
                y: node.y(),
            },
            rotation: e.currentTarget?.attrs?.rotation,
        });
    };

    const width = (pages[pageID].width);
    const height = (pages[pageID].height);

    const angleInDeg = measurement.size;
    const angle = ((180 - angleInDeg) / 180) * Math.PI
    const length = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))
    const halfx = (Math.sin(angle) * length) / 2.0
    const halfy = (Math.cos(angle) * length) / 2.0
    const cx = width / 2.0
    const cy = height / 2.0
    const x1 = cx - halfx
    const y1 = cy - halfy
    const x2 = cx + halfx
    const y2 = cy + halfy

    function intersectionY(edge, y) {
        const [[x1, y1], [x2, y2]] = edge;
        const dir = Math.sign(y2 - y1);
        if (dir && (y1 - y) * (y2 - y) <= 0) return { x: x1 + (y - y1) / (y2 - y1) * (x2 - x1), dir };
    }

    function intersectionX(edge, x) {
        const [[x1, y1], [x2, y2]] = edge;
        const dir = Math.sign(x2 - x1);
        if (dir && (x1 - x) * (x2 - x) <= 0) return { y: y1 + (x - x1) / (x2 - x1) * (y2 - y1), dir };
    }

    function tilePolygon(points, tileSize) {
        const minY = Math.min(...points.map(p => p[1])) - tileSize / 2
        const maxY = Math.max(...points.map(p => p[1])) - tileSize / 2
        const minX = Math.min(...points.map(p => p[0])) - tileSize / 2
        const gridPoints = [];
        for (let y = minY; y <= maxY; y += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize; x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }
        return gridPoints;
    }

    function tilePolygonTriangle(points, tileSize) {
        //just like above, but for triangles. we will tile the plane with equilateral triangles with side length tileSize
        //then we will find the points of intersection of the triangle inside the polygon

        //the are two types of rows. 
        //1. start with y = 0: x = 0, x = tileSize, x = 2 * tileSize, x = 3 * tileSize, ...
        //              then y increases by sqrt(3.0) * tileSize

        //2. start with y = sqrt(3.0) * tileSize / 2: x = tileSize / 2, x = 3 * tileSize / 2, x = 5 * tileSize / 2, ...
        //              then y increases by sqrt(3.0) * tileSize

        const minY = Math.min(...points.map(p => p[1])) - tileSize / 2
        const maxY = Math.max(...points.map(p => p[1])) - tileSize / 2
        const minX = Math.min(...points.map(p => p[0])) - tileSize / 2

        const gridPoints = [];

        //run the code from tilePolygon for the first type of row
        for (let y = minY; y <= maxY; y += tileSize * Math.sqrt(3.0)) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize; x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        //run the code from tilePolygon for the second type of row
        for (let y = minY + tileSize * Math.sqrt(3.0) / 2.0; y <= maxY; y += tileSize * Math.sqrt(3.0)) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    // Genereate grid points on this horizontal line segement
                    for (let x = minX + Math.ceil((startX - minX) / tileSize) * tileSize + Math.ceil(tileSize / 2); x <= cut.x; x += tileSize) {
                        gridPoints.push([x, y]);
                    }
                    startX = null;
                }
            }
        }

        return gridPoints;
    }

    const gridPolygon = (points, tileSize) => {
        //polygon is a list of points
        //tileSize is the size of the grid
        //just like dots, but this time we just want to draw horizontal lines across the shape. 
        //return an array of lines that can be drawn (x1, y1, x2, y2)
        //if the ray leaves the polygon and enters again, we will draw two lines

        const minY = Math.min(...points.map(p => p[1]));
        const maxY = Math.max(...points.map(p => p[1]));

        const gridLines = [];

        for (let y = minY; y <= maxY; y += tileSize) {
            // Collect x-coordinates where polygon crosses this horizontal line (y)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionY([points[i], points[(i + 1) % points.length]], y);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startX = null;
            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startX === null) startX = cut.x;
                } else if (startX !== null) { // Exiting polygon
                    gridLines.push([startX, y, cut.x, y]);
                    startX = null;
                }
            }
        }

        return gridLines;
    }

    const gridPolygonVertical = (points, tileSize) => {
        //polygon is a list of points
        //tileSize is the size of the grid
        //just like dots, but this time we just want to draw vertical lines across the shape.

        const minX = Math.min(...points.map(p => p[0]));
        const maxX = Math.max(...points.map(p => p[0]));

        const gridLines = [];

        for (let x = minX; x <= maxX; x += tileSize) {
            // Collect y-coordinates where polygon crosses this vertical line (x)
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersectionX([points[i], points[(i + 1) % points.length]], x);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let startY = null;
            for (let cut of cuts.sort((a, b) => a.y - b.y)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (startY === null) startY = cut.y;
                } else if (startY !== null) { // Exiting polygon
                    gridLines.push([x, startY, x, cut.y]);
                    startY = null;
                }
            }
        }

        return gridLines;
    }

    const intersection = (edge1, edge2) => {
        //edge1 and edge2 are two edges of a polygon
        //return the point of intersection of these two edges
        //if the edges are parallel, return null

        let x1 = edge1[0][0];
        let y1 = edge1[0][1];
        let x2 = edge1[1][0];
        let y2 = edge1[1][1];

        let x3 = edge2[0][0];
        let y3 = edge2[0][1];
        let x4 = edge2[1][0];
        let y4 = edge2[1][1];

        let den = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

        if (den === 0) {
            return null;
        }

        let t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / den;
        let u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / den;

        if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
            return { x: x1 + t * (x2 - x1), y: y1 + t * (y2 - y1), dir: Math.sign((x2 - x1) * (y3 - y4) - (y2 - y1) * (x3 - x4)) };
        }

        return null;
    }

    const gridPolygonAngle = (points, tileSize, angle) => {
        //same as gridPolygon, but this time we will draw lines at an angle
        //we will shoot a ray at an angle through the polygon every tileSize distance

        //first we will find the line that is perpendicular to the angle and not passing through the polygon
        //this line will be the line that we will use to shoot rays from

        console.log(angle);

        const gridLines = [];

        //find the coordinates of this line
        let x1, y1, x2, y2;

        //find the slope of the line
        let m = - 1 / Math.tan(angle);

        //find the center of the polygon
        let cx = 0;
        let cy = 0;

        for (let i = 0; i < points.length; i++) {
            cx += points[i][0];
            cy += points[i][1];
        }

        cx /= points.length;
        cy /= points.length;

        //we will move this line to the left by the maximum distance between two points in the polygon
        let maxDistance = 0;

        for (let i = 0; i < points.length; i++) {
            for (let j = i + 1; j < points.length; j++) {
                let distance = Math.sqrt((points[i][0] - points[j][0]) * (points[i][0] - points[j][0]) + (points[i][1] - points[j][1]) * (points[i][1] - points[j][1]));
                if (distance > maxDistance) {
                    maxDistance = distance;
                }
            }
        }

        //move the center to be maxDistance away from the real center in a direction perpendicular to the angle

        cx -= maxDistance * Math.cos(angle);
        cy -= maxDistance * Math.sin(angle);

        //x1, y1 is maxDistance away cx, cy in the direction of the angle
        x1 = cx - maxDistance * Math.sin(angle);
        y1 = cy + maxDistance * Math.cos(angle);

        //x2, y2 is maxDistance away cx, cy in the opposite direction of the angle
        x2 = cx + maxDistance * Math.sin(angle);
        y2 = cy - maxDistance * Math.cos(angle);

        //now we will shoot rays from this line at an angle of angle
        //we will shoot rays every tileSize distance

        //the distance along the perpendicular line is tileSize
        // so distance between (start_x, start_y) and x1, y1 are multiples of tileSize

        for (let start_x = Math.min(x1, x2); start_x <= Math.max(x1, x2); start_x += tileSize * Math.abs(Math.sin(angle))) {
            //this ray starts from (x, y) and goes in the direction of the angle

            let start_y = x1 < x2
                ? y1 + m * (start_x - (Math.min(x1, x2)))
                : y1 + m * (start_x - (Math.max(x1, x2)));

            //the line is always maxDistance * 2 long 
            let end_x = start_x + maxDistance * 2 * Math.cos(angle);
            let end_y = start_y + (Math.tan(angle) * maxDistance * 2 * Math.cos(angle));

            // Collect x-coordinates where polygon crosses this line 
            const cuts = [];
            let prev = null;
            for (let i = 0; i < points.length; i++) {
                const cut = intersection([points[i], points[(i + 1) % points.length]], [[start_x, start_y], [end_x, end_y]]);
                if (!cut) continue;
                if (!prev || prev.dir !== cut.dir) cuts.push(cut);
                prev = cut;
            }
            if (prev && prev.dir === cuts[0].dir) cuts.pop();
            // Now go through those cuts from left to right toggling whether we are in/out the polygon
            let dirSum = 0;
            let cut_x1 = null;
            let cut_y1 = null;
            let cut_x2 = null;
            let cut_y2 = null;

            for (let cut of cuts.sort((a, b) => a.x - b.x)) {
                dirSum += cut.dir;
                if (dirSum % 2) { // Entering polygon
                    if (cut_x1 === null) {
                        cut_x1 = cut.x;
                        cut_y1 = cut.y;
                    }
                } else if (cut_x1 !== null) { // Exiting polygon
                    cut_x2 = cut.x;
                    cut_y2 = cut.y;
                    gridLines.push([cut_x1, cut_y1, cut_x2, cut_y2]);
                    cut_x1 = null;
                    cut_y1 = null;
                    cut_x2 = null;
                    cut_y2 = null;
                }
            }
        }

        return gridLines;
    }

    const OCLines = useMemo(() => {
        if ((measurement.uom !== 'ft' && measurement.uom !== 'in' && measurement.uom !== 'yrd' && measurement.uom !== 'm' && measurement.uom !== 'cm'
            && measurement.uom !== 'inx2' && measurement.uom !== 'ftx2' && measurement.uom !== 'cmx2' && measurement.uom !== 'mx2' && measurement.uom !== 'yrdx2')
            || !measurement.quantity1) return [];

        let points = [
            [measurement.rectangle.x, measurement.rectangle.y],
            [measurement.rectangle.x + measurement.width, measurement.rectangle.y],
            [measurement.rectangle.x + measurement.width, measurement.rectangle.y + measurement.height],
            [measurement.rectangle.x, measurement.rectangle.y + measurement.height],
        ];

        if (measurement.offset) {
            points = [
                [measurement.rectangle.x + measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.offset / pages[pageID].scale],
                [measurement.rectangle.x + measurement.width - measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.offset / pages[pageID].scale],
                [measurement.rectangle.x + measurement.width - measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.height - measurement.offset / pages[pageID].scale],
                [measurement.rectangle.x + measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.height - measurement.offset / pages[pageID].scale],
            ];
        }

        let oc_lines = [];

        if (measurement.uom === 'inx2' || measurement.uom === 'ftx2' || measurement.uom === 'cmx2' || measurement.uom === 'mx2' || measurement.uom === 'yrdx2') {
            if (!measurement.quantity2 || measurement.quantity2 % 90 === 0) {
                oc_lines = gridPolygon(points, (measurement.quantity1 / 12) / pages[pageID].scale).concat(gridPolygonVertical(points, (measurement.quantity1 / 12) / pages[pageID].scale));
            } else {
                oc_lines = gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[pageID].scale, measurement.quantity2 * Math.PI / 180).concat(gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[pageID].scale, measurement.quantity2 * Math.PI / 180 + Math.PI / 2));
            }
        } else {
            if (!measurement.quantity2 || measurement.quantity2 % 180 === 0) {
                oc_lines = gridPolygon(points, (measurement.quantity1 / 12) / pages[pageID].scale);
            } else if (measurement.quantity2 % 90 === 0) {
                oc_lines = gridPolygonVertical(points, (measurement.quantity1 / 12) / pages[pageID].scale);
            } else {
                oc_lines = gridPolygonAngle(points, (measurement.quantity1 / 12) / pages[pageID].scale, measurement.quantity2 * Math.PI / 180);
            }
        }

        console.log(oc_lines?.length);
        if (oc_lines?.length > 500) return [];
        return oc_lines;
    }, [measurement, measurement.uom, measurement.quantity1, measurement.quantity2, measurement.offset]);


    const OCPoints = useMemo(() => {
        if (measurement.uom !== 'ea' || !measurement.quantity1) return [];

        let points = [
            [measurement.rectangle.x, measurement.rectangle.y],
            [measurement.rectangle.x + measurement.width, measurement.rectangle.y],
            [measurement.rectangle.x + measurement.width, measurement.rectangle.y + measurement.height],
            [measurement.rectangle.x, measurement.rectangle.y + measurement.height],
        ];

        if (measurement.offset) {
            points = [
                [measurement.rectangle.x + measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.offset / pages[pageID].scale],
                [measurement.rectangle.x + measurement.width - measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.offset / pages[pageID].scale],
                [measurement.rectangle.x + measurement.width - measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.height - measurement.offset / pages[pageID].scale],
                [measurement.rectangle.x + measurement.offset / pages[pageID].scale, measurement.rectangle.y + measurement.height - measurement.offset / pages[pageID].scale],
            ];
        }

        let oc_points = tilePolygon(points, (measurement.quantity1 / 12) / pages[pageID].scale);

        if (measurement.quantity2) {
            oc_points = tilePolygonTriangle(points, (measurement.quantity1 / 12) / pages[pageID].scale);
        }

        console.log(oc_points?.length);
        if (oc_points?.length > 10000) return [];
        return oc_points;
    }, [measurement, pages[pageID].scale, measurement.quantity1, measurement.quantity2, measurement.uom, measurement.offset]);

    return (
        <>
            <Html>
                {showContextMenu &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        {MergeablePolygon(measurements, selectedMeasurements, measurement.id) &&
                            <div
                                className="contextmenu-item"
                                onClick={() => handleCombineMeasurements()}
                            >
                                <IconLayersUnion size={16} stroke={1} />
                                <div>Combine</div>
                            </div>
                        }

                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Rename
                        </div>*/}

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                handleConvertPolygon(measurement);
                            }}
                        >
                            <IconPolygon size={16} stroke={1} />
                            <div>Polygon convert</div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                handleConvertLength(measurement);
                            }}
                        >
                            <IconTimeline size={16} stroke={1} />
                            <div>Length convert</div>
                        </div>

                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                handleTurnOffDrawing();
                                setCuttingRectangle(true);
                            }}
                        >
                            <CustomIcon
                                src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/cutpoly.svg'}
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    fill: 'black',
                                    stroke: 'black',
                                }}
                            />
                            <div>Cut w/ polygon</div>
                        </div>

                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                handleTurnOffDrawing();
                                setCuttingRectangleRect(true);
                            }}
                        >
                            <CustomIcon
                                src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/cutrect.svg'}
                                style={{
                                    width: '16px',
                                    height: '16px',
                                    fill: 'black',
                                    stroke: 'black',
                                }}
                            />
                            <div>Cut w/ rectangle</div>
                        </div>

                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                handleTurnOffDrawing();
                                setSplittingRectangle(true);
                            }}
                        >
                            <CustomIcon>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    width="16px"
                                    height="16px"
                                    viewBox="0 0 15 15"
                                    color={'black'}
                                    stroke='1'
                                    fill='none'
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <defs />
                                    <g>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="icon icon-tabler icon-tabler-rectangle"
                                            width="13"
                                            height="13"
                                            viewBox="0 0 22 22"
                                            stroke-width="1"
                                            stroke="currentColor"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                        </svg>
                                        <path
                                            d="M 13.64 13.64 L 0 0"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="0.6"
                                            stroke-miterlimit="10"
                                            pointer-events="stroke"
                                        />
                                    </g>
                                </svg>
                            </CustomIcon>
                            <div>Split</div>
                        </div>


                        {/*<div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`)?.dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Group
                        </div>
                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`)?.dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set depth
                        </div>
                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`)?.dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set pitch
                        </div>*/}

                        <div
                            className='contextmenu-item'
                            onClick={() => handleDuplicateMeasurement(measurement)}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>Duplicate</div>
                        </div>

                        <div
                            className='contextmenu-item'
                            onClick={() => handleZoomToMeasurement()}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>Zoom</div>
                        </div>
                        <div
                            className='contextmenu-item contextmenu-item-delete'
                            onClick={() => DeleteMeasurement(measurement)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>Delete</div>
                        </div>

                        {(new Set(selectedMeasurements)).size > 1 &&
                            <div
                                className="contextmenu-item contextmenu-item-delete"
                                onClick={() => {
                                    setShowDeleteMeasurementModal(true);
                                    setShowContextMenu(false);
                                }}
                            >
                                <IconTrashX size={16} stroke={1} />
                                <div>
                                    Delete Selected
                                </div>
                            </div>
                        }
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <Group
                    draggable={isSelected && !drawing}
                >
                    {measurement?.offset && Number(measurement?.offset) !== 0 &&
                        <Rect
                            x={measurement.rectangle.x + measurement.offset / pages[pageID].scale}
                            y={measurement.rectangle.y + measurement.offset / pages[pageID].scale}
                            width={measurement.width - 2 * measurement.offset / pages[pageID].scale}
                            height={measurement.height - 2 * measurement.offset / pages[pageID].scale}
                            strokeWidth={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 2 : 1}
                            stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                            closed={true}
                            fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}
                            opacity={isSelected || selectedMeasurements.find(m => m === measurement.id) ? 0.7 : 0.5}
                            strokeScaleEnabled={false}
                        />
                    }

                    {OCPoints?.map((point, index) => (
                        <Circle
                            key={index}
                            draggable={false}
                            x={point[0]}
                            y={point[1]}
                            radius={5}
                            fill={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                        />
                    ))}

                    {OCLines?.map((line, index) => (
                        <Line
                            key={index}
                            points={line}
                            stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                            strokeWidth={2 / pages[pageID].zoom}
                            opacity={isSelected || selectedMeasurements.find(m => m === measurement.id) ? 0.7 : 0.5}
                            lineJoin="round"
                            perfectDrawEnabled={false}
                        />
                    ))}

                    <Rect
                        ref={shapeRef}
                        onContextMenu={(e) => {
                            if (currentMeasurement !== measurement.id) {
                                //setSelectedMeasurements([measurement.id]);
                                setCurrentMeasurement(measurement.id);
                            }

                            e.evt.preventDefault();
                            setContextMenuPosition({
                                x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                            })
                            setShowContextMenu(true);
                        }}
                        x={measurement.rectangle.x}
                        y={measurement.rectangle.y}
                        width={measurement.width}
                        height={measurement.height}
                        rotation={measurement.rotation}
                        opacity={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 0.7 : 0.5}
                        fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}
                        strokeWidth={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 2 : 1}
                        stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                        shadowColor={pSBC(-0.25, measurement.color)}
                        //shadowBlur={isSelected ? 15 / pages[pageID].zoom : 0}

                        fillLinearGradientStartPoint={{ x: x1, y: y1 }}
                        fillLinearGradientEndPoint={{ x: x2, y: y2 }}
                        fillLinearGradientColorStops={colorPattern}

                        draggable={isSelected && !drawing}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragMove={(e) => {
                            const node = shapeRef.current;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();

                            node.scaleX(1);
                            node.scaleY(1);

                            updateMeasurementInState({
                                ...measurement,
                                width: Math.max(node.width() * scaleX),
                                height: Math.max(node.height() * scaleY),
                                area: measurement.offset
                                    ? Math.abs(node.width() * scaleX - 2 * measurement.offset / pages[pageID].scale) * Math.abs(node.height() * scaleY - 2 * measurement.offset / pages[pageID].scale)
                                    : Math.abs(node.width() * scaleX * node.height() * scaleY),
                                rectangle: {
                                    ...measurement.rectangle,
                                    x: node.x(),
                                    y: node.y()
                                },
                            });
                        }}
                        //onTransform={handleTransform}
                        onTransformEnd={handleTransformEnd}
                        strokeScaleEnabled={false}
                        onMouseEnter={(e) => {
                            if (isSelected) {
                                e.target.getStage().container().style.cursor = "pointer";
                            } else {
                                e.target.getStage().container().style.cursor = "default";
                            }
                        }}
                        onMouseDown={(e) => {
                            if (e.evt.button !== 0) return;

                            if (isSelected) {
                                e.target.getStage().container().style.cursor = "grabbing";
                            } else {
                                e.target.getStage().container().style.cursor = "default";
                            }

                            if (!e.evt.metaKey) {
                                setCurrentMeasurement(measurement.id);
                            }

                            if (optionDown) {
                                setTimeout(() => {
                                    const element = element = document.getElementById(`measurement-${measurement.id}-name`);
                                    if (element) element.click();
                                }, 100);
                            }
                        }}
                        onMouseLeave={(e) => {
                            e.target.getStage().container().style.cursor = "default";
                        }}
                        onMouseUp={(e) => {
                            if (e.evt.button !== 0) return;

                            if (e.evt.metaKey) {
                                if (selectedMeasurements.find(m => m === measurement.id)) {
                                    setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurement.id));
                                } else {
                                    setSelectedMeasurements([...selectedMeasurements, measurement.id]);
                                }
                            } else {
                                setSelectedMeasurements([measurement.id]);
                                setCurrentMeasurement(measurement.id);
                            }

                            if (!takeoffSettings.show_measurement_sidebar) {
                                handleChangeTakeoffSettings({
                                    ...takeoffSettings,
                                    show_measurement_sidebar: true,
                                    show_pages_sidebar: takeoffSettings.pages_sidebar_location === takeoffSettings.measurement_sidebar_location
                                        ? false : takeoffSettings.show_pages_sidebar,
                                    show_ai_sidebar: takeoffSettings.ai_sidebar_location === takeoffSettings.measurement_sidebar_location
                                        ? false : takeoffSettings.show_ai_sidebar,
                                })
                            }
                            setShowDetails(true);

                            setTimeout(() => {
                                const element = document.getElementById("measurement-" + measurement.id);
                                if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                                    element.scrollIntoView();
                                }
                            }, 100);

                            e.target.getStage().container().style.cursor = "pointer";
                        }}
                        perfectDrawEnabled={false}
                    />

                    {isSelected &&
                        <Transformer
                            ref={transformerRef}
                            enabledAnchors={[
                                'top-left', 'top-center', 'top-right',
                                'middle-left', 'middle-right',
                                'bottom-left', 'bottom-center', 'bottom-right',
                            ]}
                            flipEnabled={false}
                            ignoreStroke={true}
                            borderStrokeWidth={1}
                            borderStroke={pSBC(-0.8, measurement?.color)}
                            rotateEnabled={true}
                            anchorFill='white'
                            anchorStroke={pSBC(-0.8, measurement?.color)}
                            anchorSize={10}
                            anchorStrokeWidth={1}
                            opacity={0.7}
                            keepRatio={false}
                        />
                    }
                </Group>
            </Portal>
        </>
    );
};