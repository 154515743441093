import { useContext } from "react";

import { TakeoffContext } from "../../helper/Context";
import AnnotationText from "./AnnotationText";
import AnnotationArrow from "./AnnotationArrow";
import AnnotationRect from "./AnnotationRect";
import AnnotationRuler from "./AnnotationRuler";
import AnnotationCloud from "./AnnotationCloud";

export default function RenderAnnotations({ }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        pageID,
    } = useContext(TakeoffContext);

    return (
        <>
            {annotations && Object.values(annotations).filter(a => a.page == pageID).map(annotation => {
                if (annotation.type === 'text') return (
                    <AnnotationText
                        key={annotation.id}
                        annotation={annotation}
                    />
                )

                if (annotation.type === 'cloud') return (
                    <AnnotationCloud
                        key={annotation.id}
                        annotation={annotation}
                    />
                )

                if (annotation.type === 'arrow') return (
                    <AnnotationArrow
                        key={annotation.id}
                        annotation={annotation}
                    />
                )

                if (annotation.type === 'rectangle') return (
                    <AnnotationRect
                        key={annotation.id}
                        annotation={annotation}
                    />
                )

                if (annotation.type === 'ruler') return (
                    <AnnotationRuler
                        key={annotation.id}
                        annotation={annotation}
                    />
                )
            })}


        </>
    )
}

