var Offset = require('polygon-offset');
var offset = new Offset();

//adapted code from ray-casting algorithm, point is a diction with x and y, polygon is an arry of points
export function PointInsidePolygon(polygon, point) {
    let x = point.x, y = point.y;
    let vs = polygon;
    let inside = false;

    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i].x, yi = vs[i].y;
        let xj = vs[j].x, yj = vs[j].y;

        let intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
}

function approximatePolygonArcs(dots) {
    let approximated_polygon = [];

    for (let i = 0; i < dots.length; i++) {
        if (!("arc" in dots[i]) || !dots[i]["arc"]) {
            approximated_polygon.push(dots[i]);
        } else {
            let left_x = dots[(i - 1 + dots.length) % dots.length]["x"];
            let left_y = dots[(i - 1 + dots.length) % dots.length]["y"];

            if (dots[(i - 1 + dots.length) % dots.length]["arc"]) {
                left_x = (dots[(i - 1 + dots.length) % dots.length]["x"] + dots[i]["x"]) / 2;
                left_y = (dots[(i - 1 + dots.length) % dots.length]["y"] + dots[i]["y"]) / 2;
            }

            let right_x = dots[(i + 1) % dots.length]["x"];
            let right_y = dots[(i + 1) % dots.length]["y"];

            if (dots[(i + 1) % dots.length]["arc"]) {
                right_x = (dots[i]["x"] + dots[(i + 1) % dots.length]["x"]) / 2;
                right_y = (dots[i]["y"] + dots[(i + 1) % dots.length]["y"]) / 2;
            }

            for (let j = 0; j < 50; j++) {
                let t = j / 50;
                let x = (1 - t) ** 2 * left_x + 2 * (1 - t) * t * dots[i]["x"] + t ** 2 * right_x;
                let y = (1 - t) ** 2 * left_y + 2 * (1 - t) * t * dots[i]["y"] + t ** 2 * right_y;
                approximated_polygon.push({ "x": x, "y": y });
            }
        }
    }

    return approximated_polygon;
}

function polygonArea(dots) {
    let area = 0;
    let j = dots.length - 1;
    for (let i = 0; i < dots.length; i++) {
        area += (dots[j][0] + dots[i][0]) * (dots[j][1] - dots[i][1]);
        j = i;
    }
    return Math.abs(area / 2);
}

export function calculateOffsetPolygonArea(dots, subpolygons, offset_amount) {
    if (dots.some(dot => "arc" in dot && dot["arc"])) {
        dots = approximatePolygonArcs(dots)
    }

    let cords = dots.map(dot => [dot["x"], dot["y"]]);
    if (offset_amount) {
        cords = offset.data(cords).padding(offset_amount);
    }
    let area = polygonArea(cords);

    //for each subpolygon in polygon
    for (let i = 0; i < subpolygons.length; i++) {
        let subpolygon = subpolygons[i].polygon_dots;
        let subpolygon_cords = subpolygon.map(dot => [dot["x"], dot["y"]]);
        if (subpolygon.offset) {
            subpolygon_cords = offset.data(subpolygon_cords).offset(offset_amount);
        }
        area -= polygonArea(subpolygon_offset);
    }

    return area;
}

