import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arrow, Path, Arc } from "react-konva";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import pSBC from "../../helper/Colors";

export default function AnnotationCloudConstructor({ }) {
    const {
        pageID,
        pages,
        handleCreateAnnotation,
    } = useContext(TakeoffContext);

    const [points, setPoints] = useState([]);
    const [nextPoint, setNextPoint] = useState(null);

    const [isComplete, setIsComplete] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter' || e.key === 'Return') {
                if (points.length > 2) {
                    handleCreateAnnotation({
                        type: "cloud",
                        data: {
                            'size': 10,
                            'dots': [
                                ...points.map((point) => ({
                                    x: point.x,
                                    y: point.y
                                })),
                            ]
                        }
                    })
                    setNextPoint(null);
                    setPoints([]);
                    setIsComplete(false);
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [points, nextPoint]);


    function konvaSquigglePath(points, squiggleStep, squiggleAmplitude) {
        let path = ""
        //assemble the path for the entire array of dots
        for (let i = 0; i < points.length; i++) {
            if (i === 0) {
                path += "M" + points[i].x + "," + points[i].y;
            } else {
                path += "L" + points[i].x + "," + points[i].y;
            }
        }

        let p = new Konva.Path({ data: path }),
            pathLen = p.getLength(),
            numSteps = Math.round(pathLen / squiggleStep),
            pos = p.getPointAtLength(0),
            newPath = "M" + [pos.x, pos.y].join(','),
            side = -1;

        for (let i = 1; i <= numSteps; i++) {
            let last = pos;
            pos = p.getPointAtLength(i * pathLen / numSteps);

            let vector = { x: pos.x - last.x, y: pos.y - last.y };
            let vectorLen = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
            let half = { x: last.x + vector.x / 2, y: last.y + vector.y / 2 };
            let perpVector = { x: -(squiggleAmplitude * vector.y / vectorLen), y: (squiggleAmplitude * vector.x / vectorLen) };
            let controlPoint = { x: half.x + perpVector.x * side, y: half.y + perpVector.y * side };

            newPath += "Q" + [controlPoint.x, controlPoint.y, pos.x, pos.y].join(',');
            side = -side;
        }

        return newPath;
    }

    return (
        <>
            {points.length >= 2 && nextPoint && (
                <Path
                    data={konvaSquigglePath(points.concat(nextPoint), 10 / pages[pageID].zoom, 10 / pages[pageID].zoom)}
                    stroke="#006aff"
                    strokeWidth={3 / pages[pageID].zoom}
                    lineJoin="round"
                    closed={isComplete}
                    opacity={0.5}
                />
            )}

            {/*<Line
                strokeWidth={2 / pages[pageID].zoom}
                stroke="#006aff"
                opacity={0.5}
                lineJoin="round"
                closed={isComplete}
                points={points.flatMap((point) => [point.x, point.y]).concat(
                    nextPoint?.x
                        ? [nextPoint?.x, nextPoint?.y]
                        : []
                )}
            />*/}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;

                    if (!isComplete) {
                        if (nextPoint?.x && nextPoint?.y) {

                            setPoints(prev => prev.concat(nextPoint));
                        } else {
                            setPoints(prev => prev.concat({
                                x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                                y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                            }));
                        }
                    }
                }}
                onMouseMove={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    setNextPoint({ x: x, y: y });
                }}
            />

            {points[0] && !isComplete && (
                <PolygonOriginAnchor
                    point={{ x: points[0].x, y: points[0].y }}
                    onValidClick={() => {
                        handleCreateAnnotation({
                            type: "cloud",
                            data: {
                                'size': 10,
                                'dots': [
                                    ...points.map((point) => ({
                                        x: point.x,
                                        y: point.y
                                    })),
                                ]
                            }
                        })
                        setNextPoint(null);
                        setPoints([]);
                        setIsComplete(false);
                    }}
                    onValidMouseOver={() => {
                        setNextPoint(points[0]);
                    }}
                    validateMouseEvents={() => {
                        return points.length > 2;
                    }}
                />
            )}
        </>
    );
}

function PolygonOriginAnchor({
    point,
    onValidClick, onValidMouseOver,
    validateMouseEvents,
}) {
    const isValid = validateMouseEvents();
    const [fill, setFill] = useState("transparent");

    return (
        <Anchor
            point={point}
            fill={fill}
            onClick={() => {
                if (isValid) {
                    onValidClick();
                }
            }}
            onMouseOver={() => {
                if (isValid) {
                    document.body.style.cursor = "pointer";
                    setFill("green");
                    onValidMouseOver();
                } else {
                    document.body.style.cursor = "not-allowed";
                    setFill("red");
                }
            }}
            onMouseOut={() => {
                setFill("transparent");
            }}
        />
    );
}

function Anchor({
    point, fill,
    onClick, onMouseOver, onMouseOut
}) {
    const {
        pageID,
        pages,
    } = useContext(TakeoffContext);

    const [strokeWidth, setStrokeWidth] = useState(2);

    return (
        <Circle
            x={point.x}
            y={point.y}
            radius={10.0 / pages[pageID].zoom}
            stroke="#666"
            fill={fill}
            strokeWidth={strokeWidth / pages[pageID].zoom}
            onMouseOver={() => {
                document.body.style.cursor = "pointer";
                setStrokeWidth(3);
                onMouseOver();
            }}
            onMouseOut={() => {
                document.body.style.cursor = "default";
                setStrokeWidth(2);
                onMouseOut();
            }}
            onClick={(e) => {
                if (e.evt.button !== 0) return;

                document.body.style.cursor = "default";
                onClick(e);
            }}
        />
    );
}