import { WithContext as ReactTags } from 'react-tag-input';
import { map } from 'ramda';
import { IconPlus, IconX } from '@tabler/icons-react';

export const EstimateTagField = ({ tags = [], handleDeletion = () => {}, handleAddition = () => {} }) => {
  return (
    <div className='flex flex-row items-center w-full h-full gap-2 overflow-hidden'>
      <div className='h-full shrink-0 grow-0'>
        <button className='flex flex-row items-center h-full gap-1 p-2 text-xs rounded-lg text-gray-darkish bg-gray-revell' onClick={handleAddition}>
          <IconPlus stroke={1} />
        </button>
      </div>
      <div className='flex flex-row h-full gap-2 overflow-x-scroll overflow-y-hidden'>
        {map(({ id, text }) => (
          <div className='flex flex-row items-center h-full gap-1 p-2 text-xs text-white rounded-lg bg-blue-bobyard shrink-0 grow-0' key={id}>
            <span className='h-full overflow-hidden text-ellipsis'>{text}</span>
            <button onClick={() => handleDeletion(id)}>
              <IconX stroke={1} />
            </button>
          </div>
        ))(tags)}
      </div>
    </div>
  );
};
