import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    FolderTreeItemWrapper,
    SimpleTreeItemWrapper,
    SortableTree,
    flattenTree,
} from '../tree/index.js';

import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import { IconFolderPlus, IconFilter, IconFilterFilled, IconSquareX, IconChevronRightPipe, IconSearch, IconLayoutNavbarCollapse, IconCirclePlus2, IconCircleMinus2, IconCaretDownFilled, IconRulerMeasure, IconList, IconSortDescending, IconLayoutSidebarRightCollapse, IconLayoutSidebarLeftCollapse, IconChevronLeftPipe, IconLayoutNavbarExpand, IconFolderDown, IconLayoutGrid, IconRobot } from '@tabler/icons-react';

import { selectAuth } from '../../redux/slices/authSlice';
import { MeasurementSideBarContext, TakeoffContext } from '../helper/Context';

import { API_ROUTE } from '../..';

import '../styles/MeasurementSidebar.css';

import DefaultButton from '../../components/DefaultButton';
import ButtonHoverInfo from '../../components/ButtonHoverInfo';
import MeasurementDetail from './MeasurementDetail';
import DefaultIconButton from '../../components/DefaultIconButton';
import FilterSettings from './sidebar/FilterSettings';
import GroupMeasurements from './sidebar/Group';
import Measurement from './sidebar/Measurement';
import Group from './sidebar/Group';
import { DragOverlay } from '@dnd-kit/core';
import { deleteMeasurementFromTree } from '../helper/TreeFunctions';
import { Tooltip } from 'react-tooltip';
import { Blocks } from 'react-loader-spinner';
import SortSettings from './sidebar/SortSettings.js';
import GroupPlaceholder from './sidebar/GroupPlaceholder.js';
import Skeleton from 'react-loading-skeleton';
import { IconX } from '@tabler/icons-react';
import ImportGroupModal from './sidebar/ImportGroupModal.js';

import { UOM, UOM_Display, UOM_Measured_Default, calculateValue } from '../helper/UnitConversions';

export default function MeasurementSidebar({ sidebarWidth, setSidebarWidth }) {
    const auth = useSelector(selectAuth);

    const {
        projectUUID,
        project, setProject,
        pageID,
        groups, setGroups,
        measurements, setMeasurements,
        groupMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        currentMeasurement, setCurrentMeasurement,
        selectedDots,
        showDetails,
        search, setSearch,
        keybinds,
        tree, setTree,
        AIAutoFinding,
        hintingSAM,
        hintingLength,
        LengthAssignment,
        AIAutoCountPoly,
        hintingAutoMeasure,
        hintingAutoMeasurePerim,
        hintingAutoFindDW,
        shiftDown,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        AIAutoCountRect, setAIAutoCountRect,
        AIAutoMeasureLengthRect,
        AIAutoCountExample,
        selectingLegend,
        drawingLegend,
        drawingLegendEntry,
        drawingLegendEntryRun,
        setCurrentGroup,
    } = useContext(TakeoffContext);

    const [localSearch, setLocalSearch] = useState('');
    const [showSearch, setShowSearch] = useState(false);

    const [showNewGroup, setShowNewGroup] = useState(false);
    const [newGroup, setNewGroup] = useState('');
    const [creatingGroup, setCreatingGroup] = useState(false);
    const [showImportGroupModal, setShowImportGroupModal] = useState(false);

    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);

    const [showFilterSettings, setShowFilterSettings] = useState(false);
    const [showSortSettings, setShowSortSettings] = useState(false);

    const [draggingID, setDraggingID] = useState(null);
    const [overGroup, setOverGroup] = useState(null);

    const [hoveringGroup, setHoveringGroup] = useState(null);

    const [showDots, setShowDots] = useState({});
    const [draggingCount, setDraggingCount] = useState(false);

    const [stickyPath, setStickyPath] = useState([]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback((e) => {
        if (isResizing) {
            let temp;

            if (takeoffSettings?.measurement_sidebar_location === 'left') {
                temp = Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.18 * window.innerWidth), 0.30 * window.innerWidth)
            } else {
                temp = Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.18 * window.innerWidth), 0.30 * window.innerWidth);
            }

            handleChangeTakeoffSettings({
                ...takeoffSettings,
                measurement_sidebar_width: temp,
            })
        }

        setIsResizing(false);
    }, [isResizing, takeoffSettings]);

    const resize = useCallback((e) => {
        if (isResizing) {
            let newWidth = sidebarWidth;

            if (takeoffSettings?.measurement_sidebar_location === 'left') {
                newWidth = Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.18 * window.innerWidth), 0.30 * window.innerWidth);
            } else {
                newWidth = Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.18 * window.innerWidth), 0.30 * window.innerWidth);
            }

            setTakeoffSettings(prev => ({
                ...prev,
                measurement_sidebar_width: newWidth,
            }))
        }
    }, [isResizing]);

    const handleReset = () => {
        handleChangeTakeoffSettings({
            ...takeoffSettings,
            measurement_sidebar_width: window.innerWidth * 0.20,
        });

        setSidebarWidth('20%');
    }

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);

        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                setShowNewGroup(false);
                setNewGroup('');

                if (document.getElementById('search-measurements-input') === document.activeElement) {
                    document.activeElement.blur();
                }
            }

            if (e.key === 'Enter' && document.activeElement.tagName === 'INPUT' && !e.defaultPrevented) {
                setSearch(localSearch);
                //document.activeElement.blur();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }

    }, [newGroup, showNewGroup]);

    useEffect(() => {
        if (currentMeasurement && measurements[currentMeasurement]) {
            let measurement = measurements[currentMeasurement];

            let newTree = [...tree];
            let path = measurement.path.split('/').splice(0, -1);
            let cur = newTree;

            if (path.length > 1) {
                for (let i = 0; i < path.length - 1; i++) {
                    cur = cur.find(g => g.id === path[i]);
                    cur['collapsed'] = false;
                }
            }

            setTree(newTree);
        }
    }, [currentMeasurement]);

    const hanldeScroll = (e) => {
        //if we are scrolled all the way to the top
        if (e.target.scrollTop === 0) {
            setStickyPath([]);
            return;
        }

        let hiddenGroups = {};
        let first_visible_group = null;
        let first_visible_measurement = null;

        let top = e.target.getBoundingClientRect().top;

        //loop through all groups and check if they are in view
        groups && Object.keys(groups)?.sort((a, b) => groups[a].index - groups[b].index).forEach(g => {
            let group = document.getElementById(`group-${g}`);
            if (group) {
                let rect = group.getBoundingClientRect();

                //if the group is above the top of the sidebar
                if (rect.top < top + 32 * stickyPath.length) {
                    hiddenGroups[g] = true;

                    let index = -1;

                    for (let i = 0; i < stickyPath.length; i++) {
                        for (let j = 0; j < groups[g]?.path?.split('/').length - 1; j++) {
                            if (stickyPath[i] === groups[g]?.path?.split('/')[j]) {
                                index = i;
                                break;
                            }
                        }
                    }

                    if (index !== -1 || stickyPath.length === 0) {
                        if (rect.bottom > top + 32 * (stickyPath.length - index)) {
                            if (!first_visible_group) {
                                first_visible_group = groups[g].id;
                            }
                            hiddenGroups[g] = false;
                        }
                    }
                } else {
                    if (!first_visible_group) {
                        first_visible_group = groups[g].id;
                    }
                    hiddenGroups[g] = false;
                }
            }
        })

        //console.log(first_visible_group);

        Object.values(measurements).sort((a, b) => a.index - b.index).forEach(m => {
            let measurement = null;
            if (m.type === 'count') {
                measurement = document.getElementById(`measurement-${m.id}-count`);
            } else {
                measurement = document.getElementById(`measurement-${m.id}`);
            }

            if (measurement) {
                let rect = measurement.getBoundingClientRect();
                if (rect.bottom > top + 18 * stickyPath.length && !first_visible_measurement) {
                    first_visible_measurement = m.id;
                }
            }
        })

        //console.log(first_visible_measurement);

        let first = null;

        if (first_visible_group || first_visible_measurement) {
            if (first_visible_measurement && first_visible_group && measurements[first_visible_measurement].index < groups[first_visible_group].index) {
                first = measurements[first_visible_measurement].path;
            } else if (first_visible_group) {
                first = groups[first_visible_group].path;
            } else {
                first = measurements[first_visible_measurement].path;
            }
        }

        //console.log(first);
        setStickyPath(first?.split('/').filter(p => p).slice(0, -1) || []);
    };

    const handleSticky = (tree, hiddenGroups) => {
        if (!tree || tree.length === 0) {
            return [];
        }

        let last = null;
        let nextTree = null;

        for (let i = tree.length - 1; i >= 0; i--) {
            if (tree[i].id.includes('group') && hiddenGroups[tree[i].id.split('-')[1]] && !tree[i].collapsed) {
                last = tree[i].id;
                nextTree = tree[i].children
                break;
            }
        }

        if (!last) {
            return [];
        }

        return [last, ...handleSticky(nextTree, hiddenGroups)];
    }

    const createGroup = (name) => {
        setCreatingGroup(true);

        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/group/`,
            'data': {
                'project': project.id,
                'page': pageID,
                'name': name,
                'group': null,
                'color': `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setGroups(res.data.groups);
                //setMeasurements(res.data.measurements);
                setTree(res.data.tree);

                setNewGroup('');
                setShowNewGroup(false);
                setCreatingGroup(false);

                setCurrentGroup(res.data.group.id);

                //scroll to the new group
                setTimeout(() => {
                    let group = document.getElementById(`group-${res.data.group.id}`);
                    group?.scrollIntoView();
                }, 100);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleDragEnd = (result, type, draggedItem, draggedFromParent, droppedToParent) => {
        let sidebar = document.getElementsByClassName('measurementsidebar-groups-container')[0];
        sidebar.scrollLeft = 0;

        if (!draggedItem?.id?.includes('placeholder')) {
            if (type === 'expanded' || type === 'collapsed' || takeoffSettings?.sort === "custom" || (!draggedFromParent && droppedToParent) || (draggedFromParent && !droppedToParent) || (draggedFromParent && droppedToParent && draggedFromParent.id !== droppedToParent.id)) {
                setTree(result);

                if (type === 'dropped') {
                    axios({
                        method: 'PUT',
                        url: `${API_ROUTE}/api/takeoff-tree/`,
                        data: {
                            tree: result,
                            draggedItem: draggedItem,
                            selectedMeasurements: selectedMeasurements,
                            project: project.id,
                            contractor: auth.contractor.id,
                            page: pageID,
                        },
                        headers: {
                            'Authorization': `Token ${auth.token}`,
                            'Content-Type': 'application/json',
                        }
                    })
                        .then(res => {
                            console.log(res);

                            setMeasurements(res.data.measurements);
                            setGroups(res.data.groups);
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }
            }
        }
    }

    const handleDragCountEnd = (activeID, overID) => {
        setDraggingCount(false);

        let newMeasurements = { ...measurements };
        let tempSelectedDots = selectedDots.filter((d, i, self) => i === self.findIndex((t) => (t.id === d.id)));

        if (tempSelectedDots.some(d => String(d.id) === String(activeID.split('-')[1]))) {
            tempSelectedDots.forEach(d => {
                newMeasurements[activeID.split('-')[3]] = {
                    ...newMeasurements[activeID.split('-')[3]],
                    count_dots: newMeasurements[activeID.split('-')[3]].count_dots.filter(dot => String(dot.id) !== String(d.id)),
                    count: newMeasurements[activeID.split('-')[3]].count_dots.filter(dot => String(dot.id) !== String(d.id)).length,
                }
            })

            newMeasurements[overID.split('-')[1]] = {
                ...newMeasurements[overID.split('-')[1]],
                count_dots: [...newMeasurements[overID.split('-')[1]].count_dots, ...tempSelectedDots],
                count: [...newMeasurements[overID.split('-')[1]].count_dots, ...tempSelectedDots].length,
            }
        } else {
            let activeDot = newMeasurements[activeID.split('-')[3]].count_dots.find(dot => String(dot.id) === String(activeID.split('-')[1]));

            newMeasurements[activeID.split('-')[3]] = {
                ...newMeasurements[activeID.split('-')[3]],
                count_dots: newMeasurements[activeID.split('-')[3]].count_dots.filter(dot => String(dot.id) !== String(activeID.split('-')[1])),
                count: newMeasurements[activeID.split('-')[3]].count_dots.filter(dot => String(dot.id) !== String(activeID.split('-')[1])).length,
            }

            newMeasurements[overID.split('-')[1]] = {
                ...newMeasurements[overID.split('-')[1]],
                count_dots: [...newMeasurements[overID.split('-')[1]].count_dots, activeDot],
                count: [...newMeasurements[overID.split('-')[1]].count_dots, activeDot].length,
            }
        }

        setMeasurements(newMeasurements);

        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/change-dot-count/`,
            'data': {
                'dot': activeID.split('-')[1],
                'count': overID.split('-')[1],
                'selectedDots': selectedDots.some(d => String(d.id) === String(activeID.split('-')[1])) ? selectedDots.map(d => d.id) : [],
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);
                //setMeasurements(res.data.measurements);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const CountsVisible = useMemo(() => {
        let visible = false;

        const check = (group) => {
            if (!group.collapsed) {
                group.children.forEach(g => {
                    if (g.id.includes('group')) {
                        check(g);
                    } else if (g.id.includes('measurement') && measurements[g.id.split('-')[1]]?.type === 'count') {
                        visible = true;
                    }
                })
            }
        }

        tree.forEach(group => {
            if (group.id.includes('group')) {
                check(group);
            } else if (group.id.includes('measurement') && measurements[group.id.split('-')[1]]?.type === 'count') {
                visible = true;
            }
        })

        return visible;
    }, [tree, measurements]);

    const AIActive = useMemo(() => {
        return AIAutoFinding?.[pageID]?.show
            || hintingSAM
            || hintingLength
            || LengthAssignment
            || AIAutoCountRect?.[pageID]?.show
            || AIAutoCountPoly?.[pageID]?.show
            || AIAutoMeasureLengthRect?.[pageID]?.show
            || hintingAutoMeasure?.[pageID]?.show
            || hintingAutoMeasurePerim?.[pageID]?.show
            || hintingAutoFindDW?.[pageID]?.show
            || selectingLegend
            || drawingLegend
            || drawingLegendEntry
            || drawingLegendEntryRun
            || AIAutoCountExample?.[pageID]?.show;
    }, [pageID, AIAutoFinding, hintingSAM, hintingLength, LengthAssignment, AIAutoCountRect, AIAutoCountPoly, AIAutoMeasureLengthRect, hintingAutoMeasure, hintingAutoMeasurePerim, hintingAutoFindDW, selectingLegend, drawingLegend, drawingLegendEntry, drawingLegendEntryRun, AIAutoCountExample]);

    return (
        <MeasurementSideBarContext.Provider
            value={{
                draggingID, setDraggingID,
                overGroup, setOverGroup,
                sidebarWidth, setSidebarWidth,
                hoveringGroup, setHoveringGroup,
                createGroup,
                draggingCount, setDraggingCount,
                handleDragCountEnd,
                showDots, setShowDots,
            }}
        >
            <div className={takeoffSettings?.measurement_sidebar_location === 'left' ? "takeoffsidebar-pages-container" : "takeoffsidebar-container"}
                style={{
                    width: sidebarWidth,
                    boxShadow: AIActive && "0px 0px 5px 0px #006aff",
                    borderLeft: AIActive && takeoffSettings?.measurement_sidebar_location === 'right' && "1px solid #006aff",
                    borderRight: AIActive && takeoffSettings?.measurement_sidebar_location === 'left' && "1px solid #006aff",
                }}
                ref={sidebarRef}
                id='measurementsidebar'
            >
                <div
                    className={takeoffSettings?.measurement_sidebar_location === 'left'
                        ? "measurementsidebar-left-resizer"
                        : "measurementsidebar-resizer"}
                    id={'measurementsidebar-resizer'}
                    onMouseDown={startResizing}
                    onContextMenu={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setIsResizing(false);
                        setSidebarWidth('20%');
                        handleReset();
                    }}
                />

                <Tooltip
                    anchorSelect='#measurementsidebar-resizer'
                    delayShow={500}
                    positionStrategy='fixed'
                    style={{ zIndex: 99999 }}
                    place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}
                >
                    <div><b>Resize sidebar</b></div>
                    <div>Drag to resize</div>
                    <div>Right click to reset to default</div>
                </Tooltip>

                <div className='measurementsidebar-container'>
                    <div className="measurementsidebar-toggle">
                        {takeoffSettings?.ai_sidebar_location === takeoffSettings?.measurement_sidebar_location &&
                            <div
                                className='takeoffnavbar-toggle-button'
                                id='takeoffnavbar-settings-button-left-ai'
                                style={takeoffSettings?.show_ai_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                                onClick={() => {
                                    let newTakeoffSettings = { ...takeoffSettings };

                                    if (!newTakeoffSettings?.show_ai_sidebar) {
                                        newTakeoffSettings.show_ai_sidebar = true;

                                        if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                            newTakeoffSettings.show_measurement_sidebar = false
                                        }

                                        if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                            newTakeoffSettings.show_pages_sidebar = false
                                        }
                                    }
                                    handleChangeTakeoffSettings(newTakeoffSettings);
                                }}
                            >
                                <div><IconRobot size={20} stroke={1} /></div>
                                <div><nobr>AI tools</nobr></div>
                            </div>
                        }

                        <Tooltip delayShow={500} anchorSelect='#takeoffnavbar-settings-button-left-ai' place='top-start' positionStrategy='fixed' style={{ zIndex: 1000 }}>
                            Toggle AI sidebar ({keybinds?.ToggleAISidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleAISidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleAISidebar?.key})
                        </Tooltip>

                        {takeoffSettings?.pages_sidebar_location === takeoffSettings?.measurement_sidebar_location &&
                            <div
                                className='takeoffnavbar-toggle-button'
                                id='takeoffnavbar-pages-left-button'
                                style={takeoffSettings?.show_pages_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                                onClick={() => {
                                    let newTakeoffSettings = { ...takeoffSettings };

                                    if (!newTakeoffSettings?.show_pages_sidebar) {
                                        newTakeoffSettings.show_pages_sidebar = true;

                                        if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                            newTakeoffSettings.show_measurement_sidebar = false
                                        }

                                        if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                            newTakeoffSettings.show_ai_sidebar = false
                                        }
                                    }

                                    handleChangeTakeoffSettings(newTakeoffSettings);
                                }}

                            >
                                <div><IconLayoutGrid size={20} stroke={1} /></div>
                                <div>Pages</div>
                            </div>
                        }

                        <Tooltip delayShow={500} anchorSelect='#takeoffnavbar-pages-left-button' place='top-start' positionStrategy='fixed' style={{ zIndex: 1000 }}>
                            Toggle pages sidebar ({keybinds?.TogglePagesSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.TogglePagesSidebar?.shift ? 'Shift + ' : ''}{keybinds?.TogglePagesSidebar?.key})
                        </Tooltip>

                        <div
                            id='takeoffnavbar-measurements-button-left'
                            className='takeoffnavbar-toggle-button'
                            style={takeoffSettings?.show_measurement_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                            onClick={() => {
                                let newTakeoffSettings = { ...takeoffSettings };

                                if (!newTakeoffSettings?.show_measurement_sidebar) {
                                    newTakeoffSettings.show_measurement_sidebar = true;

                                    if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                        newTakeoffSettings.show_pages_sidebar = false
                                    }

                                    if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                        newTakeoffSettings.show_ai_sidebar = false
                                    }
                                }

                                handleChangeTakeoffSettings(newTakeoffSettings); takeoffSettings?.show_pages_sidebar
                            }}

                        >
                            <div><IconList size={20} stroke={1} /></div>
                            <div>Takeoffs</div>
                        </div>

                        <Tooltip delayShow={500} anchorSelect='#takeoffnavbar-measurements-button-left' place='top-start' positionStrategy='fixed' style={{ zIndex: 1000 }}>
                            Toggle measurements sidebar ({keybinds?.ToggleMeasurementSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleMeasurementSidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleMeasurementSidebar?.key})
                        </Tooltip>
                    </div>

                    <div className='measurementsidebar-top'>
                        <div className='measurementsidebar-header'>
                            <div className='measurementsidebar-header-buttons'>
                                <div
                                    className='measurementsidebar-header-button'
                                    id='measurementsidebar-header-button-add-group'
                                    style={{
                                        backgroundColor: showNewGroup && 'aliceblue',
                                    }}
                                    onClick={() => {
                                        if (!showNewGroup) {
                                            setCurrentMeasurement(null);
                                            setSelectedMeasurements([]);
                                            setShowNewGroup(true);
                                        } else {
                                            setShowNewGroup(false);
                                        }
                                    }}
                                >
                                    <IconFolderPlus
                                        size={20}
                                        stroke={1}
                                        style={{
                                            color: showNewGroup && '#006aff',
                                        }}
                                    />
                                </div>

                                <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-add-group' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                    <div><b>Add new group</b></div>
                                    <div>
                                        Type name for new group & press Enter
                                    </div>
                                </Tooltip>

                                <div
                                    className='measurementsidebar-header-button'
                                    id='measurementsidebar-header-button-import-group'
                                    style={{
                                        backgroundColor: showImportGroupModal && 'aliceblue',
                                    }}
                                    onClick={() => {
                                        setShowImportGroupModal(true);
                                    }}
                                >
                                    <IconFolderDown
                                        size={20}
                                        stroke={1}
                                        style={{
                                            color: showImportGroupModal && '#006aff',
                                        }}
                                    />
                                </div>

                                <ImportGroupModal
                                    showImportGroupModal={showImportGroupModal}
                                    setShowImportGroupModal={setShowImportGroupModal}
                                    projectUUID={projectUUID}
                                />

                                <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-import-group' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                    <div><b>Import groups</b></div>
                                    <div>
                                        Import groups from database
                                    </div>
                                </Tooltip>

                                <div
                                    className='measurementsidebar-header-button'
                                    id='measurementsidebar-header-button-search'
                                    style={{
                                        backgroundColor: showSearch && 'aliceblue',
                                    }}
                                    onClick={() => {
                                        if (!showSearch) {
                                            setSearch('');
                                            setLocalSearch('');
                                            setShowSearch(true);
                                            setCurrentMeasurement(null);
                                            setSelectedMeasurements([]);
                                        } else {
                                            setShowSearch(false);
                                            setSearch('');
                                            setLocalSearch('');
                                        }
                                    }}
                                >
                                    <IconSearch size={20} stroke={1} style={{ color: showSearch ? '#006aff' : null }} />
                                </div>

                                <div className='measurementsidebar-filter-container'>
                                    <div
                                        className='measurementsidebar-header-button'
                                        id='measurementsidebar-header-button-sort'
                                        onClick={() => setShowSortSettings(prev => !prev)}
                                        style={{
                                            backgroundColor: takeoffSettings?.sort !== 'custom' && 'aliceblue',
                                        }}
                                    >
                                        <IconSortDescending size={20} stroke={1} style={{ color: takeoffSettings?.sort !== 'custom' && '#006aff' }} />
                                    </div>

                                    <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-search' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                        Search measurements
                                    </Tooltip>

                                    {showSortSettings &&
                                        <SortSettings
                                            setShowSortSettings={setShowSortSettings}
                                        />
                                    }
                                </div>

                                <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-sort' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                    Sort items
                                </Tooltip>

                                <div className='measurementsidebar-filter-container'>
                                    <div
                                        className='measurementsidebar-header-button'
                                        id='measurementsidebar-header-button-filter'
                                        onClick={() => setShowFilterSettings(prev => !prev)}
                                        style={{
                                            backgroundColor: (project['include_all_pages'] || !project['include_count'] || !project['include_length'] || !project['include_area'] || !project['include_volume']) && 'aliceblue',
                                        }}
                                    >
                                        <IconFilter
                                            size={20}
                                            stroke={1}
                                            style={{
                                                color: (project['include_all_pages'] || !project['include_count'] || !project['include_length'] || !project['include_area'] || !project['include_volume']) && '#006aff',
                                            }}
                                        />
                                    </div>

                                    <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-filter' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                        Filter measurements
                                    </Tooltip>

                                    {showFilterSettings &&
                                        <FilterSettings
                                            setShowFilterSettings={setShowFilterSettings}
                                        />
                                    }
                                </div>

                                <div
                                    className={'measurementsidebar-header-button ' + (!CountsVisible && 'measurementsidebar-header-button-disabled')}
                                    id='measurementsidebar-header-button-toggle-dots'
                                    onClick={() => {
                                        document.getElementById('measurementsidebar-header-button-toggle-dots').dispatchEvent(new MouseEvent('mouseleave'));

                                        if (CountsVisible) {
                                            if (Object.values(showDots).some(d => d)) {
                                                let newShowDots = {};
                                                Object.keys(measurements).filter(m => measurements[m].type === 'count').forEach(m => {
                                                    newShowDots[m] = false;
                                                })
                                                setShowDots(newShowDots);
                                            } else {
                                                let newShowDots = {};
                                                Object.keys(measurements).filter(m => measurements[m].type === 'count').forEach(m => {
                                                    newShowDots[m] = true;
                                                })
                                                setShowDots(newShowDots);
                                            }
                                        }
                                    }}
                                >
                                    {Object.values(showDots).some(d => d)
                                        ? <IconCircleMinus2
                                            size={20}
                                            stroke={1}
                                            style={{
                                                color: CountsVisible ? 'black' : 'silver',
                                            }}
                                        />
                                        : <IconCirclePlus2
                                            size={20}
                                            stroke={1}
                                            style={{
                                                color: CountsVisible ? 'black' : 'silver',
                                            }}
                                        />
                                    }
                                </div>

                                <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-toggle-dots' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                    {CountsVisible
                                        ? Object.values(showDots).some(d => d) ? 'Collapse all visible counts' : 'Expand all visible counts'
                                        : 'No counts visible'
                                    }
                                </Tooltip>

                                <div
                                    className='measurementsidebar-header-button'
                                    id='measurementsidebar-header-button-collapse-all'
                                    onClick={() => {
                                        document.getElementById('measurementsidebar-header-button-collapse-all').dispatchEvent(new MouseEvent('mouseleave'));

                                        let disabled = Object.values(tree).filter(g => g.id.includes('group') && !g.collapsed).length === 0;

                                        if (!disabled) {
                                            setCurrentMeasurement(null);
                                            setTree(prev => {
                                                let newTree = [...prev];

                                                const collapse = (group) => {
                                                    group['collapsed'] = true;
                                                    group.children.forEach(g => {
                                                        if (g.id.includes('group')) {
                                                            collapse(g);
                                                        }
                                                    })
                                                }

                                                newTree.forEach(group => {
                                                    if (group.id.includes('group')) {
                                                        collapse(group);
                                                    }
                                                })
                                                return newTree;
                                            })

                                            axios({
                                                'method': 'post',
                                                'url': `${API_ROUTE}/api/collapse-all-groups/`,
                                                'data': {
                                                    'project': project.id,
                                                    'contractor': auth.contractor.id,
                                                },
                                                'headers': {
                                                    'Authorization': `Token ${auth.token}`,
                                                    'Content-Type': 'application/json',
                                                }
                                            })
                                                .then((res) => {
                                                    console.log(res);
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                })
                                        } else {
                                            setTree(prev => {
                                                let newTree = [...prev];

                                                const collapse = (group) => {
                                                    group['collapsed'] = false;
                                                    group.children.forEach(g => {
                                                        if (g.id.includes('group')) {
                                                            collapse(g);
                                                        }
                                                    })
                                                }

                                                newTree.forEach(group => {
                                                    if (group.id.includes('group')) {
                                                        collapse(group);
                                                    }
                                                })
                                                return newTree;
                                            })

                                            axios({
                                                'method': 'post',
                                                'url': `${API_ROUTE}/api/expand-all-groups/`,
                                                'data': {
                                                    'project': project.id,
                                                    'contractor': auth.contractor.id,
                                                },
                                                'headers': {
                                                    'Authorization': `Token ${auth.token}`,
                                                    'Content-Type': 'application/json',
                                                }
                                            })
                                                .then((res) => {
                                                    console.log(res);
                                                })
                                                .catch((err) => {
                                                    console.log(err);
                                                })
                                        }
                                    }}
                                >
                                    {Object.values(tree).filter(g => g.id.includes('group') && !g.collapsed).length === 0
                                        ? <IconLayoutNavbarExpand size={20} stroke={1} />
                                        : <IconLayoutNavbarCollapse size={20} stroke={1} />
                                    }
                                </div>

                                <Tooltip className='tooltip' anchorSelect='#measurementsidebar-header-button-collapse-all' delayShow={500} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'} style={{ zIndex: 1000 }} positionStrategy='fixed'>
                                    {Object.values(tree).filter(g => g.id.includes('group') && !g.collapsed).length === 0 ? 'Expand all groups' : 'Collapse all groups'}
                                </Tooltip>
                            </div>

                            <div className='measurementsidebar-header-buttons'>
                                <div
                                    className="measurementsidebar-header-button"
                                    id="pagessidebar-move-sidebar-button"
                                    onClick={() => {
                                        handleChangeTakeoffSettings({
                                            ...takeoffSettings,
                                            show_measurement_sidebar: true,
                                            measurement_sidebar_location: takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left',
                                            show_ai_sidebar: takeoffSettings?.ai_sidebar_location !== takeoffSettings?.measurement_sidebar_location ? false : takeoffSettings?.show_ai_sidebar,
                                            show_pages_sidebar: takeoffSettings?.pages_sidebar_location !== takeoffSettings?.measurement_sidebar_location ? false : takeoffSettings?.show_pages_sidebar,
                                        })

                                        document.getElementById('pagessidebar-move-sidebar-button').dispatchEvent(new MouseEvent('mouseleave'));
                                    }}
                                >
                                    {takeoffSettings?.measurement_sidebar_location === 'left'
                                        ? <IconChevronRightPipe size={20} stroke={1} />
                                        : <IconChevronLeftPipe size={20} stroke={1} />
                                    }
                                </div>

                                <Tooltip
                                    className='tooltip'
                                    anchorSelect="#pagessidebar-move-sidebar-button"
                                    delayShow={500}
                                    place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}
                                    positionStrategy='fixed'
                                    style={{ zIndex: 1000 }}
                                >
                                    {takeoffSettings?.measurement_sidebar_location === 'left' ? "Move sidebar to the right" : "Move sidebar to the left"}
                                </Tooltip>

                                <div
                                    className='measurementsidebar-header-button'
                                    id='measurementsidebar-header-button-close'
                                    onClick={() => {
                                        handleChangeTakeoffSettings({
                                            ...takeoffSettings,
                                            show_measurement_sidebar: false,
                                        })
                                    }}
                                >
                                    <IconX size={20} stroke={1} />
                                </div>

                                <Tooltip
                                    className='tooltip'
                                    anchorSelect='#measurementsidebar-header-button-close'
                                    delayShow={500}
                                    place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom-start' : 'bottom-end'}
                                    positionStrategy='fixed'
                                    style={{ zIndex: 1000 }}
                                >
                                    Hide measurements ({keybinds?.ToggleMeasurementSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleMeasurementSidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleMeasurementSidebar?.key})
                                </Tooltip>
                            </div>
                        </div>

                        {(showSearch || showNewGroup) &&
                            <div className='measurementsidebar-header-input-container'>
                                {showSearch &&
                                    <div className='measurementsidebar-search-container'>
                                        <IconSearch size={20} stroke={1} />
                                        <input
                                            className='measurementsidebar-group-title-input'
                                            id='search-measurements-input'
                                            placeholder=' Search measurements...'
                                            type='text'
                                            value={localSearch}
                                            onChange={(e) => setLocalSearch(e.target.value)}
                                            onBlur={() => setSearch(localSearch)}
                                            autoFocus
                                        />
                                    </div>
                                }

                                {showNewGroup &&
                                    <div className='measurementsidebar-newgroup-container'>
                                        <IconFolderPlus size={20} stroke={1} />
                                        <input
                                            className='measurementsidebar-group-title-input'
                                            style={{ width: creatingGroup && '80%' }}
                                            placeholder=' New group name...'
                                            type='text'
                                            autoFocus
                                            value={newGroup}
                                            onChange={(e) => setNewGroup(e.target.value)}
                                            onBlur={() => {
                                                if (newGroup) {
                                                    createGroup(newGroup);
                                                } else {
                                                    setTimeout(() => {
                                                        setShowNewGroup(false);
                                                    }, 100);
                                                }
                                            }}
                                        />

                                        {creatingGroup &&
                                            <Blocks
                                                visible={true}
                                                height="16"
                                                width="16"
                                                color="#006AFE"
                                                ariaLabel="blocks-loading"
                                                radius="8"
                                                wrapperClass="blocks-wrapper"
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {!isResizing ?
                        <>
                            <div className='measurementsidebar-sticky'>
                                {stickyPath.length > 0 &&
                                    <Sticky
                                        stickyPath={stickyPath}
                                    />
                                }
                            </div>

                            <div
                                className='measurementsidebar-groups-container'
                                id='measurementsidebar-groups-container'
                                onScroll={(e) => {
                                    hanldeScroll(e);
                                }}
                            >
                                <SortableTree
                                    items={tree}
                                    onItemsChanged={(e, {
                                        type, draggedItem, draggedFromParent, droppedToParent
                                    }) => {
                                        handleDragEnd(e, type, draggedItem, draggedFromParent, droppedToParent);
                                    }}
                                    keepGhostInPlace={takeoffSettings?.sort !== 'custom'}
                                    TreeItemComponent={TreeItem}
                                    pointerSensorOptions={{
                                        'activationConstraint': {
                                            'distance': 10,
                                        }
                                    }}
                                    dndContextProps={{
                                        /*onDragStart: (event) => {
                                            console.log(event);
                                            setDraggingID(event.active.id);
                                        }*/
                                    }}
                                    disableTree={draggingID?.includes('dot')}
                                    //custom sort so that folders are always on top
                                    sortableProps={{
                                        strategy: verticalListSortingStrategy,
                                        sortingStrategy: verticalListSortingStrategy,
                                        data: {
                                            accepts: ['group', 'measurement'],
                                        },
                                        //keyboardCoordinates: sortableKeyboardCoordinates,
                                    }}
                                />
                                {/*<DragOverlay>
                            {draggingID ? <div>
                                {draggingID}
                            </div> : null}
                            </DragOverlay>*/}
                            </div>

                            {currentMeasurement && showDetails &&
                                <MeasurementDetail />
                            }
                        </>
                        : <div className='measurementsidebar-resizing-body'>
                            <Skeleton height={30} />

                            <br></br>
                            <Skeleton count={1.8} />

                            <br></br>
                            <Skeleton count={5} />

                            <br></br>
                            <Skeleton count={10.9} />

                            <br></br>
                            <Skeleton count={9.6} />
                        </div>
                    }
                </div>
            </div>
        </MeasurementSideBarContext.Provider >
    )
}

const TreeItem = React.forwardRef((props, ref) => {
    const {
        measurements, groups,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [showModal, setShowModal] = useState(false);

    return (
        <FolderTreeItemWrapper
            {...props} ref={ref}
            showDragHandle={false}
            hideCollapseButton={true}
            className='measurementsidebar-treeitem'
            contentClassName='measurementsidebar-treeitem-content'
            disableSelection={showModal || props.item?.id?.includes('placeholder')}
            disableInteraction={showModal}
            disableSorting={showModal || takeoffSettings?.sort !== "custom" || props.item?.id?.includes('placeholder')}
            measurementModal={showModal}
            manualDrag={showModal}
        >
            {props.item?.id?.includes('group') && <Group group={groups[props.item.id.split('-')[1]]} collapsed={props.collapsed} item={props.item} showModal={showModal} setShowModal={setShowModal} takeoffSettings={takeoffSettings} />}
            {props.item?.id?.includes('measurement') && <Measurement measurement={measurements[props.item.id.split('-')[1]]} showModal={showModal} setShowModal={setShowModal} />}
            {props.item?.id?.includes('placeholder') && <GroupPlaceholder data={props.item} />}
        </FolderTreeItemWrapper>
    );
});

/*
{props.item?.id?.includes('group') && <Group group={groups[props.item.id.split('-')[1]]} collapsed={props.item.collapsed} item={props.item} />}
            {props.item?.id?.includes('measurement') && <Measurement measurement={measurements[props.item.id.split('-')[1]]} showModal={showModal} setShowModal={setShowModal} />}
                        {props.item?.id?.includes('placeholder') && }

*/

const Sticky = ({ stickyPath }) => {
    return (
        <div className="measurementsidebar-sticky-container">
            {stickyPath.map((g, i) =>
                <StickyItem
                    key={i}
                    g={g}
                    i={i}
                />
            )}
        </div>
    );
}


const StickyItem = ({ g, i }) => {
    const {
        measurements,
        currentMeasurement,
        groups,
        pages,
    } = useContext(TakeoffContext);

    const {
        draggingID,
        overGroup,
        setHoveringGroup,
        sidebarWidth,
    } = useContext(MeasurementSideBarContext);

    let group = groups[g.split('-')[1]];

    if (!group) return null;

    const most_common_uom = useMemo(() => {
        //loop through all measurements and add their uom to set
        let uoms = {}
        measurements && Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom) {
                if (!uoms[m?.uom]) uoms[m?.uom] = 0
                uoms[m?.uom] += 1
            } else {
                if (!uoms[UOM_Measured_Default[m?.type]]) uoms[UOM_Measured_Default[m?.type]] = 0
                uoms[UOM_Measured_Default[m?.type]] += 1
            }
        })

        //console.log(uoms)

        if (Object.keys(uoms).length === 0) return []

        //return the 1 uom that appears the most
        return Object.keys(uoms)?.reduce((a, b) => uoms[a] > uoms[b] ? a : b)
    }, [measurements, group])

    const get_uom_total = (uom) => {
        let total = 0
        measurements && Object.values(measurements).filter(m => m?.path?.includes('group-' + group.id)).forEach(m => {
            if (m?.uom === uom || !m?.uom && UOM_Measured_Default[m?.type] === uom) {
                total += calculateValue(m, pages[m?.page]?.scale)
            }
        })

        return total
    }

    return (
        <div
            className='measurementsidebar-sticky-item'
            onClick={() => {
                let groupElement = document.getElementById(`group-${group.id}`);
                let groupsContainer = document.getElementById('measurementsidebar-groups-container');

                if (groupElement && groupsContainer) {
                    groupElement.scrollIntoView({ behavior: 'instant', block: 'start' });

                    setTimeout(() => {
                        groupsContainer.scrollBy({
                            top: -32 * i - 5,
                            behavior: 'smooth'
                        });
                    }, 50);
                }
            }}
        >
            {Array.from({ length: i }).map((_, i) => (
                <div
                    key={i}
                    className='measurementsidebar-sticky-item-placeholder'
                >
                    &nbsp;
                </div>
            ))}

            <div className='measurementsidebar-sticky-item-color' >
                <IconCaretDownFilled style={{ color: group?.color }} size={21} stroke={1} />
            </div>

            <div
                className='measurementsidebar-sticky-item-name'
                style={{
                    width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (group?.path?.split('/').length - 2) * 37 - 37 - 120 - 32 - 20 - 10 + 'px',
                    maxWidth: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (group?.path?.split('/').length - 2) * 37 - 37 - 120 - 32 - 20 - 10 + 'px',
                }}
            >
                {group?.name?.length > 20 ? group?.name?.slice(0, 20) + '...' : group?.name}
            </div>

            <div className='measurementsidebar-sticky-item-value'
                style={{
                    color: group.hide && 'lightgray'
                }}
            >
                {group.uom && get_uom_total(group.uom)
                    ? <nobr>
                        {group.uom === 'ea' ? get_uom_total(group.uom).toFixed(0) : get_uom_total(group.uom).toFixed(2)}
                    </nobr>
                    : <nobr>
                        {most_common_uom === 'ea' ? get_uom_total(most_common_uom).toFixed(0) : get_uom_total(most_common_uom).toFixed(2)}
                    </nobr>
                }
            </div>

            <div className='measurementsidebar-sticky-item-uom-abbr'>
                {group.uom && get_uom_total(group.uom)
                    ? <nobr>
                        {UOM_Display[group.uom]}
                    </nobr>
                    : <nobr>
                        {UOM_Display[most_common_uom]}
                    </nobr>
                }
            </div>
        </div >
    )
}