import React, { useContext, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle, RegularPolygon, Star } from "react-konva";
import { Html, Portal } from "react-konva-utils";
import axios from "axios";
import { v4 } from "uuid";

import { TakeoffContext } from "../../helper/Context";
import ContextMenu from "../../../components/ContextMenu";
import pSBC, { invertColor } from "../../helper/Colors";
import { IconCircleArrowUpRight, IconCircleMinus, IconCirclePlus, IconCopy, IconEraser, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Count({ measurement }) {
    const {
        project, setProject,
        pageID,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        currentDot, setCurrentDot,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        addingCount, setAddingCount,
        deletingCount, setDeletingCount,
        handleCountTransform,
        DeleteMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        setShowDetails,
        optionDown,
        stopWatch,
        handleZoomToDot,
        handleDuplicateMeasurement,
        setShowDeleteMeasurementModal,
    } = useContext(TakeoffContext);

    const isSelected = currentMeasurement == measurement.id;

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    return (
        <>
            <Html>
                {showContextMenu &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Rename
                        </div>*/}

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                if (document.getElementById(`measurement-dot-${currentDot.id}-body`)) {
                                    document.getElementById(`measurement-dot-${currentDot.id}-body`).scrollIntoView();
                                    document.getElementById(`measurement-dot-${currentDot.id}-body`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                                } else {
                                    document.getElementById(`measurement-toggle-dot-${measurement.id}`).dispatchEvent(new MouseEvent('click', { bubbles: true }));

                                    setTimeout(() => {
                                        document.getElementById(`measurement-dot-${currentDot.id}-body`).scrollIntoView();
                                        document.getElementById(`measurement-dot-${currentDot.id}-body`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                                    }, 100);
                                }
                            }}
                        >
                            <IconCircleArrowUpRight size={16} stroke={1} />
                            <div>
                                Change count
                            </div>
                        </div>

                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Group
                        </div>*/}
                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                setAddingCount(true);
                            }}
                        >
                            <IconCirclePlus size={16} stroke={1} />
                            <div>Add dots</div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                handleDuplicateMeasurement(measurement);
                            }}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>Duplicate</div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToMeasurement()}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>Zoom</div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                handleZoomToDot(currentDot);
                            }}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom to dot
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => {
                                const newMeasurement = {
                                    ...measurement,
                                    count: measurement.count_dots.length - 1,
                                    count_dots: measurement.count_dots.filter(d => d.id !== currentDot.id),
                                }

                                handleCountTransform(newMeasurement, false, 'delete', currentDot.id);
                                setCurrentDot(null);
                            }}
                        >
                            <IconCircleMinus size={16} stroke={1} />
                            <div>Delete dot</div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => setDeletingCount(true)}
                        >
                            <IconEraser size={16} stroke={1} />
                            <div>Delete dots</div>
                        </div>
                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => DeleteMeasurement(measurement)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>Delete</div>
                        </div>

                        {(new Set(selectedMeasurements)).size > 1 &&
                            <div
                                className="contextmenu-item contextmenu-item-delete"
                                onClick={() => {
                                    setShowDeleteMeasurementModal(true);
                                    setShowContextMenu(false);
                                }}
                            >
                                <IconTrashX size={16} stroke={1} />
                                <div>
                                    Delete Selected
                                </div>
                            </div>
                        }
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                {deletingCount && isSelected &&
                    <Rect
                        x={0}
                        y={0}
                        width={pages[pageID].width}
                        height={pages[pageID].height}
                        perfectDrawEnabled={false}
                    //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count.svg') 2 18, auto"}
                    //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                    />
                }

                {measurement?.count_dots?.map((dot) =>
                    <CountDot
                        key={dot.id}
                        dot={dot}
                        measurement={measurement}
                        isSelected={isSelected}
                        setShowContextMenu={setShowContextMenu}
                        setContextMenuPosition={setContextMenuPosition}
                    />
                )}

                {measurement?.ai_count_example &&
                    <ExampleDot
                        measurement={measurement}
                    />
                }

                {addingCount && isSelected &&
                    <Rect
                        x={0}
                        y={0}
                        width={pages[pageID].width}
                        height={pages[pageID].height}
                        //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/add+count.svg') 8 24, auto"}
                        //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                        onClick={(e) => {
                            if (e.evt.button !== 0) return;

                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                            let tempID = v4();

                            const newMeasurement = {
                                ...measurement,
                                count_dots: measurement.count_dots.concat({
                                    x: x,
                                    y: y,
                                    measurement: measurement.id,
                                    tempID: tempID,
                                }),
                                count: measurement.count_dots.length + 1,
                            }

                            handleCountTransform(newMeasurement, false, 'add', tempID = tempID);
                        }}
                        perfectDrawEnabled={false}
                    />
                }
            </Portal>
        </>
    );
}

function ExampleDot({ measurement }) {
    const {
        project, setProject,
        pageID,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        currentDot, setCurrentDot,
        currentMeasurement, setCurrentMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        addingCount, setAddingCount,
        deletingCount, setDeletingCount,
        setShowDetails,
    } = useContext(TakeoffContext);

    let x = (measurement.ai_count_example.x1 - measurement.ai_count_example.x2 / 2) - 5 / pages[pageID].zoom;
    let y = (measurement.ai_count_example.y1 - measurement.ai_count_example.y2 / 2) - 5 / pages[pageID].zoom;

    const isSelected = false;

    const strokeWidth = currentMeasurement === measurement.id || selectedMeasurements.find(m => m === measurement.id)
        ? 3 / pages[pageID].zoom
        : 1 / pages[pageID].zoom;

    const stroke = "rgba(255, 0, 0, 0.0)";

    //const shadowBlur = isSelected && !draggingPage ? 15 / pages[pageID].zoom : 0;
    const shadowBlur = 0;

    //const shadowColor = (currentDot?.id === dot.id) ? pSBC(-0.8, measurement?.color) : pSBC(-0.25, measurement.color);
    const shadowColor = null;

    const fill = measurement?.color ? measurement.color : "rgba(255, 0, 0, 0.5)";

    const draggable = false;

    const onClick = (e) => {
        if (e.evt.button !== 0) return;

        if (e.evt.metaKey) {
            if (selectedMeasurements.find(m => m === measurement.id)) {
                setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurement.id));
            } else {
                setSelectedMeasurements([...selectedMeasurements, measurement.id]);
            }
        } else {
            setSelectedMeasurements([measurement.id]);
            setCurrentMeasurement(measurement.id);
        }

        if (!takeoffSettings.show_measurement_sidebar) {
            handleChangeTakeoffSettings({
                ...takeoffSettings,
                show_measurement_sidebar: true,
                show_pages_sidebar: takeoffSettings.pages_sidebar_location === takeoffSettings.measurement_sidebar_location
                    ? false : takeoffSettings.show_pages_sidebar,
                show_ai_sidebar: takeoffSettings.ai_sidebar_location === takeoffSettings.measurement_sidebar_location
                    ? false : takeoffSettings.show_ai_sidebar,
            })
        }
        setShowDetails(true);

        setTimeout(() => {
            const element = document.getElementById("measurement-" + measurement.id);
            if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                element.scrollIntoView();
            }
        }, 100);
    }

    function getInnerSymbol() {
        if (measurement?.shape === 'square') {
            return (
                <Rect
                    x={x}
                    y={y}
                    width={measurement?.size ? measurement.size / pages[pageID].zoom : 5 / pages[pageID].zoom}
                    height={measurement?.size ? measurement.size / pages[pageID].zoom : 5 / pages[pageID].zoom}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'circle') {
            return (
                <Circle
                    x={x}
                    y={y}
                    radius={measurement?.size ? measurement.size / pages[pageID].zoom : 5 / pages[pageID].zoom}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'diamond') {
            return (
                <Group
                    x={x}
                    y={y}
                    draggable={draggable}
                >
                    <KonvaLine
                        points={[0, measurementSize, measurementSize, 0, 0, -measurementSize, -measurementSize, 0, 0, measurementSize]}
                        strokeWidth={strokeWidth}
                        stroke={stroke}
                        fill={fill}
                        closed
                        perfectDrawEnabled={false}
                    />
                </Group>
            )
        } else if (measurement?.shape === 'triangle') {
            return (
                <RegularPolygon
                    x={x}
                    y={y}
                    sides={3}
                    radius={measurementSize}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'pentagon') {
            return (
                <RegularPolygon
                    x={x}
                    y={y}
                    sides={5}
                    radius={measurementSize}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'hexagon') {
            return (
                <RegularPolygon
                    x={x}
                    y={y}
                    sides={6}
                    radius={measurementSize}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'octagon') {
            return (
                <RegularPolygon
                    x={x}
                    y={y}
                    sides={8}
                    radius={measurementSize}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'star') {
            return (
                <Star
                    x={x}
                    y={y}
                    numPoints={5}
                    innerRadius={measurementSize / 2}
                    outerRadius={measurementSize}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    draggable={draggable}
                    perfectDrawEnabled={false}
                />
            )
        } else if (measurement?.shape === 'cross') {
            return (
                <Group
                    x={x}
                    y={y}
                >
                    <KonvaLine
                        points={[-measurementSize, -measurementSize, measurementSize, measurementSize, 0, 0, measurementSize, -measurementSize, -measurementSize, measurementSize, 0, 0, -measurementSize, -measurementSize, measurementSize]}
                        strokeWidth={strokeWidth + measurementSize / 2}
                        stroke={fill}
                        fill={fill}
                        perfectDrawEnabled={false}
                    />
                </Group>
            )
        }
    }

    return (
        <Group
            onClick={onClick}
        >
            <Rect
                x={measurement.ai_count_example.x1 - 5 / pages[pageID].zoom}
                y={measurement.ai_count_example.y1 - 5 / pages[pageID].zoom}
                width={(measurement.ai_count_example.x2 - measurement.ai_count_example.x1) + 10 / pages[pageID].zoom}
                height={(measurement.ai_count_example.y2 - measurement.ai_count_example.y1) + 10 / pages[pageID].zoom}
                cornerRadius={3 / pages[pageID].zoom}
                stroke={fill}
                strokeWidth={strokeWidth}
                perfectDrawEnabled={false}
            />
            {/*getInnerSymbol()*/}
        </Group>
    )
}

function CountDot({ dot, measurement, isSelected, setShowContextMenu, setContextMenuPosition }) {
    const {
        project, setProject,
        pageID,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        currentDot, setCurrentDot,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        addingCount, setAddingCount,
        deletingCount, setDeletingCount,
        handleCountTransform,
        DeleteMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        setShowDetails,
        optionDown,
        draggingPage,
    } = useContext(TakeoffContext);

    const contextMenu = (e) => {
        e.evt.preventDefault();
        setCurrentDot(dot);
        setContextMenuPosition({
            x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
            y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
        })
        setShowContextMenu(true);
        setCurrentMeasurement(measurement.id);
        if (!selectedMeasurements.includes(measurement.id)) {
            setSelectedMeasurements([measurement.id]);
        }
        if (!takeoffSettings.show_measurement_sidebar) {
            handleChangeTakeoffSettings({
                ...takeoffSettings,
                show_measurement_sidebar: true,
            })
        }
    }

    const strokeWidth = (isSelected || selectedMeasurements.find(m => m === measurement.id))
        ? currentDot?.id === dot.id
            ? 4 / pages[pageID].zoom
            : 3 / pages[pageID].zoom
        : 0;

    const stroke = (isSelected || selectedMeasurements.find(m => m === measurement.id))
        ? (currentDot?.id === dot.id)
            ? pSBC(-0.95, measurement?.color)
            : pSBC(-0.8, measurement?.color)
        : "rgba(255, 0, 0, 0.0)";

    //const shadowBlur = isSelected && !draggingPage ? 15 / pages[pageID].zoom : 0;
    const shadowBlur = 0;

    //const shadowColor = (currentDot?.id === dot.id) ? pSBC(-0.8, measurement?.color) : pSBC(-0.25, measurement.color);
    const shadowColor = null;

    const fill = measurement?.color ? measurement.color : "rgba(255, 0, 0, 0.5)";

    const onMouseEnter = (e) => {
        if (deletingCount && isSelected) {
            e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count+hover.svg') 2 18, auto";
        } else if (isSelected) {
            e.target.getStage().container().style.cursor = "pointer";
        }
    }

    const onMouseLeave = (e) => {
        if (deletingCount && isSelected) {
            e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count.svg') 2 18, auto";
        } else if (isSelected && addingCount) {
            e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/add+count.svg') 8 24, auto";
        }
        e.target.getStage().container().style.cursor = "default";
    }

    const onMouseDown = (e) => {
        if (e.evt.button !== 0) return;

        setCurrentMeasurement(measurement.id);

        if (optionDown && !deletingCount) {
            setTimeout(() => {
                const element = document.getElementById(`measurement-${measurement.id}-name`);
                if (element) element.click();
            }, 100);
        }

        if (isSelected) {
            if (deletingCount) {
                e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count+active.svg') 2 18, auto";
            } else {
                e.target.getStage().container().style.cursor = "grabbing";
            }
        } else {
            e.target.getStage().container().style.cursor = "default";
        }
    }

    const onClick = (e) => {
        if (e.evt.button !== 0) return;

        if (deletingCount && isSelected) {
            const newMeasurement = {
                ...measurement,
                count_dots: measurement.count_dots.filter((d) => d.id !== dot.id),
                count: measurement.count_dots.length - 1,
            }

            handleCountTransform(newMeasurement, false, 'delete', dot.id);
            e.target.getStage().container().style.cursor = "default";
        } else if (!deletingCount) {
            if (e.evt.metaKey) {
                if (selectedMeasurements.find(m => m === measurement.id)) {
                    setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurement.id));
                } else {
                    setSelectedMeasurements([...selectedMeasurements, measurement.id]);
                }
            } else {
                setSelectedMeasurements([measurement.id]);
                setCurrentDot(dot);
                setCurrentMeasurement(measurement.id);
            }

            if (!takeoffSettings.show_measurement_sidebar) {
                handleChangeTakeoffSettings({
                    ...takeoffSettings,
                    show_measurement_sidebar: true,
                    show_pages_sidebar: takeoffSettings.pages_sidebar_location === takeoffSettings.measurement_sidebar_location
                        ? false : takeoffSettings.show_pages_sidebar,
                    show_ai_sidebar: takeoffSettings.ai_sidebar_location === takeoffSettings.measurement_sidebar_location
                        ? false : takeoffSettings.show_ai_sidebar,
                })
            }
            setShowDetails(true);

            setTimeout(() => {
                const element = document.getElementById("measurement-" + measurement.id);
                const yOffset = 50;

                if (element && (element.getBoundingClientRect().top < yOffset || element.getBoundingClientRect().top > window.innerHeight - yOffset)) {
                    element.scrollIntoView();
                }
            }, 100);
        }
    }

    const onDoubleClick = (e) => {
        if (e.evt.button !== 0) return;

        const toggle = document.getElementById(`measurement-toggle-dot-${measurement.id}`);
        if (toggle) toggle.click();

        setTimeout(() => {
            const element = document.getElementById(`measurement-dot-${dot.id}`);
            if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                element.scrollIntoView();
            }
        }, 100);
    }

    const draggable = isSelected;

    const onDragEnd = (e) => {
        let newMeasurement = {
            ...measurement,
            count_dots: measurement.count_dots.map((d) => {
                if (String(d.id) === String(dot.id)) {
                    return {
                        ...d,
                        x: e.target.x(),
                        y: e.target.y(),
                    };
                } else {
                    return d;
                }
            }),
        }

        handleCountTransform(newMeasurement);
    }

    const measurementSize = (isSelected || selectedMeasurements.find(m => m === measurement.id))
        ? (currentDot?.id === dot.id)
            ? measurement?.size ? Math.round(measurement.size * 2) / pages[pageID].zoom : 10 / pages[pageID].zoom
            : measurement?.size ? measurement.size / pages[pageID].zoom : 5 / pages[pageID].zoom
        : measurement?.size ? measurement.size / pages[pageID].zoom : 5 / pages[pageID].zoom;

    if (measurement?.shape === 'square') {
        return (
            <Rect
                x={dot.x}
                y={dot.y}
                width={measurementSize}
                height={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDblClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'circle') {
        return (
            <Circle
                x={dot.x}
                y={dot.y}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDblClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'diamond') {
        return (
            <Group
                x={dot.x}
                y={dot.y}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDblClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
            >
                <KonvaLine
                    points={[0, measurementSize, measurementSize, 0, 0, -measurementSize, -measurementSize, 0, 0, measurementSize]}
                    strokeWidth={strokeWidth}
                    stroke={stroke}
                    fill={fill}
                    closed
                    perfectDrawEnabled={false}
                />
            </Group>
        )
    } else if (measurement?.shape === 'triangle') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={3}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'pentagon') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={5}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'hexagon') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={6}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'octagon') {
        return (
            <RegularPolygon
                x={dot.x}
                y={dot.y}
                sides={8}
                radius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else if (measurement?.shape === 'star') {
        return (
            <Star
                x={dot.x}
                y={dot.y}
                numPoints={5}
                innerRadius={measurementSize / 2}
                outerRadius={measurementSize}
                strokeWidth={strokeWidth}
                stroke={stroke}
                fill={fill}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
                perfectDrawEnabled={false}
            />
        )
    } else {
        //cross
        return (
            <Group
                x={dot.x}
                y={dot.y}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDblClick={onDoubleClick}
                draggable={draggable}
                onDragEnd={onDragEnd}
                onContextMenu={contextMenu}
            >
                <KonvaLine
                    points={[-measurementSize, -measurementSize, measurementSize, measurementSize, 0, 0, measurementSize, -measurementSize, -measurementSize, measurementSize, 0, 0, -measurementSize, -measurementSize, measurementSize]}
                    strokeWidth={strokeWidth + measurementSize / 2}
                    stroke={fill}
                    fill={fill}
                    perfectDrawEnabled={false}
                />
            </Group>
        )
    }
}
