export const Description = {
    'ZoomIn': (
        <div>
            <p>Zoom in on the page</p>
        </div>
    ),
    'ZoomOut': (
        <div>
            <p>Zoom out of the page</p>
        </div>
    ),
    'ZoomReset': (
        <div>
            <p>Reset the zoom level of the page</p>
        </div>
    ),
    'ZoomToMeasurement': (
        <div>
            <p>Zoom to the currently selected measurement</p>

            <p>(You can also do this by double clicking the measurement in the sidebar)</p>
        </div>
    ),
    'MinimapToggle': (
        <div>
            <p>Toggle the visibility of the minimap</p>
        </div>
    ),
    'DrawScale': (
        <div>
            <p>Draw a scale for this page</p>

            <p>Click to set start point, click again to set end point. Then enter the coresponding length.</p>
        </div>
    ),
    'DrawRectangle': (
        <div>
            <p>Draw a rectangle on the page</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'DrawPolygon': (
        <div>
            <p>Draw a polygon on the page</p>

            <p>Click to start & place points. "Enter" or click origin to finish.</p>

            <p>After completion, click a point to remove. Click line to add a point.</p>
        </div>
    ),
    'DrawLength': (
        <div>
            <p>Draw a length on the page</p>

            <p>Click to start & place points. "Enter" or double click to finish.</p>

            <p>After completion, click a point to remove. Click line to add a point.</p>
        </div>
    ),
    'DrawCount': (
        <div>
            <p>Draw a count on the page</p>

            <p>Click to place points. Press "Enter" to finish.</p>
        </div>
    ),
    'CutPolygon': (
        <div>
            <p>Cut out sections of the currently selected polygon</p>

            <p>Draw negative polygon to cut out.</p>
        </div>
    ),
    'CutRectangleRect': (
        <div>
            <p>Cut out sections of the currently selected polygon</p>

            <p>Draw negative rectangle to cut out.</p>
        </div>
    ),
    'SplitPolygon': (
        <div>
            <p>Split the currently selected polygon</p>

            <p>Click two points outside polygon, 'Enter' to split the polygon.</p>
        </div>
    ),
    'CutRectangle': (
        <div>
            <p>Cut out sections of the currently selected rectangle</p>

            <p>Draw negative polygon to cut out.</p>
        </div>
    ),
    'CutPolygonRect': (
        <div>
            <p>Cut out sections of the currently selected rectangle</p>

            <p>Draw negative rectangle to cut out.</p>
        </div>
    ),
    'SplitRectangle': (
        <div>
            <p>Split the currently selected rectangle</p>

            <p>Click two points outside rectangle, 'Enter' to split the rectangle.</p>
        </div>
    ),
    'DrawCircle': (
        <div>
            <p>Add a circle to the page</p>

            <p>Click to place center, click again to set radius.</p>
        </div>
    ),
    'AddCount': (
        <div>
            <p>Add more items to the currently selected count</p>

            <p>'Enter' to finish</p>
        </div>
    ),
    'DeleteCount': (
        <div>
            <p>Delete items from the currently selected count</p>

            <p>'Enter' to finish</p>
        </div>
    ),
    'ToggleMeasurementSidebar': (
        <div>
            <p>Toggle the measurement sidebar</p>
        </div>
    ),
    'TogglePagesSidebar': (
        <div>
            <p>Toggle the pages sidebar</p>
        </div>
    ),
    'ToggleAISidebar': (
        <div>
            <p>Toggle the AI sidebar</p>
        </div>
    ),
    'SearchMeasurement': (
        <div>
            <p>Search for a measurement</p>
        </div>
    ),
    'SearchPage': (
        <div>
            <p>Search for a page</p>
        </div>
    ),
    'Undo': (
        <div>
            <p>Undo the last action on the drawing</p>
        </div>
    ),
    'Redo': (
        <div>
            <p>Redo the last action on the drawing</p>
        </div>
    ),
    'CombineMeasurments': (
        <div>
            <p>Combine two rectangles/polygons in the same group that are overlapping.</p>

            <p>Left click the first one, and then right click the second. Choose 'Combine' in the context menu.</p>
        </div>
    ),
    'Multi-select': (
        <div>
            <p>Select multiple measurements</p>

            <p>Hold shift or command and click on the measurements you want to select on the sidebar.</p>

            <p>Hold command and click on measurements you want to select on the page.</p>
        </div>
    ),
    'Highlight-area': (
        <div>
            <p>Highlight an area on the page</p>

            <p>Hold shift, click and drag to highlight an area on the page. This will select all measurements in the area.</p>
        </div>
    ),
    'Delete-Measurement': (
        <div>
            <p>Delete the currently selected measurement(s)</p>

            <p>Press 'Delete' or 'Backspace' to delete the currently selected measurement. Hit 'Delete' again to confrim on the popup.</p>
        </div>
    ),
    'DrawAnnotationCloud': (
        <div>
            <p>Draw a cloud annotation on the page</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'DrawAnnotationArrow': (
        <div>
            <p>Draw an arrow annotation on the page</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'DrawAnnotationRectangle': (
        <div>
            <p>Draw a rectangle annotation on the page</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'DrawAnnotationText': (
        <div>
            <p>Draw a text annotation on the page</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'DrawAnnotationRuler': (
        <div>
            <p>Draw a ruler annotation on the page</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'EraseCount': (
        <div>
            <p>Draw a rectangular region to erase counts</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'SelectionCalculator': (
        <div>
            <p>Draw a polygon and calculate the count, length, and area that intersects with the polygon.</p>

            <p>Click to start. Click again to finish.</p>
        </div>
    ),
    'PreviousPage': (
        <div>
            <p>Go to the previous page</p>
        </div>
    ),
    'NextPage': (
        <div>
            <p>Go to the next page</p>
        </div>
    ),
}
