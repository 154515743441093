import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import { selectAuth } from '../redux/slices/authSlice';

import "./styles/Dashboard.css";

import { API_ROUTE } from '../index';
import DashboardNavbar from '../components/DashboardNavbar';
import ActiveButton from '../components/ActiveButton';
import DashboardCreateProjectModal from './DashboardCreateProjectModal';
import DashboardProject from './DashboardProject';
import { Blocks } from 'react-loader-spinner';
import { IconBooks, IconBrandDatabricks, IconCirclePlus, IconPlus, IconSearch } from '@tabler/icons-react';

export default function Dashboard() {
    const auth = useSelector(selectAuth);

    const [loading, setLoading] = useState(true);

    const [title, setTitle] = useState("");
    const [address, setAddress] = useState("");
    const [description, setDescription] = useState("");

    const [dashboard, setDashboard] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const [search, setSearch] = useState("");

    useEffect(() => {
        if (auth.token && !dashboard) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/dashboard/`,
                data: {
                    'userID': auth.user.id
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setLoading(false);
                    setDashboard(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowCreateModal(false);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/projects/`,
            data: {
                'title': title,
                'author': auth.contractor.id,
                'company': auth.contractor.company,
                'address': address,
                'desc': description,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                window.location.href = `/project-dashboard/${response.data.uuid}`;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleArchive = (p) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${p.id}/`,
            data: {
                ...p,
                'archived': !p.archived
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                let newProject = {
                    ...p,
                    'archived': !p.archived
                }

                setDashboard({
                    ...dashboard,
                    'projects': dashboard.projects.map((project) => {
                        if (project.id === p.id) {
                            return newProject;
                        } else {
                            return project;
                        }
                    })
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <DashboardNavbar auth={auth} />
            <div className='dashboard-container'>
                <div className='dashboard-header'>
                    <div className='dashboard-title'>
                        Projects
                    </div>

                    <DashboardCreateProjectModal
                        show={showCreateModal}
                        onHide={() => setShowCreateModal(false)}
                        auth={auth}
                        title={title}
                        setTitle={setTitle}
                        address={address}
                        setAddress={setAddress}
                        description={description}
                        setDescription={setDescription}
                        handleSubmit={(e) => handleSubmit(e)}
                    />


                    <div className='dashboard-buttons'>
                        <a href='https://docs.bobyard.com/get-started/introduction' target='_blank'>
                            <div className='dashboard-button'>
                                <IconBooks size={20} stroke={1} /> Documentation
                            </div>
                        </a>

                        <a href='/database'>
                            <div className='dashboard-button'>
                                <IconBrandDatabricks size={20} stroke={1} /> Cost Database
                            </div>
                        </a>

                        <button
                            className='active-button'
                            onClick={() => setShowCreateModal(true)}
                        >
                            <IconPlus size={20} stroke={2} /> Create Project
                        </button>
                    </div>
                </div>

                {dashboard?.projects?.filter((project) => !project.archived)?.length > 0 ?
                    <div className='dashboard-search'>
                        <div>
                            <IconSearch size={20} stroke={1} />
                        </div>
                        <div>
                            <input
                                type='text'
                                placeholder='Search...'
                                className='dashboard-search-input'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                    : null}

                {loading
                    ? <div className='dashboard-projects-loading'>
                        <Blocks
                            visible={true}
                            height="60"
                            width="60"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                    : <>
                        <div className='dashboard-projects-container'>
                            {dashboard?.projects?.filter((project) => !project.archived).filter(p => {
                                if (search === "" || !search) {
                                    return true;
                                } else {
                                    return p.title?.toLowerCase().includes(search.toLowerCase())
                                        || p.desc?.toLowerCase().includes(search.toLowerCase())
                                        || p.address?.toLowerCase().includes(search.toLowerCase());
                                }
                            }).sort((a, b) => b.id - a.id).map((project) => {
                                return <DashboardProject key={project.id} project={project} handleArchive={handleArchive} />
                            })}

                            <div
                                className='dashboard-create-project-card'
                                onClick={() => setShowCreateModal(true)}
                            >
                                <div>
                                    <IconCirclePlus size={80} stroke={0.5} />
                                </div>

                                <div>
                                    Create Project
                                </div>
                            </div>
                        </div>

                        <div className='dashboard-archived-projects'>
                            <div className='dashboard-title'>
                                Archived Projects
                            </div>

                            {dashboard?.projects?.filter((project) => project.archived).length === 0 &&
                                <div>
                                    No archived projects...
                                </div>
                            }

                            <div className='dashboard-projects-container'>
                                {dashboard?.projects?.filter((project) => project.archived).sort((a, b) => b.id - a.id).map((project) => {
                                    return <DashboardProject key={project.id} project={project} handleArchive={handleArchive} />
                                })}
                            </div>
                        </div>
                    </>
                }
            </div >
        </>
    );
}

