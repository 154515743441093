import { lensPath, pipe, view, set, replace, test } from 'ramda';
import { forwardRef, useEffect, useState } from 'react';
import { addCommas, numToStr, numToStr2Places } from '../EstimateUtilities';
import { eventValueLens } from '../../utilities/utilities';


const stripCommas = replace(/,/g, '');
const numRegex = /^-?[0-9]*\.?[0-9]*$/;
const validateRegex = test(numRegex);
const strToNum = (str) => (isNaN(pipe(stripCommas, parseFloat)(str)) ? null : pipe(stripCommas, parseFloat)(str));

const initialState = { numStr: '' };

const numStrLens = lensPath(['numStr']);

const safeSetNumStr = (numStr) => (state) => {
  const stripped = stripCommas(numStr);
  return validateRegex(stripped) ? set(numStrLens)(addCommas(stripped))(state) : state;
};
const safeSetNumStrFromEvent = (e) => safeSetNumStr(view(eventValueLens)(e));

const NumericField = forwardRef(({ onContextMenu = () => {}, onBlur = () => {}, onEnter = () => {}, onClick = () => {}, value, className, placeholder = null }, ref) => {
  const [state, setState] = useState(initialState);
  const derivedNumber = pipe(view(numStrLens), strToNum)(state);
  useEffect(() => {
    setState(set(numStrLens)(numToStr(value)));
  }, [value]);
  return (
    <input
      value={view(numStrLens)(state)}
      onChange={(e) => {
        pipe(safeSetNumStrFromEvent, setState)(e, state);
      }}
      onBlur={() => onBlur(derivedNumber)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onEnter(derivedNumber);
        }
      }}
      onClick={onClick}
      className={className}
      placeholder={isFinite(placeholder) ? numToStr2Places(placeholder) : 'ERROR'}
      onContextMenu={onContextMenu}
      ref={ref}
    />
  );
});

export default NumericField;
