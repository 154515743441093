import { useCallback, useContext, useEffect, useState } from 'react';

import { IconDimensions, IconAngle, IconX, IconFolderOff, IconRestore, IconHighlight, IconEyeOff, IconEye, IconTrashX, IconArrowAutofitHeight, IconChevronDown, IconRuler2, IconLineDashed, IconBorderStyle2, IconBaselineDensityMedium, IconLineHeight, IconRotate360, IconShare3, IconRuler, IconCube, IconWeight, IconPointFilled, IconComponents, IconFrame } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';

import DetailItem from './DetailItem';
import GroupMenu from './GroupMenu';
import ColorPicker from '../../../../components/ColorPicker';
import { TakeoffContext } from '../../../helper/Context';
import DetailsNavbarInput from './DetailsNavbarInput';
import Popup from 'reactjs-popup';
import { UOM_CONVERSIONS, UOM_Dependencies, UOM_Dependencies_Description, UOM_Description, UOM_Display, UOM_Measured_Default, UOM_Pitchable, calculateValue } from '../../../helper/UnitConversions';

import debounce from 'lodash.debounce';

export default function RectangleDetails({ left, width }) {
    const {
        pageID,
        project, setProject,
        pages, setPages,
        groups, setGroups,
        measurements, setMeasurements,
        drawing,
        handleUpdateProject,
        currentMeasurement, setCurrentMeasurement,
        handleRectTransform,
        setEditingMeasurement,
        setShowDetails,
        setShowDeleteMeasurementModal,
        groupMeasurement,
        optionDown, setOptionDown,
        handleZoomToMeasurement,
        setSelectedMeasurements
    } = useContext(TakeoffContext);

    const debouncedSetMeasurements = useCallback(debounce(setMeasurements, 1000), []);

    const [tempValue1, setTempValue1] = useState(measurements[currentMeasurement]?.quantity1);

    const [showColorPicker, setShowColorPicker] = useState(false);

    useEffect(() => {
        const handleClick = e => {
            setShowColorPicker(false);
        }

        //listen for escape
        const handleKeyDown = e => {
            if (e.key === 'Escape' && showColorPicker) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
                setCurrentMeasurement(currentMeasurement);
            } else if (e.key === 'Enter' && showColorPicker || e.key === 'Return' && showColorPicker) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                setShowColorPicker(false);
            }
        }

        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [showColorPicker])

    return (
        <>
            <div className='detailsnavbar-buttons-container'>
                {measurements[currentMeasurement]?.page != pageID
                    ? <>
                        <div
                            className='detailsnavbar-button'
                            id={'detailsnavbar-rectangle-differentpage'}
                            onClick={() => {
                                window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurements[currentMeasurement]?.page}`);
                                handleZoomToMeasurement(currentMeasurement);
                            }}
                            size={'small'}
                        >
                            <IconShare3 size={20} stroke={1} />
                        </div>

                        <Tooltip className='tooltip' anchorSelect={'#detailsnavbar-rectangle-differentpage'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                            <div>
                                <b>Measurement on different page</b>
                            </div>
                            <div>
                                Click to go to page
                            </div>
                        </Tooltip>
                    </>
                    : null}

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-rectangle-hide`}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (e.altKey) {
                            let newMeasurements = { ...measurements };

                            if (Object.values(newMeasurements).reduce((acc, m) => {
                                if (!m.hide) {
                                    return acc + 1;
                                }
                                return acc;
                            }, 0) > 1) {
                                Object.values(newMeasurements).forEach((m) => {
                                    newMeasurements[m.id].hide = true;
                                })
                                newMeasurements[currentMeasurement].hide = false;
                                setMeasurements(newMeasurements);
                            } else {
                                Object.values(newMeasurements).forEach((m) => {
                                    newMeasurements[m.id].hide = false;
                                })
                                newMeasurements[currentMeasurement].hide = false;
                                setMeasurements(newMeasurements);
                            }
                        } else {
                            handleRectTransform({
                                ...measurements[currentMeasurement],
                                hide: !measurements[currentMeasurement]?.hide,
                            }, true);
                        }
                    }}
                    size={'small'}
                >
                    {measurements[currentMeasurement]?.hide
                        ? <IconEyeOff size={20} stroke={1} />
                        : <IconEye size={20} stroke={1} />
                    }
                </div>

                <div className='tooltip-container'>
                    <Tooltip anchorSelect={'#detailsnavbar-rectangle-hide'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                        <div>
                            <b>{measurements[currentMeasurement]?.hide ? 'Show measurement' : 'Hide measurement'}</b>
                        </div>
                        {!measurements[currentMeasurement]?.hide &&
                            <div>
                                Alt (Option) + click to toggle all other measurements
                            </div>
                        }
                    </Tooltip>
                </div>

                <div className='detailsnavbar-color-container'>
                    {showColorPicker
                        ? <ColorPicker
                            color={measurements[currentMeasurement]?.color}
                            onChange={(color) => setMeasurements(prev => ({
                                ...prev,
                                [currentMeasurement]: {
                                    ...prev[currentMeasurement],
                                    color: color.hex,
                                    use_group_color: false,
                                }
                            }))}
                            onChangeComplete={(color) => {
                                handleRectTransform({
                                    ...measurements[currentMeasurement],
                                    color: color.hex,
                                    use_group_color: false,
                                }, true);
                            }}
                            top={35}
                        //left={left + 50}
                        />
                        : null}

                    <div
                        className='detailsnavbar-button'
                        id={`detailsnavbar-rectangle-color`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowColorPicker(true);
                        }}
                    >
                        <div
                            className='detailsnavbar-option-color-placeholder'
                            style={{
                                backgroundColor: measurements[currentMeasurement]?.color ? measurements[currentMeasurement]?.color : '#9DD9F3',
                            }}
                        ></div>
                    </div>

                    <Tooltip anchorSelect={'#detailsnavbar-rectangle-color'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }} place='top-start'>
                        {measurements[currentMeasurement]?.use_group_color
                            ? <>
                                <div><b>Select color</b></div>
                                <div>Currently using group's color.</div>
                            </>
                            : <>
                                <div>Select color</div>
                            </>}
                    </Tooltip>
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-rectangle-group-color`}
                    onClick={() => {
                        let disabled = measurements[currentMeasurement]?.use_group_color ? true : false;
                        if (disabled) return;

                        let colorTemp = groups[measurements[currentMeasurement]?.group]?.color ? groups[measurements[currentMeasurement]?.group]?.color : '#9DD9F3';

                        handleRectTransform({
                            ...measurements[currentMeasurement],
                            use_group_color: true,
                            color: colorTemp,
                        }, true);
                    }}
                    size={'small'}
                    disabled={measurements[currentMeasurement]?.use_group_color ? true : false}
                >
                    <IconRestore size={20} stroke={1} style={{ color: measurements[currentMeasurement]?.use_group_color ? 'lightgray' : 'black' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-rectangle-group-color'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    {measurements[currentMeasurement]?.use_group_color
                        ? <>
                            <div>
                                Reset to group color
                            </div>
                        </>
                        : <>
                            <div>
                                Reset to group color
                            </div>
                        </>
                    }
                </Tooltip>

                {/*<DetailsNavbarInput
                    keyID={'detailsnavbar-polygondetails-gap'}
                >
                    {width < 900
                        ? <div className='detailsnavbar-row-name'>
                            <IconLineHeight size={20} stroke={1} />
                        </div>
                        : <div className='detailsnavbar-row-name'>
                            <IconLineHeight size={20} stroke={1} />
                            Stripe&nbsp;gap
                        </div>
                    }
                    <input
                        className='detailsnavbar-input'
                        id='detailsnavbar-polygondetails-gap'
                        type='number'
                        onFocus={() => setEditingMeasurement(true)}
                        value={measurements[currentMeasurement]?.gap ? measurements[currentMeasurement]?.gap : ''}
                        onChange={(e) => setMeasurements(prev => ({
                            ...prev,
                            [currentMeasurement]: {
                                ...prev[currentMeasurement],
                                gap: e.target.value ? e.target.value : null,
                            }
                        }))}
                        placeholder='--'
                        onBlur={() => handleRectTransform(measurements[currentMeasurement], true)}
                    />
                </DetailsNavbarInput>

                <Tooltip anchorSelect={'#detailsnavbar-polygondetails-gap-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    <div>
                        <b>Stripe gap (visual)</b>
                    </div>
                    <div>
                        Gap between stripes for fill pattern
                    </div>
                </Tooltip>

                <DetailsNavbarInput
                    keyID={'detailsnavbar-polygondetails-size'}
                >
                    {width < 900
                        ? <div className='detailsnavbar-row-name'>
                            <IconRotate360 size={20} stroke={1} />
                        </div>
                        : <div className='detailsnavbar-row-name'>
                            <IconRotate360 size={20} stroke={1} />
                            Stripe angle
                        </div>
                    }
                    <input
                        className='detailsnavbar-input'
                        id='detailsnavbar-polygondetails-size'
                        type='number'
                        onFocus={() => setEditingMeasurement(true)}
                        value={measurements[currentMeasurement]?.size ? measurements[currentMeasurement]?.size : ''}
                        onChange={(e) => setMeasurements(prev => ({
                            ...prev,
                            [currentMeasurement]: {
                                ...prev[currentMeasurement],
                                size: e.target.value ? e.target.value : null,
                            }
                        }))}
                        placeholder='--'
                        onBlur={() => handleRectTransform(measurements[currentMeasurement], true)}
                    />
                </DetailsNavbarInput>*/}

                <Tooltip anchorSelect={'#detailsnavbar-polygondetails-size-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    <div>
                        <b>Stripe angle (visual)</b>
                    </div>
                    <div>
                        Angle of stripes for fill pattern
                    </div>
                </Tooltip>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`measurementdetail-${currentMeasurement}-name`}
                    onClick={() => {
                        setOptionDown(true)

                        setTimeout(() => {
                            document.getElementById(`measurement-${currentMeasurement}-name`)?.click();
                            setOptionDown(false);
                        }, 100);
                    }}
                    size={'small'}
                >
                    <IconHighlight size={20} stroke={1} />
                </div>

                <Tooltip anchorSelect={'#measurementdetail-' + currentMeasurement + '-name'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    Edit name (or 'Enter')
                </Tooltip>

                <GroupMenu
                    handleClick={(groupID) => {
                        groupMeasurement({
                            id: currentMeasurement,
                            group: groupID,
                        })
                    }}
                    measurement={measurements[currentMeasurement]}
                />

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-rectangle-ungroup`}
                    onClick={() => {
                        let disabled = measurements[currentMeasurement]?.group ? false : true;
                        if (disabled) return;

                        groupMeasurement({
                            id: currentMeasurement,
                            group: null,
                        })
                    }}
                    style={{
                        color: measurements[currentMeasurement]?.group ? 'black' : 'lightgray',
                    }}
                    size={'small'}
                    disabled={measurements[currentMeasurement]?.group ? false : true}
                >
                    <IconFolderOff size={20} stroke={1} style={{ color: measurements[currentMeasurement]?.group ? 'black' : 'lightgray' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-rectangle-ungroup'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    {measurements[currentMeasurement]?.group
                        ? <>
                            <div>Ungroup measurement.</div>
                        </>
                        : <>
                            <div><b>
                                Ungroup measurement.
                            </b></div>
                            <div>
                                Measurement is not grouped.
                            </div>
                        </>
                    }
                </Tooltip>

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                {/*measurements[currentMeasurement]?.uom !== UOM_Measured_Default[measurements[currentMeasurement]?.type] &&
                    <>
                        <div>
                            {(measurements[currentMeasurement]?.area * (pages[measurements[currentMeasurement].page].scale ** 2)).toFixed(1)}
                        </div>
                        <div>
                            ft<sup>2</sup>
                        </div>
                    </>
                */}

                <DetailsNavbarInput
                    keyID={'detailsnavbar-polygondetails-offset'}
                >
                    {width < 900
                        ? <div className='detailsnavbar-row-name'>
                            <IconFrame size={20} stroke={1} />
                        </div>
                        : <div className='detailsnavbar-row-name'>
                            <IconFrame size={20} stroke={1} />
                            Offset (ft)
                        </div>
                    }
                    <input
                        className='detailsnavbar-input'
                        id='detailsnavbar-polygondetails-offset'
                        type='number'
                        value={measurements[currentMeasurement]?.offset ? measurements[currentMeasurement]?.offset : ''}
                        onFocus={() => setEditingMeasurement(true)}
                        onChange={e => setMeasurements(prev => ({
                            ...prev,
                            [currentMeasurement]: {
                                ...prev[currentMeasurement],
                                offset: e.target.value ? e.target.value : null,
                                area: e.target.value
                                    ? (prev[currentMeasurement].width - 2 * e.target.value / pages[prev[currentMeasurement].page].scale) * (prev[currentMeasurement].height - 2 * e.target.value / pages[prev[currentMeasurement].page].scale)
                                    : prev[currentMeasurement].area
                            }
                        }))}
                        onBlur={() => {
                            handleRectTransform(measurements[currentMeasurement], true);
                            setEditingMeasurement(false);
                        }}
                        placeholder='--'
                    />
                </DetailsNavbarInput>

                <div
                    style={{
                        zIndex: 10000,
                    }}
                >
                    <Popup
                        trigger={open => (
                            <div
                                id='detailsnavbar-rectangledetails-uom'
                                className={'detailsnavbar-button'}
                                style={{
                                    color: open ? '#006AFE' : '#525252'
                                }}
                            >
                                <IconDimensions size={20} stroke={1} />
                                {UOM_Display[measurements[currentMeasurement]?.uom || UOM_Measured_Default[measurements[currentMeasurement]?.type]]}
                                <IconChevronDown size={20} stroke={1} />
                            </div>
                        )}
                        on='click'
                        position="bottom center"
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                        contentStyle={{
                            width: '250px',
                        }}
                    >
                        <div className="detailsnavbar-uom-items">
                            {Object.keys(UOM_CONVERSIONS[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]]).map((uom, i) => (
                                <div
                                    key={i}
                                    className={'detailsnavbar-uom-item ' + ((measurements[currentMeasurement]?.uom || UOM_Measured_Default[measurements[currentMeasurement]?.type]) === uom ? 'detailsnavbar-uom-item-active' : '')}
                                    onClick={() => {
                                        handleRectTransform({
                                            ...measurements[currentMeasurement],
                                            uom: uom,
                                            quantity1: null,
                                            quantity2: null,
                                        }, true);
                                        setTempValue1(null);
                                    }}
                                >
                                    <div>{UOM_Display[uom]}</div>
                                    <div>({UOM_Description[uom]})</div>
                                    <div>
                                        {UOM_Measured_Default[measurements[currentMeasurement]?.type] === uom
                                            ? <><IconPointFilled size={20} /></>
                                            : <>&nbsp;</>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Popup>
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-uom'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top'>
                    <div>
                        <b>Unit of measurement</b>
                    </div>
                    <div>
                        Select a different unit of measurement to convert to
                    </div>
                </Tooltip>

                {measurements[currentMeasurement]?.uom && UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom].length > 1
                    ?
                    <>
                        <DetailsNavbarInput keyID={'detailsnavbar-rectangledetails-quantity1'}>
                            {width < 900
                                ? <div className='detailsnavbar-row-name'>
                                    {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('length')
                                        ? <IconRuler2 size={20} stroke={1} />
                                        : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('area')
                                            ? <IconRuler size={20} stroke={1} />
                                            : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('volume')
                                                ? <IconCube size={20} stroke={1} />
                                                : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('tri')
                                                    || UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('o.c.')
                                                    ? <IconComponents size={20} stroke={1} />
                                                    : <IconWeight size={20} stroke={1} />
                                    }
                                    {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.split('(')?.pop()?.split(')')[0]}
                                </div>
                                : <div className={'detailsnavbar-row-name'}>
                                    {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('length')
                                        ? <IconRuler2 size={20} stroke={1} />
                                        : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('area')
                                            ? <IconRuler size={20} stroke={1} />
                                            : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('volume')
                                                ? <IconCube size={20} stroke={1} />
                                                : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('tri')
                                                    || UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']?.toLowerCase().includes('o.c.')
                                                    ? <IconComponents size={20} stroke={1} />
                                                    : <IconWeight size={20} stroke={1} />
                                    }
                                    {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']}
                                </div>
                            }
                            <input
                                className='detailsnavbar-input'
                                id='detailsnavbar-rectangledetails-quantity1'
                                type='number'
                                value={tempValue1 || ''}
                                onFocus={() => setEditingMeasurement(true)}
                                onChange={e => {
                                    setTempValue1(e.target.value);

                                    debouncedSetMeasurements(prev => ({
                                        ...prev,
                                        [currentMeasurement]: {
                                            ...prev[currentMeasurement],
                                            quantity1: e.target.value === 0 || e.target.value ? e.target.value : null,
                                        }
                                    }))
                                }}
                                onBlur={() => {
                                    handleRectTransform(measurements[currentMeasurement], true);
                                    setEditingMeasurement(false);
                                }}
                                placeholder='--'
                            />
                        </DetailsNavbarInput>

                        <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-quantity1-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                            <div>
                                <b>{UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']}</b>
                            </div>
                            <div>
                                {UOM_Dependencies_Description[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][1]['quantity1']}
                            </div>
                        </Tooltip>
                    </>
                    : null
                }

                {measurements[currentMeasurement]?.uom && UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom].length > 2
                    ? UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('tri')
                        ? <>
                            <Popup
                                trigger={open => (
                                    <div
                                        id='detailsnavbar-rectangledetails-quantity2'
                                        className={'detailsnavbar-button'}
                                        style={{
                                            color: open ? '#006AFE' : '#525252'
                                        }}
                                    >
                                        <IconComponents size={20} stroke={1} />
                                        {measurements[currentMeasurement]?.quantity2 === 1 ? 'Tri' : 'Sqr'}
                                        <IconChevronDown size={20} stroke={1} />
                                    </div>
                                )}
                                on='click'
                                position="bottom right"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{
                                    width: '250px',
                                }}
                            >
                                <div className="detailsnavbar-uom-items">
                                    {[{ name: 'Square', value: 0 }, { name: 'Triangle', value: 1 }].map((fillpattern, i) => (
                                        <div
                                            key={i}
                                            className={'detailsnavbar-uom-item ' + (measurements[currentMeasurement]?.quantity2 === fillpattern.value ? 'detailsnavbar-uom-item-active' : '')}
                                            onClick={() => {
                                                handleRectTransform({
                                                    ...measurements[currentMeasurement],
                                                    quantity2: fillpattern.value,
                                                }, true);
                                            }}
                                        >
                                            <div>Fill with {fillpattern.name.toLowerCase()}s</div>
                                        </div>
                                    ))}
                                </div>
                            </Popup>

                            <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-quantity2'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                                <div>
                                    <b>O.C. fill pattern</b>
                                </div>
                                <div>
                                    Select fill pattern for triangle or square
                                </div>
                            </Tooltip>
                        </>
                        : <>
                            <DetailsNavbarInput keyID={'detailsnavbar-rectangledetails-quantity2'}>
                                {width < 900
                                    ? <div className='detailsnavbar-row-name'>
                                        {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('length')
                                            ? <IconRuler2 size={20} stroke={1} />
                                            : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('area')
                                                ? <IconRuler size={20} stroke={1} />
                                                : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('volume')
                                                    ? <IconCube size={20} stroke={1} />
                                                    : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('tri')
                                                        || UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('o.c.')
                                                        ? <IconComponents size={20} stroke={1} />
                                                        : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2']?.toLowerCase().includes('angle')
                                                            ? <IconAngle size={20} stroke={1} />
                                                            : <IconWeight size={20} stroke={1} />
                                        }
                                        {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2']?.split('(')?.pop()?.split(')')[0]}
                                    </div>
                                    : <div className={'detailsnavbar-row-name'}>
                                        {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('length')
                                            ? <IconRuler2 size={20} stroke={1} />
                                            : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('area')
                                                ? <IconRuler size={20} stroke={1} />
                                                : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('volume')
                                                    ? <IconCube size={20} stroke={1} />
                                                    : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('tri')
                                                        || UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2'].toLowerCase().includes('o.c.')
                                                        ? <IconComponents size={20} stroke={1} />
                                                        : UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2']?.toLowerCase().includes('angle')
                                                            ? <IconAngle size={20} stroke={1} />
                                                            : <IconWeight size={20} stroke={1} />
                                        }
                                        {UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2']}
                                    </div>
                                }
                                <input
                                    className='detailsnavbar-input'
                                    id='detailsnavbar-rectangledetails-quantity2'
                                    type='number'
                                    value={measurements[currentMeasurement]?.quantity2 ? measurements[currentMeasurement]?.quantity2 : ''}
                                    onFocus={() => setEditingMeasurement(true)}
                                    onChange={e => setMeasurements(prev => ({
                                        ...prev,
                                        [currentMeasurement]: {
                                            ...prev[currentMeasurement],
                                            quantity2: e.target.value === 0 || e.target.value ? e.target.value : null,
                                        }
                                    }))}
                                    onBlur={() => {
                                        handleRectTransform(measurements[currentMeasurement], true);
                                        setEditingMeasurement(false);
                                    }}
                                    placeholder='--'
                                />

                            </DetailsNavbarInput>

                            <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-quantity2-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                                <div>
                                    <b>{UOM_Dependencies[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2']}</b>
                                </div>
                                <div>
                                    {UOM_Dependencies_Description[measurements[currentMeasurement]?.uom_measured || UOM_Measured_Default[measurements[currentMeasurement]?.type]][measurements[currentMeasurement]?.uom][2]['quantity2']}
                                </div>
                            </Tooltip>
                        </>
                    : null
                }

                {UOM_Pitchable[measurements[currentMeasurement]?.uom || UOM_Measured_Default[measurements[currentMeasurement]?.type]] &&
                    <DetailsNavbarInput
                        keyID={'detailsnavbar-rectangledetails-pitch'}
                    >
                        {width < 900
                            ? <div className='detailsnavbar-row-name'>
                                <IconAngle size={20} stroke={1} />
                            </div>
                            : <div className='detailsnavbar-row-name'>
                                <IconAngle size={20} stroke={1} />
                                Pitch
                                {measurements[currentMeasurement]?.pitch_type === 'percent'
                                    ? <div>(%)</div>
                                    : <div>(in)</div>
                                }
                                <Popup
                                    trigger={open => (
                                        <div
                                            id='detailsnavbar-polygondetails-pitch-type'
                                            className={'detailsnavbar-button-pitch-type'}
                                            style={{
                                                color: open ? '#006AFE' : '#525252'
                                            }}
                                        >
                                            <IconChevronDown size={20} stroke={1} />
                                        </div>
                                    )}
                                    on='click'
                                    position="bottom center"
                                    closeOnDocumentClick
                                    mouseLeaveDelay={300}
                                    mouseEnterDelay={0}
                                    contentStyle={{
                                        width: '250px',
                                    }}
                                >
                                    <div className="detailsnavbar-uom-items">
                                        {['percent', 'in'].map((pitch_type, i) => (
                                            <div
                                                key={i}
                                                className={'detailsnavbar-uom-item ' + (measurements[currentMeasurement]?.pitch_type === pitch_type ? 'detailsnavbar-uom-item-active' : '')}
                                                onClick={() => {
                                                    handleRectTransform({
                                                        ...measurements[currentMeasurement],
                                                        pitch_type: pitch_type,
                                                    }, true);
                                                }}
                                            >
                                                {pitch_type === 'percent'
                                                    ? <div>
                                                        Percent (rise/run)
                                                    </div>
                                                    : <div>
                                                        Inches (rise/12")
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </Popup>
                            </div>
                        }
                        <input
                            className='detailsnavbar-input'
                            id='detailsnavbar-rectangledetails-pitch'
                            type='number'
                            value={measurements[currentMeasurement]?.pitch ? measurements[currentMeasurement]?.pitch : ''}
                            onFocus={() => setEditingMeasurement(true)}
                            onChange={e => setMeasurements(prev => ({
                                ...prev,
                                [currentMeasurement]: {
                                    ...prev[currentMeasurement],
                                    pitch: e.target.value ? e.target.value : null,
                                }
                            }))}
                            onBlur={() => {
                                handleRectTransform(measurements[currentMeasurement], true);
                                setEditingMeasurement(false);
                            }}
                            placeholder='--'
                        />
                    </DetailsNavbarInput>
                }

                <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-pitch-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                    <div>
                        <b>Pitch (in)</b>
                    </div>
                    <div>
                        Number of inches of rise per 12 inches of run.
                    </div>
                </Tooltip>

                {/*
                <DetailsNavbarInput
                    keyID={'detailsnavbar-rectangledetails-depth'}
                >
                    {width < 900
                        ? <div className='detailsnavbar-row-name'>
                            <IconArrowAutofitHeight size={20} stroke={1} />
                        </div>
                        : <div className={'detailsnavbar-row-name'}>
                            <IconArrowAutofitHeight size={20} stroke={1} />
                            Depth&nbsp;(ft)
                        </div>
                    }
                    <input
                        className='detailsnavbar-input'
                        id='detailsnavbar-rectangledetails-depth'
                        type='number'
                        value={measurements[currentMeasurement]?.depth ? measurements[currentMeasurement]?.depth : ''}
                        onFocus={() => setEditingMeasurement(true)}
                        onChange={e => setMeasurements(prev => ({
                            ...prev,
                            [currentMeasurement]: {
                                ...prev[currentMeasurement],
                                depth: e.target.value ? e.target.value : null,
                            }
                        }))}
                        onBlur={() => {
                            handleRectTransform(measurements[currentMeasurement], true);
                            setEditingMeasurement(false);
                        }}
                        placeholder='--'
                    />
                </DetailsNavbarInput>

                <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-depth-container'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-start'>
                    <div>
                        <b>Depth (ft)</b>
                    </div>
                    <div>
                        Depth of rectangle
                    </div>
                </Tooltip>

                <Popup
                    trigger={open => (
                        <div
                            id='detailsnavbar-rectangledetails-details'
                            className={'detailsnavbar-button' + (!measurements[currentMeasurement]?.depth && !measurements[currentMeasurement]?.pitch ? ' detailsnavbar-button-disabled' : '')}
                            style={{
                                color:
                                    open
                                        ? '#006AFE'
                                        : measurements[currentMeasurement]?.depth || measurements[currentMeasurement]?.pitch
                                            ? '#525252'
                                            : 'silver',
                            }}
                        >
                            {width < 900
                                ? <>
                                    <IconRuler2 size={20} stroke={1} />
                                    <IconChevronDown size={20} stroke={1} />
                                </>
                                : <>
                                    <IconRuler2 size={20} stroke={1} /> Details < IconChevronDown size={20} stroke={1} />
                                </>
                            }
                        </div>
                    )}
                    on='click'
                    position="bottom center"
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    disabled={!(measurements[currentMeasurement]?.depth || measurements[currentMeasurement]?.pitch)}
                >
                    <div className="navdropdown-items">
                        {(measurements[currentMeasurement]?.depth && String(measurements[currentMeasurement]?.depth) !== '0') || (measurements[currentMeasurement]?.pitch && String(measurements[currentMeasurement]?.pitch) !== '0') ?
                            <DetailItem title='Area'>
                                {(measurements[currentMeasurement]?.area * (pages[measurements[currentMeasurement].page].scale ** 2)).toFixed(1)} ft<sup>2</sup>
                            </DetailItem>
                            : null}

                        {(measurements[currentMeasurement]?.depth && String(measurements[currentMeasurement]?.depth) !== '0') && (measurements[currentMeasurement]?.pitch && String(measurements[currentMeasurement]?.pitch) !== '0') ?
                            <DetailItem title='Pitch area'>
                                {(measurements[currentMeasurement]?.area / (Math.cos(Math.atan(measurements[currentMeasurement]?.pitch / 12))) * (pages[measurements[currentMeasurement].page].scale ** 2)).toFixed(1)} ft<sup>2</sup>
                            </DetailItem>
                            : null}
                    </div>
                </Popup>

                <Tooltip anchorSelect={'#detailsnavbar-rectangledetails-details'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                    <div>
                        <b>Details</b>
                    </div>
                    <div>
                        Measurement details and calculations.
                    </div>
                    <div>
                        Final value is shown in sidebar.
                    </div>
                </Tooltip>*/}

                <div className='detailsnavbar-divider'>
                    &nbsp;
                </div>

                <div
                    className='detailsnavbar-button'
                    id={`detailsnavbar-rectangle-delete`}
                    onClick={() => setShowDeleteMeasurementModal(true)}
                    size={'small'}
                >
                    <IconTrashX size={20} stroke={1} style={{ color: 'red' }} />
                </div>

                <Tooltip anchorSelect={'#detailsnavbar-rectangle-delete'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                    Delete measurement
                </Tooltip>
            </div>

            <div
                className='detailsnavbar-button'
                id={`detailsnavbar-circle-close`}
                onClick={() => {
                    setSelectedMeasurements(prev => prev.filter(m => m !== currentMeasurement));
                    setCurrentMeasurement(null)
                }}
            >
                <IconX size={20} stroke={1} />
            </div>

            <Tooltip anchorSelect={'#detailsnavbar-circle-close'} delayShow={500} positionStrategy='fixed' style={{ zIndex: 10000 }} place='top-end'>
                Close
            </Tooltip>
        </>
    )
}
