import { useState, useEffect } from 'react';
import { eventValueLens } from '../../utilities/utilities';
import { pipe, view, isNil } from 'ramda';

const SelectField = ({ onBlur = () => {}, value, className, children }) => {
  const [fieldValue, setFieldValue] = useState('');
  useEffect(() => {
    isNil(value) ? setFieldValue('') : setFieldValue(value);
  }, [value]);

  return (
    <select onChange={pipe(view(eventValueLens), (fvalue) => (fvalue == '' ? null : fvalue), onBlur)} value={fieldValue} className={className}>
      <option value=''></option>
      {children}
    </select>
  );
};

export default SelectField;
