import React, { useState, useEffect, useRef, useContext, useMemo, useCallback } from "react";
import {
  Stage,
  Layer,
  Image as KonvaImage,
  Rect,
  Circle,
  Line,
  Shape,
} from "react-konva";
import { Html } from "react-konva-utils";
import { useSelector } from "react-redux";
import axios from "axios";
import useImage from "use-image";

import { Grid, Blocks } from 'react-loader-spinner'

import { TakeoffContext } from "../helper/Context";
import { API_ROUTE, PROXYURL } from "../../index";
import { selectAuth } from "../../redux/slices/authSlice";
import ContextMenu from "../../components/ContextMenu";
import { getSelected, getSelectedAILengths } from "../helper/getSelected";

import "../styles/TakeoffPlan.css";
import AILocalizationDots from "./ai/AILocalizationDots";
import { IconCircleDot, IconCircles, IconFileExport, IconHome, IconPolygon, IconRectangle, IconRulerMeasure, IconTimeline, IconZoomIn, IconZoomOut } from "@tabler/icons-react";
import ExportLegend from "./measurements/ExportLegend";

import debounce from "lodash.debounce";

const polygonClipping = require("polygon-clipping");

export default function TakeoffPlan({ url, children }) {
  const {
    pageID,
    pages, setPages,
    groups, setGroups,
    project, setProject,
    measurements,
    drawing,
    drawingRectangle, setDrawingRectangle,
    drawingPolygon, setDrawingPolygon,
    selectionCalculator, setSelectionCalculator,
    drawingLine, setDrawingLine,
    drawingCount, setDrawingCount,
    drawingScale, setDrawingScale,
    drawingCircle, setDrawingCircle,
    addingCount, setAddingCount,
    deletingCount, setDeletingCount,
    erasingCount,
    cuttingPolygon, setCuttingPolygon,
    cuttingRectangle, setCuttingRectangle,
    splittingPolygon, setSplittingPolygon,
    splittingRectangle, setSplittingRectangle,
    cuttingPolygonRect, setCuttingPolygonRect,
    cuttingRectangleRect, setCuttingRectangleRect,
    AIAutoCountRect, setAIAutoCountRect,
    AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect,
    setCurrentMeasurement,
    handleZoom,
    handleResetZoom,
    selectedMeasurements, setSelectedMeasurements,
    selecting, setSelecting,
    previewURL, setPreviewURL,
    showMeasurementsSidebar,
    settings,
    takeoffSettings, setTakeoffSettings,
    draggingPage, setDraggingPage,
    scrollingPage, setScrollingPage,
    AIAutoClassifying, setAIAutoClassifying,
    AILocalization, setAILocalization,
    setHintingSAM, setAIAutoFinding,
    AIAutoFinding,
    SAMInferencing,
    hintingSAM,
    hintingLength,
    LengthAssignment,
    stopWatch,
    hintingAutoMeasure,
    hintingAutoMeasurePerim,
    hintingAutoFindDW,
    AIAutoCountPoly, setAIAutoCountPoly,
    AIAutoCountExample,
    selectingLegend,
    drawingLegend,
    drawingLegendEntry,
    drawingLegendEntryRun,
    handleTurnOffDrawing,
    globalHide, setGlobalHide,
    stageRef,
    drawingAnnotationArrow,
    drawingAnnotationText,
    drawingAnnotationRectangle,
    drawingAnnotationRuler,
    drawingAnnotationCloud,
    currentMeasurement,
    AIClassAgLength,
    handleCreateLengths,
  } = useContext(TakeoffContext);

  const auth = useSelector(selectAuth);

  const [image, status] = useImage(PROXYURL + url, "Anonymous", 'origin');

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    x: 0,
    y: 0,
  });

  const [selection, setSelection] = useState(null);

  const [cursor, setCursor] = useState("default");

  /*
  useEffect(() => {   
        if (url) {
            const img = new window.Image();
            img.src = PROXYURL + url;
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                setImage(img);
            };
        }
    }, [url]);

    useEffect(() => {
        if (page && groups) {
            setPreviewURL(stageRef.current.toDataURL({ pixelRatio: 1/4 }));
        }
    }, [page, groups]);*/

  //useEffect to listen to 'escape' key to set cursor back to default
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        //use stageRef to get the stage and set the cursor back to default
        stageRef.current.container().style.cursor = "default";
      }

      window.removeEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    };
  }, [stageRef, showContextMenu]);


  useEffect(() => {
    if (stageRef?.current) {
      if (drawingRectangle) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
      } else if (drawingPolygon || selectionCalculator) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"
      } else if (drawingLine) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+length.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+length.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+length.svg') 8 24, auto"
      } else if (drawingCount) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"
      } else if (drawingCircle) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+circle.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+circle.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+circle.svg') 8 24, auto"
      } else if (drawingScale) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+scale.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+scale.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+scale.svg') 8 24, auto"
      } else if (cuttingPolygon) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto"
      } else if (cuttingPolygonRect) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto"
      } else if (splittingPolygon) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+polygon.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+polygon.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+polygon.svg') 8 24, auto"
      } else if (cuttingRectangle) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto"
      } else if (cuttingRectangleRect) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto"
      } else if (splittingRectangle) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+rectangle.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+rectangle.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+rectangle.svg') 8 24, auto"
      } else if (addingCount) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/add+count.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/add+count.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/add+count.svg') 8 24, auto"
      } else if (deletingCount) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count.svg') 2 18, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count.svg') 2 18, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count.svg') 2 18, auto"
      } else if (AIAutoCountPoly?.[pageID]?.show) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"
      } else if (AIAutoCountRect?.[pageID]?.show
        || hintingAutoMeasure?.[pageID]?.show
        || hintingAutoMeasurePerim?.[pageID]?.show
        || hintingAutoFindDW?.[pageID]?.show
        || erasingCount
        || AIAutoMeasureLengthRect?.[pageID]?.show
      ) {
        document.body.style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
        setCursor("url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto")
        stageRef.current.container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
      } else if (hintingSAM
        || drawingAnnotationArrow
        || drawingAnnotationCloud
        || drawingAnnotationText
        || drawingAnnotationRectangle
        || drawingAnnotationRuler
        || hintingLength
        || LengthAssignment
      ) {
        document.body.style.cursor = "crosshair";
        setCursor("crosshair");
        stageRef.current.container().style.cursor = "crosshair";

        /*if (SAMInferencing) {
          document.body.style.cursor = "progress";
          setCursor("progress");
          stageRef.current.container().style.cursor = "progress";
        }*/
      }  /*else if (AIAutoClassifying || SAMInferencing) {
        document.body.style.cursor = "progress";
        setCursor("progress");
        stageRef.current.container().style.cursor = "progress";
      }*/ else {
        document.body.style.cursor = "default";
        setCursor("default");
        stageRef.current.container().style.cursor = "default";
      }
    }
  }, [stageRef, drawingRectangle, drawingPolygon, selectionCalculator, drawingLine, drawingCount, drawingCircle, drawingScale, cuttingPolygon, cuttingRectangle, splittingPolygon, splittingRectangle, addingCount, deletingCount, erasingCount, AIAutoCountPoly, AIAutoCountRect, AIAutoMeasureLengthRect]);


  const updatePagePosition = (stage, newPosition) => {
    axios({
      method: "put",
      url: `${API_ROUTE}/api/pages/${pageID}/`,
      headers: {
        Authorization: `Token ${auth.token}`,
        "Content-Type": "application/json",
      },
      data: {
        project: pages[pageID].project,
        position_x: newPosition.x,
        position_y: newPosition.y,
        zoom: pages[pageID].zoom,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const debounceUpdatePagePosition = useCallback(debounce(updatePagePosition, 1000), []);

  function handleMouseDown(e) {
    e.evt.preventDefault();

    if (e.evt.shiftKey && selection === null) {
      const stage = e.target.getStage();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const pos = {
        x: pointerX - stage.x(),
        y: pointerY - stage.y(),
      };

      setSelection({
        startX: pos.x,
        startY: pos.y,
        endX: pos.x,
        endY: pos.y,
        x: pos.x,
        y: pos.y,
        width: 0,
        height: 0,
      });
    }
  }

  function handleMouseMove(e) {
    if (e.evt.shiftKey && selection !== null) {
      const stage = e.target.getStage();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const pos = {
        x: pointerX - stage.x(),
        y: pointerY - stage.y(),
      };

      setSelection({
        ...selection,
        endX: pos.x,
        endY: pos.y,
        x: Math.min(selection.startX, pos.x),
        y: Math.min(selection.startY, pos.y),
        width: Math.abs(selection.startX - pos.x),
        height: Math.abs(selection.startY - pos.y),
      });
    }
  }

  function handleMouseUp(e) {
    e.evt.preventDefault();
    if (selection !== null) {
      if (LengthAssignment) {
        const selectedLengths = getSelectedAILengths(
          AIClassAgLength,
          selection,
          pages[pageID],
        );

        console.log(selectedLengths);
        handleCreateLengths(selectedLengths);
      } else {
        const selectedEntities = getSelected(
          selection,
          measurements,
          pages[pageID],
          groups,
        );
        setSelectedMeasurements([...selectedEntities]);
      }
      setSelection(null);
    }
  }

  const handleWheel = (e) => {
    setScrollingPage(true);
    e.evt.preventDefault();

    if (e.evt.ctrlKey) {
      e.evt.preventDefault();

      let scaleBy = 1 + Number(settings.scroll_sensitivity || 0.05) * (settings.invert_scroll ? -1 : 1);

      if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        scaleBy = 1 - Number(settings.scroll_sensitivity || 0.05) * (settings.invert_scroll ? -1 : 1);
      }

      const stage = e.target.getStage();

      const oldScale = stage.scaleX();

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };

      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      newScale = Math.min(Math.max(0.025, newScale), 25);

      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
      };

      setPages((prev) => ({
        ...prev,
        [pageID]: {
          ...prev[pageID],
          position_x: newPos.x,
          position_y: newPos.y,
          zoom: newScale,
        },
      }))
    } else if (e.evt.shiftKey) {
      const stage = e.target.getStage();

      const newPosition = stage.position();

      setPages((prev) => ({
        ...prev,
        [pageID]: {
          ...prev[pageID],
          position_x: newPosition.x - e.evt.deltaY * (settings.invert_scroll ? -1 : 1),
          position_y: newPosition.y - e.evt.deltaX * (settings.invert_scroll ? -1 : 1),
        },
      }));
    } else {
      const stage = e.target.getStage();
      const newPosition = stage.position();

      setPages((prev) => ({
        ...prev,
        [pageID]: {
          ...prev[pageID],
          position_x: newPosition.x - e.evt.deltaX * (settings.invert_scroll ? -1 : 1),
          position_y: newPosition.y - e.evt.deltaY * (settings.invert_scroll ? -1 : 1),
        },
      }));
    }

    debouncedHandleSave();

    setTimeout(() => {
      setScrollingPage(false);
    }, 100);
  };

  const handleSave = () => {
    axios({
      method: "put",
      url: `${API_ROUTE}/api/pages/${pageID}/`,
      headers: {
        Authorization: `Token ${auth.token}`,
        "Content-Type": "application/json",
      },
      data: {
        project: pages[pageID].project,
        position_x: pages[pageID].position_x,
        position_y: pages[pageID].position_y,
        zoom: pages[pageID].zoom,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const debouncedHandleSave = useCallback(debounce(handleSave, 1000), []);

  const handleWheelEnd = (e) => {
    //scrolling left and right, up and down pans the page
    //only zooms in and out if ctrl is pressed

    e.evt.preventDefault();

    if (e.evt.ctrlKey) {
      e.evt.preventDefault();

      let scaleBy = 1.05;

      if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
        scaleBy = 1.02;
      }

      const stage = e.target.getStage();

      const oldScale = stage.scaleX();

      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };

      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      newScale = Math.min(Math.max(0.025, newScale), 25);

      const newPos = {
        x:
          -(mousePointTo.x - stage.getPointerPosition().x / newScale) *
          newScale,
        y:
          -(mousePointTo.y - stage.getPointerPosition().y / newScale) *
          newScale,
      };

      setPages((prev) => ({
        ...prev,
        [pageID]: {
          ...prev[pageID],
          position_x: newPos.x,
          position_y: newPos.y,
          zoom: newScale,
        },
      }));

      axios({
        method: "put",
        url: `${API_ROUTE}/api/pages/${pageID}/`,
        headers: {
          Authorization: `Token ${auth.token}`,
          "Content-Type": "application/json",
        },
        data: {
          project: pages[pageID].project,
          position_x: newPos.x,
          position_y: newPos.y,
          zoom: newScale,
        },
      })
        .then((res) => {
          console.log(res);

          setScrollingPage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const stage = e.target.getStage();
      const newPosition = stage.position();

      setPages((prev) => ({
        ...prev,
        [pageID]: {
          ...prev[pageID],
          position_x: newPosition.x - e.evt.deltaX,
          position_y: newPosition.y - e.evt.deltaY,
        },
      }));

      axios({
        method: "put",
        url: `${API_ROUTE}/api/pages/${pageID}/`,
        headers: {
          Authorization: `Token ${auth.token}`,
          "Content-Type": "application/json",
        },
        data: {
          project: pages[pageID].project,
          position_x: newPosition.x - e.evt.deltaX,
          position_y: newPosition.y - e.evt.deltaY,
          zoom: pages[pageID].zoom,
        },
      })
        .then((res) => {
          console.log(res);

          setScrollingPage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return false;
  };

  const handleDragMove = (e) => {
    const stage = e.target.getStage();
    const newPosition = stage.position();

    setPages((prev) => ({
      ...prev,
      [pageID]: {
        ...prev[pageID],
        position_x: newPosition.x,
        position_y: newPosition.y,
      },
    }));
  };

  const handleDragEnd = (e) => {
    setDraggingPage(false);
    const stage = e.target.getStage();
    const newPosition = stage.position();

    debounceUpdatePagePosition(stage, newPosition);
  };

  const WW = useMemo(() => {
    let windowWidth = window.innerWidth;

    if (takeoffSettings?.show_pages_sidebar) {
      windowWidth -= document.getElementById("pagessidebar")?.offsetWidth;
    }
    if (takeoffSettings?.show_measurement_sidebar) {
      windowWidth -= document.getElementById("measurementsidebar")?.offsetWidth;
    }
    if (takeoffSettings?.show_ai_sidebar) {
      windowWidth -= document.getElementById("aisidebar")?.offsetWidth;
    }

    return windowWidth;
  }, [takeoffSettings?.show_pages_sidebar, takeoffSettings?.show_measurement_sidebar, takeoffSettings?.show_ai_sidebar]);

  const BL = useMemo(() => {
    if (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left') {
      return document.getElementById("pagessidebar")?.offsetWidth / pages[pageID].zoom
    } else if (takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left') {
      return document.getElementById("measurementsidebar")?.offsetWidth / pages[pageID].zoom
    } else if (takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left') {
      return document.getElementById("aisidebar")?.offsetWidth / pages[pageID].zoom
    } else {
      return 0
    }
  }, [takeoffSettings?.show_pages_sidebar, takeoffSettings?.pages_sidebar_location, takeoffSettings?.show_measurement_sidebar, takeoffSettings?.measurement_sidebar_location, takeoffSettings?.show_ai_sidebar, takeoffSettings?.ai_sidebar_location])

  const AIActive = useMemo(() => {
    return AIAutoFinding?.[pageID]?.show
      || hintingSAM
      || hintingLength
      || LengthAssignment
      || AIAutoCountRect?.[pageID]?.show
      || AIAutoCountPoly?.[pageID]?.show
      || hintingAutoMeasure?.[pageID]?.show
      || hintingAutoMeasurePerim?.[pageID]?.show
      || hintingAutoFindDW?.[pageID]?.show
      || selectingLegend
      || drawingLegend
      || drawingLegendEntry
      || drawingLegendEntryRun
      || AIAutoCountExample?.[pageID]?.show
      || AIAutoMeasureLengthRect?.[pageID]?.show
  }, [pageID, AIAutoFinding, hintingSAM, hintingLength, LengthAssignment, AIAutoCountRect, AIAutoCountPoly, AIAutoMeasureLengthRect, hintingAutoMeasure, hintingAutoMeasurePerim, hintingAutoFindDW, selectingLegend, drawingLegend, drawingLegendEntry, drawingLegendEntryRun, AIAutoCountExample]);

  let shouldDrawCursor = drawingRectangle
    || drawingPolygon || selectionCalculator
    || drawingLine || drawingCount || drawingScale || drawingCircle
    || cuttingPolygon || cuttingRectangle || splittingPolygon || splittingRectangle || cuttingPolygonRect || cuttingRectangleRect
    || addingCount || deletingCount
    || AIAutoCountPoly?.[pageID]?.show
    || AIAutoCountRect?.[pageID]?.show || hintingAutoMeasure?.[pageID]?.show || hintingAutoMeasurePerim?.[pageID]?.show || hintingAutoFindDW?.[pageID]?.show || erasingCount || AIAutoMeasureLengthRect?.[pageID]?.show
    || hintingSAM || drawingAnnotationArrow || drawingAnnotationText || drawingAnnotationRectangle || drawingAnnotationRuler || drawingAnnotationCloud || hintingLength;

  useEffect(() => {
    document.addEventListener('mousemove', e => {
      const cursorVT = document.querySelector('.takeoffplan-drawing-cursor-vt')
      const cursorHL = document.querySelector('.takeoffplan-drawing-cursor-hl')

      if (cursorVT && cursorHL && shouldDrawCursor) {
        cursorVT.setAttribute('style', `left: ${e.clientX}px; border-color: ${settings?.cursor_guides_color || '#FFFFFF'}; box-shadow: 0px 0px 3px 0px ${settings?.cursor_guides_color || '#808080'};`)
        cursorHL.setAttribute('style', `top: ${e.clientY}px; border-color: ${settings?.cursor_guides_color || '#FFFFFF'}; box-shadow: 0px 0px 3px 0px ${settings?.cursor_guides_color || '#808080'};`)
      }
    })

    return () => {
      document.removeEventListener('mousemove', () => { })
    }
  }, [shouldDrawCursor])

  const handleToggleExportLegend = () => {
    let newState = !pages[pageID].export_legend;

    setPages((prev) => ({
      ...prev,
      [pageID]: {
        ...prev[pageID],
        export_legend: newState,
      },
    }));

    axios({
      method: 'put',
      url: `${API_ROUTE}/api/pages/${pageID}/`,
      data: {
        'project': pages[pageID].project,
        'export_legend': newState,
      },
      headers: {
        'Authorization': `Token ${auth.token}`,
        "Content-Type": "application/json"
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  if (status === "loading") {
    return (
      <div className="takeoffplan-loading-container">
        <Blocks
          visible={true}
          height="60"
          width="60"
          color="#006AFE"
          ariaLabel="blocks-loading"
          radius="10"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
        />
      </div>
    );
  }

  return (
    <div
      className="takeoffplan-container"
      style={{
        cursor: cursor,
        border: AIActive ? "1px solid #006AFF" : "none",
      }}
    >
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        scaleX={pages[pageID]?.zoom}
        scaleY={pages[pageID]?.zoom}
        x={pages[pageID]?.position_x}
        y={pages[pageID]?.position_y}
        ref={stageRef}
        onWheel={(e) => handleWheel(e)}
        onWheelEnd={(e) => handleWheelEnd(e)}
        //draggable={!drawing && !selecting}
        draggable={!selecting}
        listening={!selecting}
        onDragStart={() => {
          setDraggingPage(true)
        }}
        onDragMove={(e) => {
          //if (!drawing) {
          handleDragMove(e);
          //}
        }}
        onMouseOver={(e) => {
          //if the mouse cordinates are outside the stage, set the cursor to default
          if (e.target.x() < 0 ||
            e.target.y() < 0 ||
            e.target.x() > pages[pageID].width * pages[pageID].zoom ||
            e.target.y() > pages[pageID].height * pages[pageID].zoom
          ) {
            e.target.getStage().container().style.cursor = "default";
          } else {
            if (drawingRectangle) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
            } else if (drawingPolygon || selectionCalculator) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"
            } else if (drawingLine) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+length.svg') 8 24, auto"
            } else if (drawingCount) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"
            } else if (drawingCircle) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+circle.svg') 8 24, auto"
            } else if (drawingScale) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+scale.svg') 8 24, auto"
            } else if (cuttingPolygon) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon+cursor.svg') 8 24, auto"
            } else if (cuttingPolygonRect) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto"
            } else if (splittingPolygon) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+polygon.svg') 8 24, auto"
            } else if (cuttingRectangle) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon.svg') 8 24, auto"
            } else if (cuttingRectangleRect) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+rect+cursor.svg') 8 24, auto"
            } else if (splittingRectangle) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+rectangle.svg') 8 24, auto"
            } else if (addingCount) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/add+count.svg') 8 24, auto"
            } else if (deletingCount) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/delete+count.svg') 2 18, auto"
            } else if (AIAutoCountPoly?.[pageID]?.show) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+polygon.svg') 8 24, auto"
            } else if (AIAutoCountRect?.[pageID]?.show
              || hintingAutoMeasure?.[pageID]?.show
              || hintingAutoMeasurePerim?.[pageID]?.show
              || hintingAutoFindDW?.[pageID]?.show
              || erasingCount
              || AIAutoMeasureLengthRect?.[pageID]?.show
            ) {
              e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
            } else if (hintingSAM
              || drawingAnnotationArrow
              || drawingAnnotationCloud
              || drawingAnnotationText
              || drawingAnnotationRectangle
              || drawingAnnotationRuler
              || hintingLength
            ) {
              e.target.getStage().container().style.cursor = "crosshair";

              if (SAMInferencing) {
                //e.target.getStage().container().style.cursor = "progress";
              }
            } else if (AIAutoClassifying || SAMInferencing) {
              //e.target.getStage().container().style.cursor = "progress";
            } else {
              e.target.getStage().container().style.cursor = "default";
            }
          }
        }}
        onDragEnd={(e) => {
          handleDragEnd(e)
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <Layer
          listening={true}
        >
          <Rect
            x={0 - pages[pageID]?.position_x / pages[pageID]?.zoom - 250}
            y={0 - pages[pageID]?.position_y / pages[pageID]?.zoom - 250}
            width={window.innerWidth / pages[pageID]?.zoom + 500}
            height={window.innerHeight / pages[pageID]?.zoom + 500}
            fill="rgb(115, 147, 179, 0.3)"
          />

          <Html>
            {showContextMenu && (
              <ContextMenu
                x={contextMenuPosition.x}
                y={contextMenuPosition.y}
                zoom={pages[pageID].zoom}
                showContextMenu={showContextMenu}
                setShowContextMenu={setShowContextMenu}
              >
                <div
                  className="contextmenu-item"
                  onClick={() => {
                    handleTurnOffDrawing();

                    setCurrentMeasurement(null);

                    setDrawingRectangle(true);
                  }}
                >
                  <IconRectangle size={16} stroke={1} />
                  <div>
                    Add rectangle
                  </div>
                </div>

                <div
                  className="contextmenu-item"
                  onClick={() => {
                    handleTurnOffDrawing();

                    setCurrentMeasurement(null);

                    setDrawingPolygon(true);
                  }}
                >
                  <IconPolygon size={16} stroke={1} />
                  <div>
                    Add polygon
                  </div>
                </div>

                <div
                  className="contextmenu-item"
                  onClick={() => {
                    handleTurnOffDrawing();

                    setCurrentMeasurement(null);

                    setDrawingCircle(true);
                  }}
                >
                  <IconCircleDot size={16} stroke={1} />
                  <div>
                    Add circle
                  </div>
                </div>

                <div
                  className="contextmenu-item"
                  onClick={() => {
                    handleTurnOffDrawing();

                    setCurrentMeasurement(null);

                    setDrawingLine(true);
                  }}
                >
                  <IconTimeline size={16} stroke={1} />
                  <div>
                    Add line
                  </div>
                </div>

                <div
                  className="contextmenu-item"
                  onClick={() => {
                    handleTurnOffDrawing();

                    setCurrentMeasurement(null);

                    setDrawingCount(true);
                    stopWatch.reset();
                  }}
                >
                  <IconCircles size={16} stroke={1} />
                  <div>
                    Add count
                  </div>
                </div>

                <div
                  className="contextmenu-item"
                  onClick={() => {
                    handleTurnOffDrawing();

                    setDrawingScale(true);
                  }}
                >
                  <IconRulerMeasure size={16} stroke={1} />
                  <div>
                    Scale
                  </div>
                </div>

                <div className="contextmenu-item" onClick={() => handleZoom(1.2)}>
                  <IconZoomIn size={16} stroke={1} />
                  <div>
                    Zoom in
                  </div>
                </div>

                <div className="contextmenu-item" onClick={() => handleZoom(0.8)}>
                  <IconZoomOut size={16} stroke={1} />
                  <div>
                    Zoom out
                  </div>
                </div>

                <div className="contextmenu-item" onClick={() => handleResetZoom()}>
                  <IconHome size={16} stroke={1} />
                  <div>
                    Reset zoom
                  </div>
                </div>

                <div className="contextmenu-item" onClick={() => handleToggleExportLegend()}>
                  <IconFileExport size={16} stroke={1} />
                  <div>
                    Export Legend
                  </div>
                </div>
              </ContextMenu>
            )}
          </Html>

          {status === "loaded" && <KonvaImage
            image={image}
            onContextMenu={(e) => {
              e.evt.preventDefault();
              setContextMenuPosition({
                x:
                  (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) /
                  pages[pageID].zoom,
                y:
                  (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) /
                  pages[pageID].zoom,
              });
              setShowContextMenu(true);
            }}
          />}
        </Layer>
        {!globalHide &&
          <Layer
          //listening={!draggingPage}
          >
            {children}
          </Layer>
        }
        <Layer name="top-layer" />
        <Layer name="selection-layer">
          {pages[pageID]?.export_legend &&
            <ExportLegend />
          }

          {selection && (
            <Rect
              fill="rgba(86, 204, 242, 0.1)"
              stroke="#2d9cdb"
              strokeWidth={1 / pages[pageID].zoom}
              x={selection.x / pages[pageID].zoom}
              y={selection.y / pages[pageID].zoom}
              width={selection.width / pages[pageID].zoom}
              height={selection.height / pages[pageID].zoom}
            />
          )}

          {/*!draggingPage && pages[pageID]?.width && pages[pageID]?.zoom && WW && !(pages[pageID]?.width * pages[pageID]?.zoom < window.innerWidth * 1.5 && pages[pageID]?.height * pages[pageID]?.zoom < window.innerHeight * 1.5) ?
            <>
              <Rect
                x={(-pages[pageID].position_x + window.innerWidth) / pages[pageID].zoom -
                  (takeoffSettings?.show_measurement_sidebar
                    ? takeoffSettings?.show_pages_sidebar && !takeoffSettings?.pages_sidebar_location === 'left'
                      ? Math.max((document.getElementById("pagessidebar")?.offsetWidth + 2) / pages[pageID].zoom, (document.getElementById("measurementsidebar")?.offsetWidth + 2) / pages[pageID].zoom)
                      : (document.getElementById("measurementsidebar")?.offsetWidth + 2) / pages[pageID].zoom
                    : takeoffSettings?.show_pages_sidebar && !takeoffSettings?.pages_sidebar_location === 'left'
                      ? (document.getElementById("pagessidebar")?.offsetWidth + 2) / pages[pageID].zoom
                      : 2 / pages[pageID].zoom)
                }
                y={-pages[pageID].position_y / pages[pageID].zoom - ((pages[pageID].position_y / pages[pageID].zoom) * (window.innerHeight / pages[pageID].height)) / pages[pageID].zoom}
                width={1 / pages[pageID].zoom}
                height={(window.innerHeight * window.innerHeight) / (pages[pageID].height * pages[pageID].zoom * pages[pageID].zoom)}
                stroke="#006AFE"
                strokeWidth={1 / pages[pageID].zoom}
              />

              <Rect
                x={(-pages[pageID].position_x) / pages[pageID].zoom + BL + (-pages[pageID].position_x * WW) / (pages[pageID].width * pages[pageID].zoom * pages[pageID].zoom)}
                y={(-pages[pageID].position_y + window.innerHeight) / pages[pageID].zoom - (window.innerHeight * 0.04 + 1) / pages[pageID].zoom}
                width={(WW * WW) / (pages[pageID].width * pages[pageID].zoom * pages[pageID].zoom)}
                height={1 / pages[pageID].zoom}
                stroke="#006AFE"
                strokeWidth={2 / pages[pageID].zoom}
              />
            </>
              : null*/}
        </Layer>
      </Stage>

      {/*crosshair lines that extend left and right of the user's cursor all the way up and down, left and right of the screen */}
      {(shouldDrawCursor && settings?.cursor_guides) &&
        <div class="takeoffplan-drawing-cursor">
          <div class="takeoffplan-drawing-cursor-vt"></div>
          <div class="takeoffplan-drawing-cursor-hl"></div>
        </div>
      }
    </div>
  );
}
