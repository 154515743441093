import { forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_ROUTE } from '../..';

import { Modal } from 'react-bootstrap';
import { selectAuth } from '../../redux/slices/authSlice';
import {
  IconBrandDatabricks,
  IconCaretRight,
  IconChecks,
  IconCopyPlus,
  IconFolder,
  IconFolderOpen,
  IconSearch,
  IconSquare,
  IconSquareCheckFilled,
  IconSquaresDiagonal,
  IconX,
} from '@tabler/icons-react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { FolderTreeItemWrapper, SortableTree } from 'dnd-kit-sortable-tree';
import { IconCaretDown } from '@tabler/icons-react';
import { DatabaseContext } from './context';
import { Resizable } from 'react-resizable';
import { AssemblyCalculatedFields, CalculateValue, InputCalculated, InputEditable } from '../../database/components/Utils';
import { Tooltip } from 'react-tooltip';
import { Blocks } from 'react-loader-spinner';
import { numToStr2Places } from '../EstimateUtilities';
import { andThen, compose, concat, defaultTo, equals, filter, isNotNil, keys, lensProp, map, pipe, prop, reduce, set, uniq, view } from 'ramda';
import { axiosCurry, createLookupByKeyName, responseDataLens } from '../../utilities/utilities';

const nodesLens = lensProp('nodes');

const initialState = { nodes: {}, tagUuids: [], tagEditorNodeUuid: null };

const getNodeDatabaseItemTagUuidsLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('database_item_tag_uuids'));
const getNodeTextLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid), lensProp('text'));

export default function DatabasePopup({ show, onHide, item, setItem }) {
  const auth = useSelector(selectAuth);

  const [searching, setSearching] = useState(false);

  const [tree, setTree] = useState(null);
  const [columns, setColumns] = useState(null);
  const [column_settings, setColumnSettings] = useState(null);

  const [assemblyEntries, setAssemblyEntries] = useState(null);

  const [hoverID, setHoverID] = useState(null);
  const [search, setSearch] = useState('');

  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);

  useEffect(() => {
    handleSearch('');
  }, []);

  const handleSearch = (s) => {
    setSearching(true);

    axios({
      method: 'post',
      url: `${API_ROUTE}/api/database-search/`,
      data: {
        search: s,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        setTree([...response.data.tree]);

        let columnsTemp = {};

        Object.keys(response.data.columns).forEach((column) => {
          columnsTemp[column] = true;
        });

        setColumns(columnsTemp);

        let column_settings = {};
        Object.keys(response.data.column_settings).forEach((column) => {
          if (column.includes('width')) {
            column_settings[column] = column.includes('name') ? 300 : 200;
          } else {
            column_settings[column] = response.data.column_settings[column];
          }
        });
        setColumnSettings(column_settings);

        setSearching(false);
        setAssemblyEntries(response.data.assembly_entries);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleImport = (databaseItem) => {
    setItem({ database_id: databaseItem.id, properties: pipe(filter(equals(true)), keys)(columns) });

    // Object.keys(columns).forEach((column) => {
    //   if (columns[column]) {
    //     newValues[column] = databaseItem[column]
    //       ? databaseItem[column]
    //       : (column === 'material_cost' || column === 'labor_amount') && databaseItem.type === 'assembly'
    //       ? CalculateValue(column, databaseItem, assemblyEntries)
    //       : null;
    //     /*databaseItem.id.includes('entry')
    //                 && (databaseItem.type === 'entry' && InputCalculated[column] || databaseItem.type === 'assembly' && AssemblyCalculatedFields[column])
    //                 && InputEditable[column]
    //                 ? CalculateValue(column, databaseItem, assemblyEntries)
    //                 : null;*/
    //   }
    // });

    // setItem(newValues);
    onHide();
    //update measurements in estimate table state TODO
    /*
            axios({
                method: 'put',
                url: `${API_ROUTE}/api/measurements/${item.id}/`,
                data: {
                    ...item,
                    ...newValues,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });*/
  };

  const visibleIDs = useMemo(() => {
    if (!tree) return [];

    const flattened = [];

    const dfs = (node) => {
      flattened.push(node);

      if (node.children && !node.collapsed) {
        node.children.forEach((child, i) => {
          dfs(child);
        });
      }
    };

    tree.forEach((node, i) => {
      dfs(node);
    });

    return flattened;
  }, [tree]);

  const entryIdUuidMap = useMemo(() => pipe(createLookupByKeyName('id'), map(prop('uuid')))(tree), [tree]);
  const getUuidFromEntryID = (entryID) => entryIdUuidMap[entryID];

  const databaseItemTagAPI = useMemo(() =>
    axiosCurry(`${API_ROUTE}/api/database-item-tags/`)({
      Authorization: `Token ${auth.token}`,
      'Content-Type': 'application/json',
    })('post')
  );

  const refreshTagData = () => {
    pipe(
      databaseItemTagAPI,
      andThen(view(responseDataLens)),
      andThen(createLookupByKeyName('uuid')),
      andThen(set(nodesLens)),
      andThen(setState)
    )([
      { operation: 'database_entries_generate_uuids' },
      { operation: 'database_assembly_entries_generate_uuids' },
      { operation: 'database_entries_get' },
      { operation: 'database_assembly_entries_get' },
    ]);
  };

  useEffect(() => {
    if (auth.token) refreshTagData();
  }, [auth.token]);

  return (
    <>
      <Modal show={show} onHide={onHide} centered size={'xl'} dialogClassName='vendorquotes-modal-dialog' contentClassName='vendorquotes-modal-content'>
        <Modal.Header
          style={{
            padding: '5px 20px',
          }}
        >
          <div className='vendorquotes-title'>
            <IconBrandDatabricks size={20} /> Cost database
          </div>
          <div className='database-navbar-search'>
            {searching ? <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' wrapperStyle={{}} wrapperClass='blocks-wrapper' /> : <IconSearch size={20} />}

            <div className='database-navbar-search-container'>
              <input
                id='database-navbar-search'
                type='text'
                placeholder='Search...'
                className='database-navbar-search-input'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onBlur={(e) => handleSearch(search)}
              />

              <div
                id='database-navbar-search-clear'
                className='database-navbar-search-clear'
                onClick={() => {
                  setSearch('');
                  handleSearch('');
                }}
              >
                <IconX size={20} />
              </div>
            </div>
          </div>

          <div className='database-navbar-buttons'>
            <div
              className='vendorquotes-close-button'
              onClick={() =>
                setColumns((prev) => {
                  let newColumns = {};

                  Object.keys(prev).forEach((column) => {
                    newColumns[column] = true;
                  });

                  return newColumns;
                })
              }
            >
              <div>
                <IconChecks size={20} />
              </div>
              Select all
            </div>
            <div
              className='vendorquotes-close-button'
              onClick={() =>
                setColumns((prev) => {
                  let newColumns = {};

                  Object.keys(prev).forEach((column) => {
                    newColumns[column] = false;
                  });

                  return newColumns;
                })
              }
            >
              <IconSquaresDiagonal size={20} />
              Deselect all
            </div>
          </div>

          <div className='vendorquotes-close-button' onClick={onHide}>
            <IconX size={20} />
          </div>
        </Modal.Header>

        {columns && tree.length === 0 && !searching ? (
          <div className='vendorquotes-body'>
            <div className='database-empty-content'>
              <div className='database-empty-content-title'>Database is empty</div>
              <div className='database-empty-content-link'>
                <a href='/database'>Go to the Cost Database to add items</a>
              </div>
            </div>
          </div>
        ) : (
          <div className='vendorquotes-body'>
            {tree && columns && visibleIDs ? (
              <ScrollSync>
                <div className='database-columns'>
                  <div
                    className='database-column database-sidebar'
                    style={{
                      width: column_settings['name_width'] + 'px',
                    }}
                  >
                    <Resizable
                      axis='x'
                      handle={
                        <div
                          className='database-column-resize-handle'
                          onClick={(e) => e.stopPropagation()}
                          onDoubleClick={(e) =>
                            setColumnSettings((prev) => ({
                              ...prev,
                              name_width: 300,
                            }))
                          }
                          onContextMenu={(e) => {
                            e.preventDefault();
                            setColumnSettings((prev) => ({
                              ...prev,
                              name_width: 300,
                            }));
                          }}
                        >
                          &nbsp;
                        </div>
                      }
                      minConstraints={[300, 0]}
                      maxConstraints={[1000, Infinity]}
                      height={Infinity}
                      width={column_settings['name_width']}
                      onResize={(e, data) => {
                        setColumnSettings((prev) => ({
                          ...prev,
                          name_width: Math.max(300, data.size.width),
                        }));
                      }}
                      onResizeStop={(e, data) => {
                        setColumnSettings((prev) => ({
                          ...prev,
                          name_width: Math.max(300, data.size.width),
                        }));
                      }}
                    >
                      <div
                        className='database-column-header'
                        style={{
                          width: column_settings['name_width'] + 'px',
                        }}
                        onClick={() =>
                          setColumns((prev) => ({
                            ...prev,
                            ['name']: !columns['name'],
                          }))
                        }
                      >
                        {InputEditable['name'] &&
                          (columns['name'] ? (
                            <div className='database-columns-modal-item-icon'>
                              <IconSquareCheckFilled size={20} style={{ color: '#066aff' }} />
                            </div>
                          ) : (
                            <div className='database-columns-modal-item-icon'>
                              <IconSquare size={20} />
                            </div>
                          ))}
                        Name
                      </div>
                    </Resizable>

                    <ScrollSyncPane>
                      <div className='database-sidebar-content'>
                        <DatabaseContext.Provider
                          value={{
                            hoverID,
                            setHoverID,
                            handleImport,
                          }}
                        >
                          <SortableTree
                            items={tree}
                            onItemsChanged={(newTree, { type, item, draggedItem, draggedFromParent, droppedToParent }) => setTree(newTree)}
                            TreeItemComponent={TreeItem}
                            //keepGhostInPlace={settings?.sort}
                            disableSorting={true}
                          />
                        </DatabaseContext.Provider>
                      </div>
                    </ScrollSyncPane>
                  </div>

                  {columns &&
                    Object.keys(columns)
                      ?.filter((columnID) => columnID !== 'name')
                      ?.map((columnID) => (
                        <div
                          key={columnID}
                          className='database-column'
                          style={{
                            width: column_settings[columnID + '_width'] + 'px',
                          }}
                        >
                          <Resizable
                            axis='x'
                            handle={
                              <div
                                className='database-column-resize-handle'
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) =>
                                  setColumnSettings((prev) => ({
                                    ...prev,
                                    [columnID + '_width']: 200,
                                  }))
                                }
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  setColumnSettings((prev) => ({
                                    ...prev,
                                    [columnID + '_width']: 200,
                                  }));
                                }}
                              >
                                &nbsp;
                              </div>
                            }
                            minConstraints={[200, 0]}
                            maxConstraints={[1000, Infinity]}
                            height={Infinity}
                            width={column_settings[columnID + '_width']}
                            onResize={(e, data) => {
                              setColumnSettings((prev) => ({
                                ...prev,
                                [columnID + '_width']: Math.max(200, data.size.width),
                              }));
                            }}
                            onResizeStop={(e, data) => {
                              setColumnSettings((prev) => ({
                                ...prev,
                                [columnID + '_width']: Math.max(200, data.size.width),
                              }));
                            }}
                          >
                            <div
                              id={'database-column-header-' + columnID}
                              className='database-column-header databasepopup-column-header'
                              style={{
                                width: column_settings[columnID + '_width'] + 'px',
                              }}
                              onClick={() =>
                                setColumns((prev) => ({
                                  ...prev,
                                  [columnID]: !columns[columnID],
                                }))
                              }
                            >
                              {InputEditable[columnID] &&
                                (columns[columnID] ? (
                                  <div className='database-columns-modal-item-icon'>
                                    <IconSquareCheckFilled size={20} style={{ color: '#066aff' }} />
                                  </div>
                                ) : (
                                  <div className='database-columns-modal-item-icon'>
                                    <IconSquare size={20} />
                                  </div>
                                ))}

                              {columnID.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
                            </div>
                          </Resizable>

                          {InputEditable[columnID] && (
                            <Tooltip anchorSelect={'#database-column-header-' + columnID} delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                              Select to import
                            </Tooltip>
                          )}

                          <ScrollSyncPane>
                            <div className='database-column-content'>
                              {visibleIDs?.map((entry) => (
                                <div
                                  key={entry.id}
                                  className={'database-entry databasepopup-entry'}
                                  onMouseEnter={() => setHoverID(entry.id)}
                                  onMouseLeave={() => setHoverID(null)}
                                  style={{
                                    backgroundColor: entry.id === hoverID ? '#EEEEEE' : '#F8F8F8',
                                    width: column_settings[columnID + '_width'] + 'px',
                                  }}
                                >
                                  {columnID == 'item_tags' ? (
                                    <div className='flex flex-row h-full gap-2 overflow-x-scroll overflow-y-hidden'>
                                      {pipe(
                                        getUuidFromEntryID,
                                        getNodeDatabaseItemTagUuidsLens,
                                        viewState,
                                        defaultTo([]),
                                        map((tagUuid) => (
                                          <div className='flex flex-row items-center h-full gap-1 p-2 text-xs text-white rounded-lg bg-blue-bobyard shrink-0 grow-0' key={tagUuid}>
                                            {pipe(getNodeTextLens, viewState)(tagUuid)}
                                          </div>
                                        ))
                                      )(entry.id)}
                                    </div>
                                  ) : entry[columnID] ? (
                                    entry[columnID]
                                  ) : entry.id.includes('entry') && ((entry.type === 'entry' && InputCalculated[columnID]) || (entry.type === 'assembly' && AssemblyCalculatedFields[columnID])) ? (
                                    <Item value={CalculateValue(columnID, entry, assemblyEntries)} />
                                  ) : (
                                    <>--</>
                                  )}
                                </div>
                              ))}
                            </div>
                          </ScrollSyncPane>
                        </div>
                      ))}
                </div>
              </ScrollSync>
            ) : null}
          </div>
        )}
      </Modal>
    </>
  );
}

const Item = ({ value }) => {
  return <>{isFinite(value) ? numToStr2Places(value) : 'ERROR'}</>;
};

const TreeItem = forwardRef((props, ref) => {
  const { hoverID, setHoverID, handleImport } = useContext(DatabaseContext);

  return (
    <FolderTreeItemWrapper
      {...props}
      ref={ref}
      manualDrag={true}
      showDragHandle={true}
      hideCollapseButton={true}
      style={{
        backgroundColor: hoverID === props.item.id ? '#EEEEEE' : '#F8F8F8',
        marginTop: '0px',
        marginBottom: '0px',
        height: '40px',
      }}
    >
      <div className='database-tree-entry' onMouseEnter={() => setHoverID(props.item.id)} onMouseLeave={() => setHoverID(null)}>
        <div
          className='database-tree-entry-icon'
          id={'database-tree-entry-icon-' + props.item.id}
          onClick={() => {
            if (!props.item.id.includes('group')) {
              handleImport(props.item);
            }
          }}
        >
          {props.item.id.includes('group') ? props.collapsed ? <IconFolder size={18} /> : <IconFolderOpen size={18} /> : <IconCopyPlus size={16} color='#066aff' />}
        </div>

        {props.item.id.includes('entry') && props.item.type === 'assembly' && (
          <div className='database-tree-entry-icon'>{props.collapsed ? <IconCaretRight size={16} color='#066aff' /> : <IconCaretDown size={16} color='#066aff' />}</div>
        )}

        {!props.item.id.includes('group') && (
          <Tooltip anchorSelect={'#database-tree-entry-icon-' + props.item.id} delayShow={500} positionStrategy='fixed' style={{ zIndex: 99999 }}>
            {props.item.id.includes('group') ? (props.collapsed ? 'Expand group' : 'Collapse group') : 'Import item to estimate'}
          </Tooltip>
        )}

        <div className='vendorquotes-tree-entry-name'>{props.item.name || <>--</>}</div>
      </div>
    </FolderTreeItemWrapper>
  );
});
