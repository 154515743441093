import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arc } from "react-konva";
import { useSelector } from "react-redux";
import axios from "axios";

import { API_ROUTE } from "../../../index";
import { selectAuth } from "../../../redux/slices/authSlice";
import { TakeoffContext } from "../../helper/Context";
import pSBC from "../../helper/Colors";
import { Portal } from "react-konva-utils";

const polygonClipping = require('polygon-clipping')

export default function RectangleCutterRect({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        measurements, setMeasurements,
        currentGroup,
        createMeasurement,
        cuttingRectangle, setCuttingRectangleRect,
        cuttingPolygonRect, setCuttingPolygonRect,
        currentMeasurement, setCurrentMeasurement,
        handlePolygonTransform,
        history, setHistory,
        currentHistory, setCurrentHistory,
        settings,
    } = useContext(TakeoffContext);

    const [start, setStart] = useState({ x: 0, y: 0 });
    const [end, setEnd] = useState({ x: 0, y: 0 });

    const rectToPoly = (m) => {
        let x = m.rectangle.x;
        let y = m.rectangle.y;
        let width = m.width;
        let height = m.height;

        return [[
            [x, y],
            [x + width, y],
            [x + width, y + height],
            [x, y + height],
        ]]
    }

    const handleCreatePolygon = (poly) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/polygon/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': measurements[currentMeasurement].group || null,
                'type': 'polygon',
                'color': measurements[currentMeasurement].color,
                'dots': poly.polygon_dots,
                'sub_polygons': poly.sub_polygons || null,
                'quantity1': measurements[currentMeasurement].quantity1,
                'quantity2': measurements[currentMeasurement].quantity2,
                'uom': measurements[currentMeasurement].uom,
                'offset': measurements[currentMeasurement].offset,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.token}`
            }
        })
            .then((res) => {
                console.log(res);

                createMeasurement(res.data, true);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCut = (points) => {
        let measurement = measurements[currentMeasurement];

        let polygon = rectToPoly(measurement);

        const difference = polygonClipping.difference(
            polygon,
            [[...points.map((point) => [point.x, point.y])]]
        );

        let tempHistory = [...history];
        let tempCurrentHistory = currentHistory;

        if (difference[0].length > 1) {
            let sub_polygons = {}

            difference[0].slice(1).forEach((poly, i) => {
                sub_polygons[i] = {
                    id: i,
                    polygon_dots: poly.slice(1).map((dot, j) => ({
                        id: j,
                        x: dot[0],
                        y: dot[1],
                    })),
                }
            })

            handlePolygonTransform({
                measurement: {
                    ...measurement,
                    type: "polygon",
                    polygon_dots: difference[0][0].slice(1).map((dot, i) => ({
                        id: i,
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                }, prev: null, disabled: true
            });

            tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

            tempHistory.push({
                action: "edit",
                previous: measurement,
                current: {
                    ...measurement,
                    type: "polygon",
                    polygon_dots: difference[0][0].slice(1).map((dot, i) => ({
                        id: i,
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                },
            });
            tempCurrentHistory++;

            setStart({ x: 0, y: 0 });
            setEnd({ x: 0, y: 0 });

            setCuttingRectangleRect(false);
            setCuttingPolygonRect(true);
        } else {
            tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

            tempHistory.push({
                action: "edit",
                previous: measurement,
                current: {
                    ...measurement,
                    type: "polygon",
                    polygon_dots: difference[0][0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                },
            });
            tempCurrentHistory++;

            handlePolygonTransform({
                measurement: {
                    ...measurement,
                    type: "polygon",
                    polygon_dots: difference[0][0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                }, prev: null, disabled: true
            });

            setStart({ x: 0, y: 0 });
            setEnd({ x: 0, y: 0 });

            setCuttingRectangleRect(false);
            setCuttingPolygonRect(true);
        }

        if (difference.length > 1) {
            difference.slice(1).forEach((poly) => {
                if (poly.length > 1) {
                    let sub_polygons = {}

                    poly.slice(1).forEach((poly, i) => {
                        sub_polygons[i] = {
                            id: i,
                            polygon_dots: poly.slice(1).map((dot, j) => ({
                                id: j,
                                x: dot[0],
                                y: dot[1],
                            })),
                        }
                    })

                    tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                    tempHistory.push({
                        action: "add",
                        previous: null,
                        current: {
                            ...measurement,
                            type: "polygon",
                            polygon_dots: poly[0].slice(1).map((dot, i) => ({
                                id: i,
                                x: dot[0],
                                y: dot[1],
                            })),
                            sub_polygons: sub_polygons,
                        },
                    });
                    tempCurrentHistory += 1;

                    handleCreatePolygon({
                        polygon_dots: poly[0].slice(1).map((dot, i) => ({
                            id: i,
                            x: dot[0],
                            y: dot[1],
                        })),
                        sub_polygons: sub_polygons,
                    });
                } else {
                    tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                    tempHistory.push({
                        action: "add",
                        previous: null,
                        current: {
                            ...measurement,
                            type: "polygon",
                            polygon_dots: poly[0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                            sub_polygons: {},
                        },
                    });
                    tempCurrentHistory += 1;

                    handleCreatePolygon({
                        polygon_dots: poly[0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                        sub_polygons: {},
                    });
                }
            })
        }

        console.log(tempHistory);
        console.log(tempCurrentHistory);

        setHistory(tempHistory);
        setCurrentHistory(tempCurrentHistory);
    }

    return (
        <Portal
            selector={'.selection-layer'}
            enabled={true}
        >
            {start.x !== 0 && start.y !== 0 && end.x !== 0 && end.y !== 0 &&
                <Rect
                    x={start.x}
                    y={start.y}
                    width={end.x - start.x}
                    height={end.y - start.y}
                    stroke={'red'}
                    fill={pSBC(0.5, measurements[currentMeasurement].color)}
                    opacity={0.5}
                    strokeWidth={1 / pages[pageID].zoom}
                    dash={[5 / pages[pageID].zoom, 5 / pages[pageID].zoom]}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/cut+polygon.svg') 8 24, auto"}
                onClick={(e) => {
                    if (e.evt.button !== 0) return;

                    if (start.x === 0) {
                        setStart({ x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom, y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom });
                    } else {
                        handleCut([
                            { x: start.x, y: start.y },
                            { x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom, y: start.y },
                            { x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom, y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom },
                            { x: start.x, y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom },
                        ]);
                    }
                }}
                onMouseMove={(e) => {
                    if (start.x !== 0) {
                        setEnd({ x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom, y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom });
                    }
                }}
            />
        </Portal>
    );
}