import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';

import './styles/Chat.css';
import { Blocks } from 'react-loader-spinner';
import {
  IconCaretDown,
  IconCaretRight,
  IconCircleCheckFilled,
  IconCloudUpload,
  IconCursorText,
  IconDownload,
  IconFile,
  IconFolderPlus,
  IconMenu2,
  IconProgressCheck,
  IconRobot,
  IconSearch,
  IconTableDown,
  IconTrashX,
  IconX,
} from '@tabler/icons-react';
import useWebSocket from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import ChatNavbar from './components/ChatNavbar';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import Popup from 'reactjs-popup';
import {
  allPass,
  always,
  andThen,
  append,
  compose,
  defaultTo,
  either,
  equals,
  F,
  filter,
  head,
  identity,
  ifElse,
  includes,
  insert,
  isEmpty,
  isNil,
  isNotEmpty,
  isNotNil,
  keys,
  last,
  lensProp,
  map,
  mergeDeepLeft,
  not,
  omit,
  over,
  pipe,
  prop,
  reject,
  set,
  sortBy,
  startsWith,
  toLower,
  tryCatch,
  view,
  when,
} from 'ramda';
import { axiosCurry, createLookupByKeyName, eventFilesLens, eventValueLens, formDataFromObj, getMimeType, mapIndexed, renameFile, responseDataLens, reverseView } from '../utilities/utilities';
import { Tree } from 'react-arborist';
import useResizeObserver from 'use-resize-observer';
import { Menu, useContextMenu } from 'react-contexify';
import ContextMenuItem from '../components/ContextMenuItem';
import { Button, ButtonGroup, Navbar, Stack, Form, InputGroup, Offcanvas, ToastContainer, Toast, ProgressBar, Modal } from 'react-bootstrap';

const SIDEBAR_CONTEXT_MENU_ID = 'sidebar-context-menu';

const sidebarLens = lensProp('sidebar');
const sidebarIsLoadingLens = compose(sidebarLens, lensProp('isLoading'));
const sidebarSortingMethodLens = compose(sidebarLens, lensProp('sortingMethod'));
const sidebarSelectedNodeUuidLens = compose(sidebarLens, lensProp('selectedNodeUuid'));
const sidebarContextMenuNodeUuidLens = compose(sidebarLens, lensProp('contextMenuNodeUuid'));
const searchLens = lensProp('search');
const searchTextLens = compose(searchLens, lensProp('text'));
const searchIsLoadingLens = compose(searchLens, lensProp('isLoading'));
const searchIsSyncedLens = compose(searchLens, lensProp('isSynced'));
const searchMatchedUuidsLens = compose(searchLens, lensProp('matchedUuids'));
const nodesLens = lensProp('nodes');
const uploadKeysLens = lensProp('uploadKeys');
const uploadObjectsLens = lensProp('uploadObjects');
const isToastSidebarOpenLens = lensProp('isToastSidebarOpen');
const nodeRenameModalLens = lensProp('nodeRenameModal');
const nodeRenameModalUuidLens = compose(nodeRenameModalLens, lensProp('uuid'));
const nodeRenameModalTextLens = compose(nodeRenameModalLens, lensProp('text'));

const getNodeLens = (nodeUuid) => compose(nodesLens, lensProp(nodeUuid));
const getNodeNameLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('name'));
const getNodeUuidLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('uuid'));
const getNodeChildUuidsLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('child_uuids'));
const getNodeParentUuidLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('parent_uuid'));
const getNodeIsFolderLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('is_folder'));
const getNodeFileUrlLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('file_url'));
const getNodeIndexLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('index'));
const getNodeIDLens = (nodeUuid) => compose(getNodeLens(nodeUuid), lensProp('id'));

const getUploadObjectMessageLens = (uploadKey) => compose(uploadObjectsLens, lensProp(uploadKey), lensProp('message'));
const getUploadObjectProgressLens = (uploadKey) => compose(uploadObjectsLens, lensProp(uploadKey), lensProp('progress'));

const initialState = {
  sidebar: {
    isLoading: true,
    sortingMethod: 'index',
    selectedNodeUuid: null,
    contextMenuNodeUuid: null,
    isSearchLoading: false,
  },
  search: {
    text: '',
    isLoading: false,
    isSynced: true,
    matchedUuids: new Set([]),
  },
  nodes: {},
  uploadKeys: [],
  uploadObjects: {},
  isToastSidebarOpen: false,
};

const openNodeRenameModal = (fileUuid) => (state) => {
  const nodeName = pipe(getNodeNameLens, reverseView(state))(fileUuid);
  return pipe(set(nodeRenameModalTextLens)(nodeName), set(nodeRenameModalUuidLens)(fileUuid))(state);
};

const closeNodeRenameModal = pipe(set(nodeRenameModalUuidLens)(null), set(nodeRenameModalTextLens)(''));

const fixIndices = (orderedNodeUuids) => pipe(identity, ...mapIndexed((nodeUuid, index) => set(getNodeIndexLens(nodeUuid))(index))(orderedNodeUuids));

const updateOrCreateUpload = (uploadKey) => (progress) => (message) =>
  pipe(
    when(pipe(view(uploadKeysLens), includes(uploadKey), not), pipe(over(uploadKeysLens)(append(uploadKey)))),
    set(getUploadObjectMessageLens(uploadKey))(message),
    set(getUploadObjectProgressLens(uploadKey))(progress)
  );
const removeUpload = (uploadKey) => (state) => pipe(over(uploadKeysLens)(reject(equals(uploadKey))), over(uploadObjectsLens)(omit([uploadKey])))(state);

const getOrderedUuidsAfterDeletion = (state) => (nodeUuid) => {
  const parentUuid = pipe(getNodeParentUuidLens, reverseView(state))(nodeUuid);
  if (isNil(parentUuid))
    return pipe(view(nodesLens), keys, filter(pipe(getNodeParentUuidLens, reverseView(state), isNil)), reject(equals(nodeUuid)), sortBy(pipe(getNodeIndexLens, reverseView(state))))(state);
  return pipe(getNodeChildUuidsLens, reverseView(state), reject(equals(nodeUuid)), sortBy(pipe(getNodeIndexLens, reverseView(state))))(parentUuid);
};

const getOrderedUuidsAfterInsertion = (state) => (index) => (parentUuid) => (nodeUuid) => {
  if (isNil(parentUuid)) {
    return pipe(
      view(nodesLens),
      keys,
      filter(pipe(getNodeParentUuidLens, reverseView(state), isNil)),
      sortBy(pipe(getNodeIndexLens, reverseView(state))),
      reject(equals(nodeUuid)),
      insert(index)(nodeUuid)
    )(state);
  }
  return pipe(getNodeChildUuidsLens, reverseView(state), sortBy(pipe(getNodeIndexLens, reverseView(state))), reject(equals(nodeUuid)), insert(index)(nodeUuid))(parentUuid);
};

export default function Chat() {
  const params = useParams();
  const projectUUID = params.projectUUID;
  const auth = useSelector(selectAuth);
  const projectFileNodesApi = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/project-file-nodes-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }),
    [API_ROUTE, auth.token, projectUUID]
  );
  const getAWSPresignedHeaders = (filename) =>
    pipe(axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post'), andThen(view(responseDataLens)))({ filename });
  const { ref: treeParentRef, height: treeHeight, width: treeWidth } = useResizeObserver();
  const { show: showProjectFileNodeContextMenu } = useContextMenu({ id: SIDEBAR_CONTEXT_MENU_ID });

  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);
  const sortFn = pipe(
    getNodeLens,
    viewState,
    prop(viewState(sidebarSortingMethodLens)),
    tryCatch(toLower, (_, value) => value)
  );

  const [chat, setChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState({});

  const [currentFile, setCurrentFile] = useState(null);
  const [currentFileUrl, setCurrentFileUrl] = useState(null);
  const [newMessage, setNewMessage] = useState('');

  const [loading, setLoading] = useState(true);
  const [loadingFile, setLoadingFile] = useState(true);

  const [gettingMessage, setGettingMessage] = useState(false);

  // initial file nodes fetch
  useEffect(() => {
    pipe(
      projectFileNodesApi('post'),
      andThen(view(responseDataLens)),
      andThen(last),
      andThen(createLookupByKeyName('uuid')),
      andThen(set(nodesLens)),
      andThen(setState)
    )([{ operation: 'project_file_nodes_generate_uuids' }, { operation: 'project_file_nodes_get' }]);
  }, []);

  function Node({ node, style, dragHandle }) {
    const nodeUuid = prop('id')(node);
    // width, height are autodetermined by the Tree component
    return (
      <div
        style={{ width: '100%', height: '100%', ...style }}
        onContextMenu={(event) => {
          showProjectFileNodeContextMenu({ event });
          pipe(set(sidebarContextMenuNodeUuidLens), setState)(nodeUuid);
          event.stopPropagation();
        }}
        onClick={(e) => {
          if (!pipe(getNodeIsFolderLens, viewState)(nodeUuid)) {
            let FileID = pipe(getNodeIDLens, viewState)(nodeUuid);
            let FileURL = pipe(getNodeFileUrlLens, viewState)(nodeUuid);

            if (FileID === null || FileID === undefined || FileID === currentFile) {
              return;
            }
            setCurrentFile(FileID);

            if (FileURL.slice(0, FileURL.lastIndexOf('?')).endsWith('.pdf')) {
              getFileURL(pipe(getNodeLens, viewState)(nodeUuid));
            } else {
              setLoadingFile(false);
            }
          }
        }}
      >
        <div
          className={`flex flex-row items-center w-full h-full gap-2 overflow-hidden cursor-pointer select-none rounded p-2 ${pipe(
            viewState,
            equals(nodeUuid),
            ifElse(identity, always('bg-blue-alice text-blue-bobyard'), always(''))
          )(sidebarSelectedNodeUuidLens)}`}
        >
          <div className='shrink-0' ref={dragHandle}>
            <IconMenu2 size={20} stroke={1} />
          </div>
          <div
            className='shrink-0'
            onClick={() => {
              node.toggle();
            }}
          >
            {pipe(getNodeIsFolderLens, viewState, (isFolder) => {
              if (!isFolder) return <IconFile size={20} stroke={1} />;
              if (node.isOpen) return <IconCaretDown size={20} stroke={1} />;
              return <IconCaretRight size={20} stroke={1} />;
            })(nodeUuid)}
          </div>
          <div className='overflow-hidden grow shrink text-ellipsis text-nowrap'>{pipe(getNodeNameLens, viewState)(node.id)}</div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_ROUTE}/api/chat/`,
      params: {
        contractor_uuid: auth.contractor.uuid,
        project_uuid: projectUUID,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // console.log(response);

        setChat(response.data.chat);
        setMessages(response.data.messages);
        setFiles(response.data.files);

        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [projectUUID]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(`${WEBSOCKET_ROUTE}/chatbot-consumer/${projectUUID}/`, {
    heartbeat: {
      message: 'ping',
      returnMessage: 'pong',
      timeout: 60000, // 1 minute, if no response is received, the connection will be closed
      interval: 25000, // every 25 seconds, a ping message will be sent
    },
    onMessage: (e) => {
      const data = JSON.parse(e.data);
      //   console.log(data);

      if (data.type === 'chatbot_embed_update') {
        if (data.status === 'success') {
          //   console.log('Embed update success', data);

          if (data.file_id === null || data.file_id === undefined || !files[data.file_id]) {
            return;
          }

          setFiles((prev) => ({
            ...prev,
            [data.file_id]: {
              ...prev[data.file_id],
              chatbot_embeded: true,
            },
          }));
        }
      }
    },
    onClose: (e) => {
      //   console.log(e);
    },
    shouldReconnect: (closeEvent) => true,
    onOpen: (e) => {
      //   console.log(e);
    },
  });

  const handleSubmit = () => {
    if (!newMessage) {
      return;
    }

    setGettingMessage(true);

    setMessages([
      ...messages,
      {
        id: 'temp',
        chat: messages[0]?.chat || 0,
        created_at: new Date().toISOString(),
        message: newMessage,
        sender: 'user',
      },
    ]);

    setTimeout(() => {
      const chatMessages = document.querySelector('.chat-messages');
      chatMessages.scrollTop = chatMessages.scrollHeight;
    }, 200);

    axios({
      method: 'post',
      url: `${API_ROUTE}/api/chat/`,
      data: {
        contractor_uuid: auth.contractor.uuid,
        project_uuid: projectUUID,
        message: newMessage,
      },
      headers: {
        Authorization: `Token ${auth.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);

        setMessages((prev) => [...prev.slice(0, prev.length - 1), response.data.user_message, response.data.chatbot_message]);
        setGettingMessage(false);

        setTimeout(() => {
          const chatMessages = document.querySelector('.chat-messages');
          chatMessages.scrollTop = chatMessages.scrollHeight;
        }, 200);
      })
      .catch((error) => {
        console.log(error);
      });

    setNewMessage('');
  };

  useEffect(() => {
    //listen for enter
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' || e.key === 'Return') {
        if (newMessage) {
          handleSubmit();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [newMessage]);

  const getFileURL = (file) => {
    // console.log(file);
    setLoadingFile(true);
    axios
      .get(file.file, {
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setCurrentFileUrl(url);
        setLoadingFile(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const handleProjectFileNodeDelete = (nodeUuid) => {
    const parentUuid = pipe(getNodeParentUuidLens, viewState)(nodeUuid);
    const orderedUuids = getOrderedUuidsAfterDeletion(state)(nodeUuid);
    if (isNil(parentUuid)) {
      // if we are deleting a root element give root element a dummy parent id and fix the root elements' indices
      setState(pipe(set(getNodeParentUuidLens(nodeUuid))('deleted'), fixIndices(orderedUuids)));
    } else {
      //otherwise just remove it from the children of the parent and fix the indices
      setState(pipe(set(getNodeChildUuidsLens(parentUuid))(orderedUuids), fixIndices(orderedUuids)));
    }
    projectFileNodesApi('post')([
      { operation: 'project_file_node_delete', uuid: nodeUuid },
      ...mapIndexed((nodeUuid, index) => ({ operation: 'project_file_node_update', uuid: nodeUuid, index: index }))(orderedUuids),
    ]);
  };

  const handleReparent = (index) => (parentUuid) => (nodeUuid) => {
    const oldParentUuid = pipe(getNodeParentUuidLens, viewState)(nodeUuid);
    const oldOrderedUuids = getOrderedUuidsAfterDeletion(state)(nodeUuid);
    const newOrderedUuids = getOrderedUuidsAfterInsertion(state)(index)(parentUuid)(nodeUuid);
    setState(
      pipe(
        when(always(isNotNil(oldParentUuid)))(set(getNodeChildUuidsLens(oldParentUuid))(oldOrderedUuids)),
        when(always(isNotNil(parentUuid)))(set(getNodeChildUuidsLens(parentUuid))(newOrderedUuids)),
        set(getNodeParentUuidLens(nodeUuid))(parentUuid),
        fixIndices(oldOrderedUuids),
        fixIndices(newOrderedUuids)
      )
    );
    projectFileNodesApi('post')([
      { operation: 'project_file_node_update', uuid: nodeUuid, parent_uuid: parentUuid, index: index },
      ...mapIndexed((nodeUuid, index) => ({ operation: 'project_file_node_update', uuid: nodeUuid, index: index }))(oldOrderedUuids),
      ...mapIndexed((nodeUuid, index) => ({ operation: 'project_file_node_update', uuid: nodeUuid, index: index }))(newOrderedUuids),
    ]);
  };

  const handleCreateProjectFileNode = ({ uuid, name, parent_uuid, file_key = null, is_folder = false, index = 0 }) => {
    const newProjectFileNode = { uuid, name, parent_uuid, file_key, is_folder, index, child_uuids: [] };
    const orderedUuids = getOrderedUuidsAfterInsertion(state)(index)(parent_uuid)(uuid);
    return projectFileNodesApi('post')([
      { operation: 'project_file_node_create', ...newProjectFileNode },
      ...mapIndexed((nodeUuid, index) => ({ operation: 'project_file_node_update', uuid: nodeUuid, index: index }))(orderedUuids),
      { operation: 'project_file_nodes_get' },
    ]);
  };

  const handleCreateProjectFileNodePdf = ({ uuid, name, parent_uuid, file_key = null, index = 0 }) => {
    const newProjectFileNode = { uuid, name, parent_uuid, file_key, is_folder: false, index, child_uuids: [] };
    const orderedUuids = getOrderedUuidsAfterInsertion(state)(index)(parent_uuid)(uuid);
    return projectFileNodesApi('post')([
      { operation: 'project_file_node_create_pdf', ...newProjectFileNode },
      ...mapIndexed((nodeUuid, index) => ({ operation: 'project_file_node_update', uuid: nodeUuid, index: index }))(orderedUuids),
      { operation: 'project_file_nodes_get' },
    ]);
  };

  const handleCreateProjectFileNodeText = ({ uuid, name, parent_uuid, file_key = null, index = 0 }) => {
    const newProjectFileNode = { uuid, name, parent_uuid, file_key, is_folder: false, index, child_uuids: [] };
    const orderedUuids = getOrderedUuidsAfterInsertion(state)(index)(parent_uuid)(uuid);
    return projectFileNodesApi('post')([
      { operation: 'project_file_node_create_text', ...newProjectFileNode },
      ...mapIndexed((nodeUuid, index) => ({ operation: 'project_file_node_update', uuid: nodeUuid, index: index }))(orderedUuids),
      { operation: 'project_file_nodes_get' },
    ]);
  };

  const handleFileUpload = (file) => {
    const filename = file.name;
    const presignedHeadersPromise = getAWSPresignedHeaders(filename);
    const fileUploadPromise = andThen(({ url, fields }) => {
      const { key } = fields;
      const onUploadProgress = ({ loaded, total }) => setState(updateOrCreateUpload(key)(Math.round((loaded / total) * 100))(`Uploading ${filename}`));
      return axios.post(url, formDataFromObj({ ...fields, file: file }), { onUploadProgress });
    })(presignedHeadersPromise);
    return Promise.all([filename, presignedHeadersPromise, fileUploadPromise]);
  };

  const handleProjectFileNodeSearch = () => {
    setState(pipe(set(searchIsLoadingLens)(true), set(searchIsSyncedLens)(false)));
    const searchTerm = viewState(searchTextLens);
    pipe(
      projectFileNodesApi('post'),
      andThen(view(responseDataLens)),
      andThen(head),
      andThen(map(prop('uuid'))),
      andThen((matchedUuids) => setState(pipe(set(searchMatchedUuidsLens)(new Set(matchedUuids)), set(searchIsLoadingLens)(false), set(searchIsSyncedLens)(true))))
    )([{ operation: 'project_file_nodes_search', search_term: searchTerm }]);
  };

  return (
    <div>
      <ChatNavbar projectUUID={projectUUID} />
      <div className='chat-container'>
        <div className='overflow-hidden h-full border-r-[e0e0e0] border-r flex flex-col p-2'>
          <Navbar>
            <Stack direction='vertical' gap={1} className='w-full'>
              <InputGroup className='mb-3'>
                <Form.Control
                  placeholder='Search'
                  aria-label='Username'
                  aria-describedby='basic-addon1'
                  onChange={pipe(view(eventValueLens), (text) => setState(pipe(set(searchTextLens)(text), set(searchIsSyncedLens)(false))))}
                  value={viewState(searchTextLens)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleProjectFileNodeSearch();
                  }}
                />
                {pipe(
                  viewState,
                  ifElse(
                    isEmpty,
                    F,
                    always(
                      <InputGroup.Text
                        className='cursor-pointer'
                        onClick={() => {
                          pipe(set(searchTextLens), setState)('');
                        }}
                      >
                        <IconX size={20} stroke={1} />
                      </InputGroup.Text>
                    )
                  )
                )(searchTextLens)}
                <InputGroup.Text className='cursor-pointer' onClick={handleProjectFileNodeSearch}>
                  {ifElse(
                    viewState,
                    always(<Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='20' wrapperStyle={{}} wrapperClass='blocks-wrapper' />),
                    always(<IconSearch size={20} stroke={1} />)
                  )(searchIsLoadingLens)}
                </InputGroup.Text>
              </InputGroup>
              <Stack direction='horizontal' gap={1} className='w-full'>
                <Form.Select onChange={pipe(view(eventValueLens), set(sidebarSortingMethodLens), setState)} value={viewState(sidebarSortingMethodLens)}>
                  <option value='index'>Custom</option>
                  <option value='name'>Name</option>
                  <option value='created'>Date Created</option>
                </Form.Select>
                <input
                  type='file'
                  id='file-upload-input'
                  multiple
                  className='hidden'
                  onChange={pipe(
                    view(eventFilesLens),
                    map((file) => renameFile(file.name.replace(/[^a-zA-Z0-9.]/g, ''))(file)),
                    map(handleFileUpload),
                    map(
                      andThen(([filename, presignedHeaders, fileUploadResponse]) => {
                        const mimeType = getMimeType(filename);
                        const fileCreationHandler =
                          mimeType == 'application/pdf' ? handleCreateProjectFileNodePdf : startsWith('text/')(mimeType) ? handleCreateProjectFileNodeText : handleCreateProjectFileNode;
                        const fileCreationPromise = fileCreationHandler({ name: filename, uuid: uuidv4(), file_key: presignedHeaders.fields.key, is_folder: false, index: 0 });
                        andThen((fileCreationResponse) => {
                          console.log('fileCreationResponse', fileCreationResponse);
                          let newFiles = {};
                          fileCreationResponse.data
                            .at(-1)
                            .filter((item) => item.is_folder === false)
                            .map((item) => {
                              newFiles[item.id] = item;
                            });
                          console.log('oldFiles', files);
                          console.log('newFiles', newFiles);
                          setFiles(newFiles);

                          const key = presignedHeaders.fields.key;
                          const newNodes = pipe(view(responseDataLens), last, createLookupByKeyName('uuid'))(fileCreationResponse);
                          setState(pipe(over(nodesLens)(mergeDeepLeft(newNodes)), removeUpload(key)));
                        })(fileCreationPromise);
                      })
                    )
                  )}
                />
                <ButtonGroup>
                  <Button
                    variant='outline-primary'
                    onClick={() =>
                      pipe(
                        handleCreateProjectFileNode,
                        andThen(view(responseDataLens)),
                        andThen(last),
                        andThen(createLookupByKeyName('uuid')),
                        andThen((newNodes) => over(nodesLens)(mergeDeepLeft(newNodes))),
                        andThen(setState)
                      )({ uuid: uuidv4(), name: 'New Folder', is_folder: true })
                    }
                  >
                    <IconFolderPlus />
                  </Button>
                  <Button variant='outline-primary' onClick={() => document.getElementById('file-upload-input').click()}>
                    <IconCloudUpload />
                  </Button>
                  <Button variant='outline-primary' onClick={() => setState(set(isToastSidebarOpenLens)(true))}>
                    {/*if there are files loading, turn this into a loading spinner */}
                    {pipe(view(uploadKeysLens))(state).length > 0 ? (
                      <Blocks visible={true} height='24' width='24' color='#006AFE' ariaLabel='blocks-loading' radius='12' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                    ) : (
                      <IconProgressCheck />
                    )}
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          </Navbar>
          <div
            className='overflow-hidden grow shrink'
            ref={treeParentRef}
            onContextMenu={(event) => {
              showProjectFileNodeContextMenu({ event });
              pipe(set(sidebarContextMenuNodeUuidLens), setState)(null);
              event.stopPropagation();
            }}
          >
            <Tree
              data={pipe(viewState, keys, filter(pipe(getNodeParentUuidLens, viewState, isNil)), sortBy(sortFn))(nodesLens)}
              idAccessor={pipe(getNodeUuidLens, viewState)}
              childrenAccessor={pipe(getNodeChildUuidsLens, viewState, sortBy(sortFn))}
              disableDrop={({ parentNode }) => ifElse(equals('__REACT_ARBORIST_INTERNAL_ROOT__'), F, pipe(getNodeIsFolderLens, viewState, not))(parentNode.id)}
              onSelect={pipe(head, prop('id'), defaultTo(null), set(sidebarSelectedNodeUuidLens), setState)}
              onMove={({ dragIds, parentId, index }) => {
                const nodeUuid = head(dragIds);
                const parentUuid = when(equals('__REACT_ARBORIST_INTERNAL_ROOT__'))(always(null))(parentId);
                handleReparent(index)(parentUuid)(nodeUuid);
              }}
              height={treeHeight}
              width={treeWidth}
              indent={24}
              rowHeight={36}
              overscanCount={1}
              searchTerm={viewState(searchTextLens)}
              searchMatch={(node, searchTerm) => {
                if (isNotEmpty(searchTerm) && viewState(searchIsSyncedLens) && !viewState(searchIsLoadingLens)) return viewState(searchMatchedUuidsLens).has(node.id);
                return true;
              }}
              disableMultiSelection
            >
              {Node}
            </Tree>
          </div>
        </div>
        <div className='chat-right'>
          {loadingFile && currentFile && (
            <div className='chat-loading'>
              <Blocks visible={true} height='40' width='40' color='#006AFE' ariaLabel='blocks-loading' radius='20' wrapperStyle={{}} wrapperClass='blocks-wrapper' />

              <div>Loading file...</div>
            </div>
          )}
          {currentFile &&
            files?.[currentFile]?.file &&
            (files?.[currentFile]?.file?.slice(0, files[currentFile]?.file.lastIndexOf('?')).endsWith('.pdf') ? (
              <iframe src={currentFileUrl} className='chat-file-iframe' />
            ) : (
              <div className='chat-file-image'>
                <ReactPanZoom
                  image={files[currentFile]?.file}
                  zoom={0.8}
                  enablePan={true}
                  disableDoubleClickZoom={true}
                  disableScrollZoom={false}
                  disableKeyInteraction={true}
                  disableZoom={true}
                  disablePan={true}
                  disableRotate={true}
                  disableReset={true}
                  disableX={true}
                  disableY={true}
                />
              </div>
            ))}

          {!currentFile && <div className='chat-no-file'>Select a file to view</div>}
        </div>
        <div className='chat-left'>
          <div className='chat-header'>
            <div className='chat-title'>AI chat</div>

            <div className='chat-header-buttons'>
              <Popup
                trigger={(open) => (
                  <div className='chat-header-files-dropdown'>
                    <IconTableDown size={20} />
                    Files
                  </div>
                )}
                on='click'
                position='bottom right'
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{ width: '300px' }}
              >
                <div className='chat-files-dropdown-container'>
                  <div className='chat-files-dropdown-header'>Files available</div>

                  {files &&
                    Object.values(files)?.map((file) => (
                      <div
                        key={file.id}
                        className='chat-file'
                        onClick={() => {
                          if (file.id === null || file.id === undefined || file.id === currentFile) {
                            return;
                          }
                          setCurrentFile(file.id);

                          if (file.file.slice(0, file.file.lastIndexOf('?')).endsWith('.pdf')) {
                            getFileURL(file);
                          } else {
                            setLoadingFile(false);
                          }

                          const fileUuid = file.uuid;
                          const fileNode = pipe(getNodeUuidLens, viewState)(fileUuid);
                          setState(set(sidebarSelectedNodeUuidLens)(fileNode));
                        }}
                      >
                        <div className='chat-file-name'>{file.name}</div>

                        <div className='chat-file-status'>
                          {file.chatbot_embeded ? (
                            <IconCircleCheckFilled size={20} style={{ color: '#006afe' }} />
                          ) : (
                            <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='10' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </Popup>
            </div>
          </div>

          <div className='chat-messages'>
            {loading && (
              <div className='chat-loading'>
                <Blocks visible={true} height='40' width='40' color='#006AFE' ariaLabel='blocks-loading' radius='20' wrapperStyle={{}} wrapperClass='blocks-wrapper' />

                <div>Loading chat...</div>
              </div>
            )}

            {messages && messages?.length > 0 ? (
              messages?.map((message) => (
                <div key={message.uuid} className={`chat-message ${message.sender == 'user' ? 'chat-message-user' : 'chat-message-bot'}`}>
                  <div className='chat-message-details'>
                    <div className='chat-message-sender'>
                      {message.sender == 'user' ? (
                        'You'
                      ) : (
                        <>
                          <IconRobot size={20} /> AI Model
                        </>
                      )}
                    </div>
                    <div className='chat-message-time'>
                      {new Date(message.created_at).toLocaleDateString()} at {new Date(message.created_at).toLocaleTimeString()}
                    </div>
                  </div>
                  <div className='chat-message-text'>
                    <ReactMarkdown>{message.message}</ReactMarkdown>
                  </div>
                  {message.used_context?.length > 0 && (
                    <div className='chat-message-contexts'>
                      {message.used_context.map((context) => (
                        <div
                          key={context.uuid}
                          className='chat-message-context'
                          onClick={() => {
                            if (context.file_id === null || context.file_id === undefined || context.file_id === currentFile) {
                              return;
                            }
                            setCurrentFile(context.file_id);

                            if (files[context.file_id].file.slice(0, files[context.file_id].file.lastIndexOf('?')).endsWith('.pdf')) {
                              getFileURL(files[context.file_id]);
                            } else {
                              setLoadingFile(false);
                            }

                            const fileUuid = files[context.file_id].uuid;
                            const fileNode = pipe(getNodeUuidLens, viewState)(fileUuid);
                            setState(set(sidebarSelectedNodeUuidLens)(fileNode));
                          }}
                        >
                          <div className='chat-message-context-source'>{context.source}</div>
                          <div className='chat-message-context-page'>
                            [
                            {context.pages?.length > 0 &&
                              context.pages
                                ?.sort((a, b) => a - b)
                                .map((page, index) => (
                                  <>
                                    {page + 1}
                                    {index < context.pages.length - 1 && <>,&nbsp;</>}
                                  </>
                                ))}
                            ]
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div className='chat-loading'>
                <div className='chat-description'>
                  This AI model has been trained on the text data of this project.
                  <div>Ask a question to get started.</div>
                </div>
              </div>
            )}

            {gettingMessage && (
              <div className={`chat-message chat-message-bot`}>
                <div className='chat-message-sender'>
                  <>
                    <IconRobot size={20} /> AI Model thinking...
                  </>
                </div>
              </div>
            )}
          </div>

          <div className='chat-input-footer'>
            <input type='text' className='chat-input' value={newMessage} placeholder='Type a message...' onChange={(e) => setNewMessage(e.target.value)} />

            {gettingMessage ? (
              <div className='chat-send-button'>
                <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='10' wrapperStyle={{}} wrapperClass='blocks-wrapper' />
              </div>
            ) : (
              <button className={'chat-send-button ' + (newMessage ? ' chat-send-button-active' : 'chat-send-button-inactive')} onClick={() => handleSubmit()}>
                Send
              </button>
            )}
          </div>
        </div>
      </div>
      <Offcanvas show={viewState(isToastSidebarOpenLens)} onHide={() => pipe(set(isToastSidebarOpenLens), setState)(false)} scroll backdrop placement='end'>
        <Offcanvas.Header>
          <Offcanvas.Title>Uploads</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ToastContainer className='position-static'>
            {pipe(
              viewState,
              ifElse(
                isEmpty,
                always(<div>No uploads in progress</div>),
                map((uploadKey) => (
                  <Toast key={uploadKey}>
                    <Toast.Header className='overflow-hidden text-ellipsis'>{pipe(getUploadObjectMessageLens, viewState)(uploadKey)}</Toast.Header>
                    <Toast.Body>
                      <ProgressBar now={pipe(getUploadObjectProgressLens, viewState)(uploadKey)} />
                    </Toast.Body>
                  </Toast>
                ))
              )
            )(uploadKeysLens)}
          </ToastContainer>
        </Offcanvas.Body>
      </Offcanvas>
      {pipe(viewState, (nodeUuid) => {
        const newName = viewState(nodeRenameModalTextLens);
        return (
          <Modal show={isNotNil(nodeUuid)} onHide={() => setState(closeNodeRenameModal)}>
            <Modal.Header>
              <Modal.Title className='overflow-hidden text-ellipsis'>Renaming file "{pipe(getNodeNameLens, viewState)(nodeUuid)}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Label>Page Name</Form.Label>
              <Form.Control onChange={pipe(view(eventValueLens), set(nodeRenameModalTextLens), setState)} value={newName} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='outline-primary'
                onClick={() => {
                  const nodeNameLens = getNodeNameLens(nodeUuid);
                  projectFileNodesApi('post')([{ operation: 'project_file_node_update', uuid: nodeUuid, name: newName }]);
                  setState(pipe(set(nodeNameLens)(newName), closeNodeRenameModal));
                }}
              >
                Save
              </Button>
              <Button variant='outline-secondary' onClick={() => setState(closeNodeRenameModal)} className='ms-auto'>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        );
      })(nodeRenameModalUuidLens)}
      <Menu id={SIDEBAR_CONTEXT_MENU_ID} theme='bobyard-light'>
        {pipe(viewState, (nodeUuid) => {
          if (isNil(nodeUuid)) return;
          return (
            <ContextMenuItem onClick={() => pipe(openNodeRenameModal, setState)(nodeUuid)}>
              <IconCursorText size={20} stroke={1} /> Rename
            </ContextMenuItem>
          );
        })(sidebarContextMenuNodeUuidLens)}
        {pipe(viewState, (nodeUuid) => {
          if (allPass([isNotNil, pipe(getNodeIsFolderLens, viewState, not), pipe(getNodeFileUrlLens, viewState, isNotNil)])(nodeUuid)) {
            return (
              <ContextMenuItem>
                <a href={pipe(getNodeFileUrlLens, viewState)(nodeUuid)} target='_blank' className='contents'>
                  <IconDownload size={20} stroke={1} />
                  Download
                </a>
              </ContextMenuItem>
            );
          }
        })(sidebarContextMenuNodeUuidLens)}
        {pipe(viewState, (nodeUuid) => {
          if (either(isNil, pipe(getNodeIsFolderLens, viewState))(nodeUuid))
            return (
              <ContextMenuItem
                onClick={() =>
                  pipe(
                    handleCreateProjectFileNode,
                    andThen(view(responseDataLens)),
                    andThen(last),
                    andThen(createLookupByKeyName('uuid')),
                    andThen((newNodes) => over(nodesLens)(mergeDeepLeft(newNodes))),
                    andThen(setState)
                  )({ uuid: uuidv4(), name: 'New Folder', parent_uuid: nodeUuid, is_folder: true })
                }
              >
                <IconFolderPlus size={20} stroke={1} /> New Folder
              </ContextMenuItem>
            );
        })(sidebarContextMenuNodeUuidLens)}
        {pipe(
          viewState,
          ifElse(isNil, F, (nodeUuid) => (
            <ContextMenuItem
              className='hover:!text-red-normal hover:bg-pink-light'
              onClick={() => {
                handleProjectFileNodeDelete(nodeUuid);
              }}
            >
              <IconTrashX size={20} stroke={1} />
              Delete
            </ContextMenuItem>
          ))
        )(sidebarContextMenuNodeUuidLens)}
      </Menu>
      {/*<div className={"chat-container " + (currentFile ? 'chat-container-file' : '')}>
                {currentFile &&
                    <div className="chat-right">
                        {loadingFile &&
                            <div className='chat-loading'>
                                <Blocks
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="20"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />

                                <div>
                                    Loading file...
                                </div>
                            </div>
                        }
                        {currentFile && (files[currentFile].file.slice(0, files[currentFile].file.lastIndexOf('?')).endsWith('.pdf')
                            ? <iframe
                                src={currentFileUrl}
                                className='chat-file-iframe'
                            />
                            : <div className='chat-file-image'>
                                <ReactPanZoom
                                    image={files[currentFile].file}
                                    zoom={0.8}
                                    enablePan={true}

                                    disableDoubleClickZoom={true}
                                    disableScrollZoom={false}
                                    disableKeyInteraction={true}
                                    disableZoom={true}
                                    disablePan={true}
                                    disableRotate={true}
                                    disableReset={true}
                                    disableX={true}
                                    disableY={true}
                                />
                            </div>
                        )}
                    </div>
                }
                <div className={"chat-left "}
                    style={{
                        borderLeft: currentFile ? '1px solid #e0e0e0' : 'none',
                    }}
                >
                    <div className="chat-header"
                        style={{
                            paddingLeft: currentFile ? '5%' : '20%',
                            paddingRight: currentFile ? '5%' : '20%'
                        }}
                    >
                        <div className="chat-title">
                            AI chat
                        </div>

                        <div className='chat-header-buttons'>
                            <Popup
                                trigger={open => (
                                    <div className="chat-header-files-dropdown">
                                        <IconTableDown size={20} />
                                        Files
                                    </div>
                                )}
                                on='click'
                                position="bottom right"
                                closeOnDocumentClick
                                mouseLeaveDelay={300}
                                mouseEnterDelay={0}
                                contentStyle={{ width: '300px' }}
                            >
                                <div className="chat-files-dropdown-container">
                                    <div className='chat-files-dropdown-header'>
                                        Files available
                                    </div>

                                    {files && Object.values(files)?.map((file) =>
                                        <div key={file.id} className='chat-file'
                                            onClick={() => {
                                                if (file.id === null || file.id === undefined || file.id === currentFile) {
                                                    return;
                                                }
                                                setCurrentFile(file.id);

                                                if (file.file.slice(0, file.file.lastIndexOf('?')).endsWith('.pdf')) {
                                                    getFileURL(file);
                                                } else {
                                                    setLoadingFile(false);
                                                }
                                            }}
                                        >
                                            <div className='chat-file-name'>
                                                {file.name}
                                            </div>

                                            <div className='chat-file-status'>
                                                {file.chatbot_embeded
                                                    ? <IconCircleCheckFilled size={20} style={{ 'color': '#006afe' }} />
                                                    : <Blocks
                                                        visible={true}
                                                        height="20"
                                                        width="20"
                                                        color="#006AFE"
                                                        ariaLabel="blocks-loading"
                                                        radius="10"
                                                        wrapperStyle={{}}
                                                        wrapperClass="blocks-wrapper"
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Popup>

                            {currentFile &&
                                <button
                                    className='chat-back-button'
                                    onClick={() => {
                                        setCurrentFile(null);
                                        setCurrentFileUrl(null);
                                    }}
                                >
                                    <IconArrowsMaximize size={20} />

                                    <div>
                                        Expand
                                    </div>
                                </button>
                            }
                        </div>
                    </div>

                    <div className="chat-messages"
                        style={{
                            paddingLeft: currentFile ? '5%' : '20%',
                            paddingRight: currentFile ? '5%' : '20%'
                        }}
                    >
                        {loading &&
                            <div className='chat-loading'>
                                <Blocks
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="20"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />

                                <div>
                                    Loading chat...
                                </div>
                            </div>
                        }

                        {(messages && messages?.length > 0) ? messages?.map((message) =>
                            <div
                                key={message.uuid}
                                className={`chat-message ${message.sender == 'user' ? 'chat-message-user' : 'chat-message-bot'}`}
                            >
                                <div className='chat-message-details'>
                                    <div className='chat-message-sender'>
                                        {message.sender == 'user'
                                            ? 'You'
                                            : <><IconRobot size={20} /> AI Model</>
                                        }
                                    </div>
                                    <div className='chat-message-time'>
                                        {new Date(message.created_at).toLocaleDateString()} at {new Date(message.created_at).toLocaleTimeString()}
                                    </div>
                                </div>
                                <div className='chat-message-text'>
                                    <ReactMarkdown>{message.message}</ReactMarkdown>
                                </div>
                                {message.used_context?.length > 0 &&
                                    <div className='chat-message-contexts'>
                                        {message.used_context.map((context) =>
                                            <div
                                                key={context.uuid}
                                                className='chat-message-context'
                                                onClick={() => {
                                                    if (context.file_id === null || context.file_id === undefined || context.file_id === currentFile) {
                                                        return;
                                                    }
                                                    setCurrentFile(context.file_id);

                                                    if (files[context.file_id].file.slice(0, files[context.file_id].file.lastIndexOf('?')).endsWith('.pdf')) {
                                                        getFileURL(files[context.file_id]);
                                                    } else {
                                                        setLoadingFile(false);
                                                    }
                                                }}
                                            >
                                                <div className='chat-message-context-source'>
                                                    {context.source}
                                                </div>
                                                <div className='chat-message-context-page'>
                                                    [{context.pages?.length > 0 && context.pages?.sort((a, b) => a - b).map((page, index) =>
                                                        <>{page + 1}{index < context.pages.length - 1 && <>,&nbsp;</>}
                                                        </>
                                                    )}]
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        )
                            : <div className='chat-loading'>
                                <div className='chat-description'>
                                    This AI model has been trained on the text data of this project.

                                    <div>Ask a question to get started.</div>
                                </div>
                            </div>
                        }

                        {gettingMessage &&
                            <div className={`chat-message chat-message-bot`}>
                                <div className='chat-message-sender'>
                                    <><IconRobot size={20} /> AI Model thinking...</>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="chat-input-footer"
                        style={{
                            paddingLeft: currentFile ? '5%' : '20%',
                            paddingRight: currentFile ? '5%' : '20%'
                        }}
                    >
                        <input
                            type="text"
                            className='chat-input'
                            value={newMessage}
                            placeholder='Type a message...'
                            onChange={(e) => setNewMessage(e.target.value)}
                        />

                        {gettingMessage
                            ? <div className='chat-send-button'>
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <button
                                className={'chat-send-button ' + (newMessage ? ' chat-send-button-active' : 'chat-send-button-inactive')}
                                onClick={() => handleSubmit()}
                            >
                                Send
                            </button>
                        }
                    </div>
                </div>
            </div>*/}
    </div>
  );
}
